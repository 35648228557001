import {
  AllEntries,
  validationFactory,
} from '../../../../core/helpers/validationFactory';
import { addressNamesWithPrefix } from '../../../../components/Address/Address';

const { createValidate, createValidator } = validationFactory();

export const validate = createValidate({
  product_type: createValidator(
    value => value.length > 0,
    'Product Type is required.',
  ),
  neat_amount_in_mj: createValidator(
    value => value.length > 0 && !Number.isNaN(+value),
    'Neat amount in MJ is required and must be a number.',
  ),
  batch_number: createValidator(
    value => value.length > 0 && !Number.isNaN(+value),
    'Batch Number is required and must be a number.',
  ),
  raw_material: createValidator(
    value => value.length > 0,
    'Raw Material is required.',
  ),
  delivery_country: createValidator(
    value => value.length > 0,
    'Delivery Country is required.',
  ),
  country_of_origin_of_feedstock: createValidator(
    value => value.length > 0,
    'Country of feedstock origin is required.',
  ),
  credit_volume: createValidator(
    value => value.length > 0 && !Number.isNaN(+value),
    'Neat amount in MT is required and must be a number.',
  ),
  certification_scheme: createValidator(
    value => value.length > 0,
    'Certification scheme applicable is required.',
  ),
  acquisition_date: createValidator(
    value => value.length > 0,
    'Date of Acquisition is required.',
  ),
  entry_date: createValidator(
    value => value.length > 0,
    'Date of Entry is required.',
  ),
  supplier_name: createValidator(
    value => value.length > 0,
    'Name of Supplier is required.',
  ),
  POS_file: createValidator(
    value => value.name.length > 0 && value.type === 'application/pdf',
    'POS PDF File is required and must be PDF.',
  ),
  supplier_address: createValidator(
    (value: string) => !!value && value.length >= 3,
    'Address is required',
  ),
  supplier_address_number: createValidator(
    (value: string) => !!value,
    'Address Number is required',
  ),
  supplier_ZIP: createValidator(
    (value: string) => value.length > 4,
    'ZIP should be more than 4 characters',
  ),
  supplier_city: createValidator(
    (value: string) => !!value,
    'City is required',
  ),
  supplier_country: createValidator(
    (value: string) => !!value,
    'Country is required',
  ),

  last_production_country: createValidator(
    value => value.length > 0,
    'Name of the last production is required.',
  ),
  chain_of_custody_model: createValidator(
    value => value.length > 0,
    'Custody model is required.',
  ),
  LCA_GHG: createValidator(
    value => value.length > 0 && !Number.isNaN(+value),
    'LCA GHG value is required.',
  ),
  production_date_of_neat_SAF: createValidator(
    (value, allEntries: AllEntries) => {
      const [, schema] =
        allEntries.find(([key]) => key === 'certification_scheme') || [];
      return schema !== 'ICAO_CORSIA' || value.length > 0;
    },
    'Production date of Neat SAF is required.',
  ),
  production_location_of_neat_SAF: createValidator(
    (value, allEntries: AllEntries) => {
      const [, schema] =
        allEntries.find(([key]) => key === 'certification_scheme') || [];
      return schema !== 'ICAO_CORSIA' || value.length > 0;
    },
    'Production location of Neat SAF is required.',
  ),
  default_ILUC_value: createValidator((value, allEntries: AllEntries) => {
    const [, schema] =
      allEntries.find(([key]) => key === 'certification_scheme') || [];
    return (
      schema !== 'ICAO_CORSIA' || (value.length > 0 && !Number.isNaN(+value))
    );
  }, 'Default ILUC Value is required and should be a number.'),
  feedstock_origin_with_SCS_details: createValidator(
    (value, allEntries: AllEntries) => {
      const [, schema] =
        allEntries.find(([key]) => key === 'certification_scheme') || [];
      return schema !== 'ICAO_CORSIA' || value.length > 0;
    },
    'Feedstock origin, with SCS Details is required.',
  ),
});

export const createTransform =
  (abatementBaselineScheme: string, GHGEmissionReduction: string) =>
  (formData: FormData) => {
    const productType = formData.get('product_type');
    const neatAmountInMJ = formData.get('neat_amount_in_mj');
    const batchNumber = formData.get('batch_number');
    const rawMaterial = formData.get('raw_material');
    const volume = formData.get('credit_volume');
    const certificationScheme = formData.get('certification_scheme');
    const deliveryCountry = formData.get('delivery_country');
    const acquisitionDate = formData.get('acquisition_date');
    const entryInPOChainDate = formData.get('entry_date');

    const supplierName = formData.get('supplier_name');
    const supplierAddressNames = addressNamesWithPrefix('supplier');
    const supplierAddress = formData.get(supplierAddressNames['address']);
    const supplierAddressNumber = formData.get(
      supplierAddressNames['address_number'],
    );
    const supplierCity = formData.get(supplierAddressNames['city']);
    const supplierCountry = formData.get(supplierAddressNames['country']);
    const supplierZip = formData.get(supplierAddressNames['ZIP']);

    const lastProductionCountry = formData.get('last_production_country');

    const chainCustodyModel = formData.get('chain_of_custody_model');
    const LCAGHGValue = formData.get('LCA_GHG');
    const additionalRSBClaim = formData.get('additional_claim');
    const incentivesSAFDeclarationAndTaxCredits =
      formData.get('SAF_declaration');
    const originalPOSNumber = formData.get('POS_number');
    const feedstockOriginCountry = formData.get(
      'country_of_origin_of_feedstock',
    );
    const posFile = formData.get('POS_file');

    const neatData: Record<string, any> = {};

    if (certificationScheme === 'ICAO_CORSIA') {
      neatData['neatSAFProducerName'] = supplierName;
      neatData['neatSAFProductionDate'] = formData.get(
        'production_date_of_neat_SAF',
      );
      neatData['neatSAFProductionLocation'] = formData.get(
        'production_location_of_neat_SAF',
      );
      neatData['conversionProcess'] = formData.get('conversion_process');
      neatData['feedstockOriginWithSCSDetail'] = formData.get(
        'feedstock_origin_with_SCS_details',
      );
      neatData['defaultILUCValue'] = +formData.get('default_ILUC_value')!;
      neatData['directLandUseChangeDisaggregatedValue'] = formData.get(
        'disaggregated_value_for_direct_land_use_change',
      );
      neatData['landfillDirectionCreditDisaggregatedValue'] = formData.get(
        'disaggregated_value_for_landfill_emission_credit',
      );
      neatData['recyclingEmissionCreditDisaggregatedValue'] = formData.get(
        'disaggregated_value_recycling_emission_credit',
      );
    }

    return Object.entries({
      productType,
      neatAmountInMJ,
      deliveryCountry,
      batchNumber,
      rawMaterial,
      volume,
      acquisitionDate,
      entryInPOChainDate,
      supplierName,
      supplierAddress,
      supplierAddressNumber,
      supplierCity,
      supplierCountry,
      supplierZip,
      certificationScheme,
      lastProductionCountry,
      chainCustodyModel,
      LCAGHGValue,
      GHGEmissionReduction,
      additionalRSBClaim,
      incentivesSAFDeclarationAndTaxCredits,
      originalPOSNumber,
      feedstockOriginCountry,
      posFile,
      abatementBaselineScheme,
      ...neatData,
    }).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...(value ? { [key]: value } : {}),
      }),
      {},
    );
  };
