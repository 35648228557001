import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';
import { ViewElement } from '../../../../components/ViewElement/ViewElement';

export const AdminTransferInfo: React.FC = () => {
  const details = useAppSelector(selectSubmissionsDetails);
  if (!details) return null;

  return (
    <div className="admin-transfer-info view-elements-with-rows">
      <ViewElement
        label="BCUI ID"
        description={details?.newCredit?.BCUID || details?.credit?.BCUID}
      />
      <ViewElement
        label="Volume of BCUs to be transferred"
        description={details?.amount}
      />
      <ViewElement
        label="Company name buyer/token"
        description={details?.receiver?.name}
      />
      <ViewElement
        label="Company name transportation service provider/token"
        description={details?.TSP?.name}
      />
      <ViewElement
        label="Country where BCU is claimed"
        description={
          details?.creditClaimCountry || details?.newCredit?.creditClaimCountry
        }
      />
      <ViewElement
        label="Use of the SAF for domestic or international purposes"
        description={details?.SAFUsePurpose}
      />
    </div>
  );
};
