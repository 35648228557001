import {
  AllEntries,
  validationFactory,
} from '../../../../core/helpers/validationFactory';

const { createValidate, createValidator } = validationFactory();

export const validate = createValidate({
  productType: createValidator(
    value => value.length > 0,
    'Product Type is required.',
  ),
  neatAmountInMJ: createValidator(
    value => value.length > 0 && !Number.isNaN(+value),
    'Neat amount in MJ is required and must be a number.',
  ),
  batchNumber: createValidator(
    value => value.length > 0 && !Number.isNaN(+value),
    'Batch Number is required and must be a number.',
  ),
  rawMaterial: createValidator(
    value => value.length > 0,
    'Raw Material is required.',
  ),
  deliveryCountry: createValidator(
    value => value.length > 0,
    'Delivery Country is required.',
  ),
  volume: createValidator(
    value => value.length > 0 && !Number.isNaN(+value),
    'Neat amount in MT is required and must be a number.',
  ),
  certificationScheme: createValidator(
    value => value.length > 0,
    'Certification scheme applicable is required.',
  ),
  acquisitionDate: createValidator(
    value => value.length > 0,
    'Date of Acquisition is required.',
  ),
  entryInPOChainDate: createValidator(
    value => value.length > 0,
    'Date of Entry is required.',
  ),
  supplierName: createValidator(
    value => value.length > 0,
    'Name of Supplier is required.',
  ),
  supplierAddress: createValidator(
    (value: string) => !!value && value.length >= 3,
    'Address is required',
  ),
  supplierAddressNumber: createValidator(
    (value: string) => !!value,
    'Address Number is required',
  ),
  supplierZip: createValidator(
    (value: string) => value.length > 4,
    'ZIP should be more than 4 characters',
  ),
  supplierCity: createValidator((value: string) => !!value, 'City is required'),
  supplierCountry: createValidator(
    (value: string) => !!value,
    'Country is required',
  ),

  lastProductionCountry: createValidator(
    value => value.length > 0,
    'Country of the last production is required.',
  ),
  chainCustodyModel: createValidator(
    value => value.length > 0,
    'Custody model is required.',
  ),
  LCAGHGValue: createValidator(
    value => value.length > 0 && !Number.isNaN(+value),
    'LCA GHG value is required.',
  ),
  directLandUseChangeDisaggregatedValue: createValidator(
    (value, allEntries: AllEntries) => {
      const [, schema] =
        allEntries.find(([key]) => key === 'certification_scheme') || [];
      return (
        schema !== 'ICAO_CORSIA' || (value.length > 0 && !Number.isNaN(+value))
      );
    },
    'Default ILUC Value is required and should be a number.',
  ),
});

export const transformData = (formData: FormData) => {
  const id = formData.get('id');
  const productType = formData.get('productType');
  const neatAmountInMJ = formData.get('neatAmountInMJ');
  const batchNumber = formData.get('batchNumber');
  const rawMaterial = formData.get('rawMaterial');
  const volume = formData.get('volume');
  const certificationScheme = formData.get('certificationScheme');
  const deliveryCountry = formData.get('deliveryCountry');
  const acquisitionDate = formData.get('acquisitionDate');
  const entryInPOChainDate = formData.get('entryInPOChainDate');

  const supplierName = formData.get('supplierName');
  const supplierAddress = formData.get('supplierAddress');
  const supplierAddressNumber = formData.get('supplierAddressNumber');
  const supplierCity = formData.get('supplierCity');
  const supplierCountry = formData.get('supplierCountry');
  const supplierZip = formData.get('supplierZip');

  const lastProductionCountry = formData.get('lastProductionCountry');

  const chainCustodyModel = formData.get('chainCustodyModel');
  const LCAGHGValue = formData.get('LCAGHGValue');
  const additionalRSBClaim = formData.get('additionalRSBClaim');

  const neatData: Record<string, any> = {};

  if (certificationScheme === 'ICAO_CORSIA') {
    neatData['directLandUseChangeDisaggregatedValue'] = formData.get(
      'directLandUseChangeDisaggregatedValue',
    );
    neatData['landfillDirectionCreditDisaggregatedValue'] = formData.get(
      'landfillDirectionCreditDisaggregatedValue',
    );
    neatData['recyclingEmissionCreditDisaggregatedValue'] = formData.get(
      'recyclingEmissionCreditDisaggregatedValue',
    );
  }

  return {
    id,
    productType,
    neatAmountInMJ,
    deliveryCountry,
    batchNumber,
    rawMaterial,
    volume,
    acquisitionDate,
    entryInPOChainDate,
    supplierName,
    supplierAddress,
    supplierAddressNumber,
    supplierCity,
    supplierCountry,
    supplierZip,
    certificationScheme,
    lastProductionCountry,
    chainCustodyModel,
    LCAGHGValue,
    additionalRSBClaim,
    ...neatData,
  };
};

const keys = [
  'productType',
  'neatAmountInMJ',
  'deliveryCountry',
  'batchNumber',
  'rawMaterial',
  'volume',
  'acquisitionDate',
  'entryInPOChainDate',
  'supplierName',
  'supplierAddress',
  'supplierAddressNumber',
  'supplierCity',
  'supplierCountry',
  'supplierZip',
  'certificationScheme',
  'lastProductionCountry',
  'chainCustodyModel',
  'LCAGHGValue',
  'additionalRSBClaim',
  'directLandUseChangeDisaggregatedValue',
  'landfillDirectionCreditDisaggregatedValue',
  'recyclingEmissionCreditDisaggregatedValue',
];

export const hasChanges = (formData: FormData, item: any) => {
  return keys.some((key: string) => item[key] !== formData.get(key));
};
