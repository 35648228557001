import React, { useEffect } from 'react';
import { ChangePasswordForm } from './ChangePasswordForm';
import { fetchSetPassword } from '../../API/login';
import { toast } from 'react-toastify';
import { STATEMENTS } from '../../structure/routes/routes';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const SetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      toast('Error: Token is required.', { type: 'error' });
      navigate(STATEMENTS);
    }
  }, []);

  return <ChangePasswordForm fetchData={fetchSetPassword} token={token!} />;
};
