import React from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { fetchDownloadPDF } from '../../../API/credits';

import './PosFileStyle.scss';

interface Props {
  id: string;
}

export const PosFile: React.FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(fetchDownloadPDF(id)).then((blob: Blob) => {
      const url = URL.createObjectURL(blob);

      window.open(url, '_blank');
    });
  };

  return (
    <span
      className="material-icons-outlined pos-file-download"
      onClick={onClick}>
      picture_as_pdf
    </span>
  );
};
