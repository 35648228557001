import { Column } from '../../../../../components/Table/Table';

export const COLUMNS: Column<any>[] = [
  {
    title: 'Scheme',
    keyItem: 'name',
    render: item => item.name,
  },
  {
    title: 'Fuel emission factor, combustion only kg CO2e/kg fuel',
    keyItem: 'fuelEmissionFactor',
    render: item => item.fuelEmissionFactor,
  },
  {
    title: 'Baseline value gCO2e/MJ',
    keyItem: 'baselineValue',
    render: item => item.baselineValue,
  },
  {
    title: 'Applicable Schemes',
    keyItem: 'applicableSchemes',
    render: item => item.applicableSchemes.join(', '),
  },
];
