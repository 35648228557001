import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ViewElement } from '../../components/ViewElement/ViewElement';
import {
  COMPANY_TYPE_NAMES,
  CompanyType,
} from '../../../structure/models/company/company';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';
import { formatDate } from '../../core/models/contstants/format-date';

import './CompanyInfoStyle.scss';
import { fetchCompanyInfo } from '../../../API/company';

export const CompanyInfo: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const company = user!.company;
  const certificateDate =
    company.type === CompanyType.Supplier
      ? `${formatDate(company.certificateValidityStartDate!)} - ${formatDate(
          company.certificateValidityEndDate!,
        )}`
      : null;

  useEffect(() => {
    if (!user || user.company.secondaryContactInfo) return;
    dispatch(fetchCompanyInfo());
  }, [user]);

  return (
    <div className="company-info">
      <Row className="company-info__header-block">
        <Col className="company-info__header-block__company">
          <h1>{company.name}</h1>
          <ViewElement label={COMPANY_TYPE_NAMES[company.type!]} dots={false} />
        </Col>
        <Col className="company-info__header-block__total-balance">
          <ViewElement
            label="Total Balance"
            description={`${company.balance || 0} BCU`}
          />
        </Col>
      </Row>
      <Row>
        <Col md="5" className="company-info__info-block">
          {company.type === CompanyType.Supplier && (
            <>
              <ViewElement
                label="RSB PO Number"
                description={company.RSBPONumber}
              />
              <ViewElement
                label="Certificate validity date"
                description={certificateDate}
              />
            </>
          )}
          <ViewElement
            label="Address"
            description={`${company.address} ${company.addressNumber} ${company.city}, ${company.country} ${company.zip}`}
          />
          <ViewElement
            label="Date of registration"
            description={formatDate(company.approveDate)}
          />
          <ViewElement
            label="Secondary (other) contact info"
            description={
              <>
                {company?.secondaryContactInfo?.firstname}{' '}
                {company?.secondaryContactInfo?.lastname}
                <br />
                {company?.secondaryContactInfo?.email}
                <br />
                {company?.secondaryContactInfo?.position}
              </>
            }
          />
        </Col>
      </Row>
    </div>
  );
};
