import {
  AllEntries,
  validationFactory,
} from '../../core/helpers/validationFactory';

const { createValidator } = validationFactory();

export const validators = {
  amount: createValidator(
    (value: number, _: AllEntries, max?: number) =>
      !!(max && value > 0 && value <= max),
    (key, value, _: AllEntries, max: number) =>
      `Amount is so match, max is ${max}`,
  ),
  retired_by: createValidator(value => !!value, 'Retired By is required'),
  credit: createValidator(value => !!value, 'BCU ID is required'),
  tsp: createValidator(
    (value, _, selectedValue, isTSP) => isTSP || !!value || !!selectedValue,
    'Name Transportation Service Provider is required',
  ),
};
