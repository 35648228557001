import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { fetchForgotPassword } from '../../API/login';
import { Input } from '../components/Form/Input';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { useFormActions } from '../components/Form/FormHook';
import { getFormForgotPasswordData, validate } from './helpers/forgot-password';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { STATEMENTS } from '../../structure/routes/routes';

import './ForgotPasswordStyle.scss';

export const ForgotPassword: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const transformAndDispatchData = (formData: FormData) => {
    return dispatch(
      fetchForgotPassword(getFormForgotPasswordData(formData)),
    ).then(() => {
      toast('Success: Instructions have been sent on your email.', {
        type: 'success',
      });

      navigate(STATEMENTS);
    });
  };

  const { onSubmit, loading, showErrorsBlock } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  return (
    <div className="forgot-password-screen">
      <div className="forgot-password-screen__form">
        <h1>Forgot Password?</h1>
        <div className="forgot-password-screen__form-description">
          Enter the email address you used when you joined and we’ll send you
          instructions to reset your password.
        </div>
        <form onSubmit={onSubmit}>
          <Input
            placeholder="Email"
            required
            type="email"
            name="email"
            error={showErrorsBlock['email']}
          />
          <BaseButtonSubmit
            className="forgot-password-screen__form-submit"
            active
            lock={loading}
            loading={loading}>
            Send Reset Instructions
          </BaseButtonSubmit>
        </form>
      </div>
    </div>
  );
});
