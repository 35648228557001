import { ADMIN_REFERENCE_VALUES_ROUTES } from '../../../../../structure/routes/routes';

export const TABS = [
  {
    title: 'Certification Schemes',
    path: ADMIN_REFERENCE_VALUES_ROUTES.CERTIFICATION_SCHEMES,
  },
  {
    title: 'Tab 2',
    path: ADMIN_REFERENCE_VALUES_ROUTES.TAB1,
  },
  {
    title: 'Tab 3',
    path: ADMIN_REFERENCE_VALUES_ROUTES.TAB2,
  },
];
