import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState = {
  status: 'idle',
  credits: [],
};

const creditsReducer = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    setCredits: (state, action) => {
      state.credits = action.payload;
    },
  },
});

export const { setCredits } = creditsReducer.actions;

export const selectCredits = (state: RootState): any[] => state.credits.credits;

export default creditsReducer.reducer;
