import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsCompany } from '../../../../../store/reducers/admin/submissionsReducer';
import { ViewElement } from '../../../../components/ViewElement/ViewElement';
import {
  COMPANY_TYPE_NAMES,
  CompanyType,
} from '../../../../../structure/models/company/company';
import { formatDate } from '../../../../core/models/contstants/format-date';

export const AdminCompanyInfo: React.FC = () => {
  const company = useAppSelector(selectSubmissionsCompany);
  if (!company) return null;

  const certificateDate =
    company.type === CompanyType.Supplier
      ? `${formatDate(company.certificateValidityStartDate!)} - ${formatDate(
          company.certificateValidityEndDate!,
        )}`
      : 'N/A';

  const PONumber =
    company.type === CompanyType.Supplier ? company.RSBPONumber : 'N/A';

  return (
    <div className="admin-company-info view-elements-with-rows">
      <ViewElement label="Account ID" description={company.id} />
      <ViewElement label="Company Name" description={company.name} />
      <ViewElement
        label="Type of organization"
        description={COMPANY_TYPE_NAMES[company.type!]}
      />
      <ViewElement label="RSB PO Number" description={PONumber} />
      <ViewElement
        label="Certificate validity date"
        description={certificateDate}
      />
      <ViewElement
        label="Address"
        description={`${company.address} ${company.addressNumber} ${company.city}, ${company.country} ${company.zip}`}
      />
      <ViewElement
        label="Registration date"
        description={formatDate(company.createdAt)}
      />
    </div>
  );
};
