import React from 'react';
import { AdminSubmissionsDetailsLayout } from './AdminSubmissionsDetailsLayout';
import {
  fetchRegistrationApprove,
  fetchRegistrationReject,
  fetchSubmissionsCompanyInfo,
} from '../../../../API/admin/submissions';
import { COMPANY_TABS } from './models/company-tabs.constant';

export const AdminRegisterLayout: React.FC = () => {
  return (
    <AdminSubmissionsDetailsLayout
      fetchData={fetchSubmissionsCompanyInfo}
      fetchReject={fetchRegistrationReject}
      fetchApprove={fetchRegistrationApprove}
      tabs={COMPANY_TABS}
    />
  );
};
