// LOGIN
export const URL_LOGIN = 'auth/login';
export const URL_LOGOUT = 'auth/logout';
export const URL_GET_USER = 'auth';
export const URL_REFRESH_TOKEN = 'auth/refresh';

export const URL_GENERATE_TOKEN = 'share-token/generate';

export const URL_CONFIRM_EMAIL = 'email-confirmation/confirm';

// REGISTRATION
export const URL_REGISTRATION = 'auth/register';
export const URL_SET_PASSWORD = 'account/set-password';
export const URL_RESET_PASSWORD = 'account/reset-password';
export const URL_CHANGE_PASSWORD = 'account/change-password';
export const URL_FORGOT_PASSWORD = 'account/restore-password';
export const URL_CHECK_CODE = 'check_code';

//ADMIN
export const URL_ADMIN_GET_SUBMISSIONS = 'submissions';
export const URL_ADMIN_GET_SUBMISSIONS_USERS = `${URL_ADMIN_GET_SUBMISSIONS}/user-registration`;
export const URL_ADMIN_REJECT_USER_REGISTRATION = `${URL_ADMIN_GET_SUBMISSIONS}/review/user-registration/:id/decline`;
export const URL_ADMIN_APPROVE_USER_REGISTRATION = `${URL_ADMIN_GET_SUBMISSIONS}/review/user-registration/:id/approve`;
export const URL_ADMIN_GET_COMPANY_INFO = `${URL_ADMIN_GET_SUBMISSIONS_USERS}/:id`;
export const URL_ADMIN_GET_SUBMISSIONS_TRANSACTIONS = `${URL_ADMIN_GET_SUBMISSIONS}/credit-transaction`;
export const URL_ADMIN_GET_SUBMISSIONS_TRANSACTION = `${URL_ADMIN_GET_SUBMISSIONS_TRANSACTIONS}/:id`;
export const URL_ADMIN_SUBMISSIONS_TRANSACTIONS_APPROVE = `${URL_ADMIN_GET_SUBMISSIONS}/review/credit-transaction/:id/approve`;
export const URL_ADMIN_SUBMISSIONS_TRANSACTIONS_DECLINE = `${URL_ADMIN_GET_SUBMISSIONS}/review/credit-transaction/:id/decline`;

//ADMIN LOGS
export const URL_ADMIN_LOGS = 'action-logs';
export const URL_ADMIN_LOGS_DOWNLOAD = `${URL_ADMIN_LOGS}/list/download`;

//USERS
export const URL_ADMIN_GET_USERS = `/users`;
export const URL_ADMIN_EDIT_USER = `${URL_ADMIN_GET_USERS}/edit/:id`;
export const URL_ADMIN_DOWNLOAD_USERS = `${URL_ADMIN_GET_USERS}/list/download`;

//ACCOUNTS
export const URL_ADMIN_GET_ACCOUNTS = `/companies`;
export const URL_ADMIN_GET_COMPANY = `/companies/:id`;
export const URL_ADMIN_EDIT_COMPANY = `/companies/edit/:id`;

//RETIREMENT_STATEMENTS
export const URL_ADMIN_GET_RETIREMENT_STATEMENTS = `retirement-statements/public-list`;

// CERTIFICATES
export const URL_GET_CERTIFICATES = 'URL_GET_CERTIFICATES';
export const URL_GET_RETIREMENTS = 'URL_GET_RETIREMENTS';

export const URL_COMPANY = '/companies/company';
// TRANSFER
export const URL_TRANSFER = 'credits/transfer/:id';
export const URL_TRANSFER_COMPANIES = 'transfer-companies';
export const URL_TRANSFER_TSP_COMPANIES = 'transfer-companies/tsp';
export const URL_TRANSFER_TSP_SUBMISSIONS = `${URL_ADMIN_GET_SUBMISSIONS}/tsp/credit-transaction`;
export const URL_TRANSFER_TSP_SUBMISSIONS_DETAILS = `${URL_TRANSFER_TSP_SUBMISSIONS}/:id`;
export const URL_TRANSFER_TSP_SUBMISSIONS_APPROVE = `${URL_ADMIN_GET_SUBMISSIONS}/tsp/review/credit-transaction/:id/approve`;

// Credits
export const URL_BCU_REGISTRATION = 'credits/register';
export const URL_ABATEMENT_BASELINES = 'abatement-baselines';
export const URL_COMPANY_CREDITS = 'credits';
export const URL_CREDITS_RETIRE = 'credits/retire/:id';

export const URL_ADMIN_COMPANY_CREDITS = 'credits/company/:id';
export const URL_ADMIN_BCU_LIST = 'credits/list';
export const URL__GET_ADMIN_BCU_CREDIT = 'credits/:id';
export const URL_ADMIN_BCU_EDIT = 'credits/edit/:id';
export const URL_ADMIN_SPLIT_BCU = 'credits/split/:id';
export const URL_ADMIN_BCU_LIST_DOWNLOAD = 'credits/list/download';
export const URL_POS_FILE = '/credits/download-pos/:id';

// USERS
export const URL_GET_USERS = 'URL_GET_USERS';

// Transactions

export const URL_USER_TRANSACTIONS = 'credit-transactions';

export const URL_ADMIN_TRANSACTIONS = `${URL_USER_TRANSACTIONS}/company/:id`;
export const URL_ADMIN_CREDIT_TRANSACTIONS = `${URL_USER_TRANSACTIONS}/credit/:id`;

// Contact us
export const URL_CONTACT_US_AUTH = 'contact-us';
export const URL_CONTACT_US = 'contact-us/public';

// Company info
export const URL_COMPANY_INFO = 'companies/info';
