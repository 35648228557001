import { RetirementStatement } from '../../../structure/models/retirement-statements/retirement-statements';
import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../core/models/contstants/format-date';
import { config } from '../../../config';

export const RETIREMENT_STATEMENTS_COLUMNS = [
  {
    title: 'Statement ID',
    keyItem: 'id',
    sort: true,
    render: (item: RetirementStatement) => item.id,
  },
  {
    title: 'Date of Issue',
    keyItem: 'issueDate',
    sort: true,
    render: (item: RetirementStatement) =>
      formatDate(item.issueDate, DATE_WITH_TIME_FORMAT),
  },
  {
    title: 'Product Type',
    keyItem: 'credit.productType',
    sort: true,
    render: (item: RetirementStatement) => item.credit.productType,
  },
  {
    title: 'Amount BCUs',
    keyItem: 'transaction.amount',
    sort: true,
    render: (item: RetirementStatement) => `${item.transaction.amount}`,
  },
  {
    title: 'BCU ID',
    keyItem: 'credit.BCUID',
    sort: true,
    render: (item: RetirementStatement) => item.credit.BCUID,
  },
  {
    title: 'Retired By',
    keyItem: 'item.transaction.companyRetiredBy',
    sort: true,
    render: (item: RetirementStatement) => item.transaction.companyRetiredBy,
  },
  {
    title: 'On Behalf Of',
    keyItem: 'transaction.companyOnBehalfOf',
    sort: true,
    render: (item: RetirementStatement) => item.transaction.companyOnBehalfOf,
  },
  {
    title: 'Certificate',
    keyItem: 'certificate',
    render: (item: RetirementStatement) => {
      const link = `${config.API_URL}/retirement-statements/download/${item.id}`;

      return (
        <a
          className="pdf-link"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          download>
          <span className="material-icons-outlined">picture_as_pdf</span>
        </a>
      );
    },
  },
];
