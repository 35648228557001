import React from 'react';
import { Col, Container } from 'react-bootstrap';
import './AccountsStyle.scss';
import { ACCOUNTS_COLUMNS } from './models/columns.constant';
import { EditablePaginatedTable } from '../../../components/Table/Table';
import { useSearchParamsState } from '../../../components/Table/Pagination/PaginationHook';
import { fetchAccounts, fetchEditCompany } from '../../../../API/admin/accounts';
import {
  selectAccountsList,
  selectAccountsStatus,
} from '../../../../store/reducers/admin/accountsReducer';
import { Search } from '../../../components/Table/Search/Search';
import { transformData, validate } from './models/validate';
import { hasChanges } from './models/has-changes.constant';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../../store/hooks';

export const Accounts: React.FC = () => {
  const [searchQuery, setSearchQuery] = useSearchParamsState<string>(
    'search',
    '',
  );

  const rowKey = (item: any) => item.id;

  const dispatch = useAppDispatch();

  return (
    <div className="accounts">
      <Container fluid>
        <div className="accounts__header">
          <h1>Accounts</h1>
          <div className="controls">
            <Col className="search">
              <Search
                className="search__input"
                defaultValue={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            </Col>
          </div>
        </div>
        <EditablePaginatedTable
          fetchData={fetchAccounts}
          getColumns={ACCOUNTS_COLUMNS}
          selectData={selectAccountsList}
          selectStatus={selectAccountsStatus}
          filters={{
            search: searchQuery,
          }}
          rowKey={rowKey}
          validate={validate}
          transformAndDispatchData={formData => {

            return dispatch(fetchEditCompany(transformData(formData))).then(() => {
              toast.success('Success: Account has been updated');
            });
          }}
          hasChanges={hasChanges}
        />
      </Container>
    </div>
  );
};
