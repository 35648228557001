import { Option, Select, SelectProps } from '../Form/Select/Select';
import React from 'react';
import { COUNTRIES } from './models/countries.constant';

export const Country: React.FC<Omit<SelectProps<Option>, 'options'>> = ({
  placeholder = 'Country',
  ...props
}) => {
  return <Select placeholder={placeholder} options={COUNTRIES} {...props} />;
};
