import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import userReducer from './reducers/user/userReducer';
import adminSubmissionsReducer from './reducers/admin/submissionsReducer';
import { ThunkDispatch } from 'redux-thunk/src/types';
import abatementBaselinesReducer from './reducers/user/abatementBaselinesReducer';
import retirementStatementsReducer from './reducers/user/retirementStatementsReducer';
import creditsReducer from './reducers/user/creditsReducer';
import companiesSelectReducer from './reducers/user/companiesSelectReducer';
import usersReducer from './reducers/admin/usersReducer';
import transactionsReducer from './reducers/user/transactionsReducer';
import logsReducer from './reducers/admin/logsReducer';
import accountsReducer from './reducers/admin/accountsReducer';
import bcuListReducer from './reducers/admin/bcuListReducer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    userTransactions: transactionsReducer,
    adminSubmissions: adminSubmissionsReducer,
    adminLogs: logsReducer,
    adminBCUsList: bcuListReducer,
    abatementBaselines: abatementBaselinesReducer,
    retirementStatements: retirementStatementsReducer,
    credits: creditsReducer,
    companiesSelect: companiesSelectReducer,
    users: usersReducer,
    accounts: accountsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppThunkDispatch = ThunkDispatch<
  RootState,
  unknown,
  Action<string>
>;
