import { AppThunk, AppThunkDispatch } from '../../store/store';
import { actions } from '../fetchProvider/fetch';
import { AxiosResponse } from 'axios';
import { ServerResponse } from '../types';
import { PaginatedData } from '../../structure/request/pagination';
import {
  setAccountsList,
  setAccountsStatus,
  setCompany,
  updateCompany,
} from '../../store/reducers/admin/accountsReducer';
import {
  URL_ADMIN_COMPANY_CREDITS,
  URL_ADMIN_EDIT_COMPANY,
  URL_ADMIN_GET_ACCOUNTS,
  URL_ADMIN_GET_COMPANY,
  URL_ADMIN_TRANSACTIONS,
} from '../urls/urls';
import {
  setTransactions,
  setUserTransactionsStatus,
} from '../../store/reducers/user/transactionsReducer';
import { setCredits } from '../../store/reducers/user/creditsReducer';

export const fetchAccounts =
  ({ page, limit, ...query }: any): AppThunk<Promise<any>> =>
    (dispatch: AppThunkDispatch) => {
      dispatch(setAccountsStatus('loading'));

      return actions
        .get<AxiosResponse<ServerResponse<PaginatedData<any>>>>(
          URL_ADMIN_GET_ACCOUNTS,
          {
            queries: {
              skip: (page - 1) * limit,
              limit,
              ...query,
            },
          },
        )
        .then(response => {
          dispatch(setAccountsStatus('idle'));
          dispatch(setAccountsList(response.data.data));

          return response.data.data;
        })
        .catch(e => {
          dispatch(setAccountsStatus('failed'));
          throw e;
        });
    };

export const fetchEditCompany =
  ({ id, ...user }: any) =>
    (dispatch: AppThunkDispatch) => {

      return actions
        .post(URL_ADMIN_EDIT_COMPANY.replace(':id', id), {
          payloads: user,
        })
        .then((resp: any) => {
          dispatch(updateCompany(resp.data.data));

          return resp.data.data;
        });
    };

export const fetchAdminTransactions =
  ({ id, page, limit, ...query }: any) =>
    (dispatch: AppThunkDispatch) => {
      dispatch(setUserTransactionsStatus('loading'));

      return actions
        .get(URL_ADMIN_TRANSACTIONS.replace(':id', id), {
          queries: {
            skip: (page - 1) * limit,
            limit,
            ...query,
          },
        })
        .then((resp: any) => {
          dispatch(setUserTransactionsStatus('idle'));
          dispatch(setTransactions(resp.data.data));

          return resp.data.data;
        })
        .catch(e => {
          dispatch(setUserTransactionsStatus('failed'));

          throw e;
        });
    };

export const fetchAdminCredits = (id: string) => (dispatch: any) => {
  return actions
    .get(URL_ADMIN_COMPANY_CREDITS.replace(':id', id))
    .then((resp: any) => dispatch(setCredits(resp.data.data)));
};

export const fetchCompany = (id: string) => (dispatch: AppThunkDispatch) => {
  return actions
    .get(URL_ADMIN_GET_COMPANY.replace(':id', id))
    .then((resp: any) => dispatch(setCompany(resp.data.data)));
};
