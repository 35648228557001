import React, { useEffect, useState } from 'react';
import { UserBalance } from '../components/UserBalance/UserBalance';
import { Col, Row } from 'react-bootstrap';
import { Input } from '../components/Form/Input';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import {
  fetchAddTransferCompany,
  fetchAddTransferTSPCompany,
  fetchTransferCompanies,
  fetchTransferTSPCompanies,
} from '../../API/transfer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectCredits } from '../../store/reducers/user/creditsReducer';
import { selectUser } from '../../store/reducers/user/userReducer';
import { CompanyType } from '../../structure/models/company/company';
import { useFormActions } from '../components/Form/FormHook';
import { Select } from '../components/Form/Select/Select';
import { CompanySelect } from '../transfer/CompanySelect/CompanySelect';
import { Country } from '../components/Address/Country';
import { validationFactory } from '../core/helpers/validationFactory';
import {
  selectSelectCompanies,
  selectSelectTSPCompanies,
} from '../../store/reducers/user/companiesSelectReducer';
import { validators } from './helpers/validations';
import { getTransferDataFromFormData } from './helpers/transform';
import { fetchCreditRetire, fetchCredits } from '../../API/credits';
import { useNavigate } from 'react-router-dom';
import { STATEMENTS } from '../../structure/routes/routes';
import { fetchCompanyData } from '../../API/user';
import { SAF_USE_PURPOSE_OPTIONS } from '../transfer/helpers/options.constant';

import './RetirementStyle.scss';

const { createValidate } = validationFactory();

export const Retirement: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({
    credit: '',
    amount: '',
    retiredBy: '',
    TSP: '',
    creditClaimCountry: '',
  });

  const credits = useAppSelector(selectCredits);
  const selectedCredit = credits.find(({ id }) => formData.credit.value === id);
  const user = useAppSelector(selectUser);
  const creditsOptions = credits.map(({ id, BCUID }) => ({
    value: id,
    label: BCUID,
  }));

  const isTSP = user?.company.type === CompanyType.Airline;

  useEffect(() => {
    if (!user) return;
    dispatch(fetchCredits());
    dispatch(fetchCompanyData());
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      TSP: selectedCredit?.TSP?.id || '',
      creditClaimCountry: selectedCredit?.creditClaimCountry || '',
      SAFUsePurpose: selectedCredit?.SAFUsePurpose || '',
    });
  }, [selectedCredit]);

  const maxVolume = formData.credit?.value ? selectedCredit?.volume || 0 : 0;

  const validate = createValidate(validators, {
    amount: [maxVolume],
    tsp: [selectedCredit?.TSP?.id, isTSP],
  });

  const transformAndDispatchData = (data: FormData) => {
    const transferData = getTransferDataFromFormData(data, formData);

    return dispatch(fetchCreditRetire(transferData)).then(() => {
      toast.success('Success: Your retirement request has been sent');

      navigate(STATEMENTS);
    });
  };

  const { loading, onSubmit, showErrorsBlock } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  const createOnChange = (field: string) => (item: any) => {
    const value = item?.target ? item.target.value : item;

    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const lockSubmit =
    !formData.credit || !formData.amount || !formData.retiredBy || loading;

  return (
    <div>
      <UserBalance company={user!.company} />
      <div className="transfer_container">
        <form onSubmit={onSubmit}>
          <div>
            <h1>BCU Retirement</h1>
            <Row>
              <Col>
                <Select
                  name="credit"
                  onChange={createOnChange('credit')}
                  placeholder="BCU ID"
                  options={creditsOptions}
                />
              </Col>
              <Col>
                <Input
                  placeholder="Amount BCUs"
                  name="amount"
                  type="number"
                  min={0}
                  disabled={!formData.credit}
                  max={maxVolume}
                  onChange={createOnChange('amount')}
                  value={formData.amount}
                  required
                  step="0.001"
                  error={showErrorsBlock['volume']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CompanySelect
                  fetchCompanies={fetchTransferCompanies}
                  fetchAddCompany={fetchAddTransferCompany}
                  selectCompanies={selectSelectCompanies}
                  onChange={createOnChange('retiredBy')}
                  placeholder="Company name retiree 1"
                  name="retired_by"
                  required
                  error={showErrorsBlock['retired_by']}
                />
              </Col>
              <Col>
                <CompanySelect
                  fetchCompanies={fetchTransferCompanies}
                  fetchAddCompany={fetchAddTransferCompany}
                  selectCompanies={selectSelectCompanies}
                  onChange={createOnChange('onBehalfOf')}
                  placeholder="Company name retiree 2"
                  name="on_behalf_of"
                  error={showErrorsBlock['on_behalf_of']}
                  description="Fill in if you want to retire BCU on behalf of someone"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {!isTSP
                  ? !(
                      formData.SAFUsePurpose && formData.creditClaimCountry
                    ) && (
                      <CompanySelect
                        fetchCompanies={fetchTransferTSPCompanies}
                        fetchAddCompany={fetchAddTransferTSPCompany}
                        selectCompanies={selectSelectTSPCompanies}
                        placeholder="Name Transportation Service Provider"
                        name="tsp"
                        value={selectedCredit?.TSP?.id}
                        isDisabled={!isTSP}
                        onChange={createOnChange('TSP')}
                        error={showErrorsBlock['tsp']}
                      />
                    )
                  : null}
              </Col>
              <Col>
                <Country
                  placeholder="Country where BCU is claimed"
                  name="credit_claim_country"
                  isDisabled={!isTSP}
                  value={formData.creditClaimCountry}
                  onChange={createOnChange('creditClaimCountry')}
                  error={showErrorsBlock['credit_claim_country']}
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>
                <Select
                  placeholder="Use of the SAF for domestic or international purposes"
                  name="SAFUsePurpose"
                  options={SAF_USE_PURPOSE_OPTIONS}
                  onChange={createOnChange('SAFUsePurpose')}
                  value={formData.SAFUsePurpose}
                  isDisabled={!isTSP}
                  error={showErrorsBlock['SAFUsePurpose']}
                />
              </Col>
            </Row>
          </div>
          <div className="action-buttons">
            <BaseButtonSubmit
              lock={lockSubmit}
              className={classNames({
                base_color: !lockSubmit,
              })}>
              Submit
            </BaseButtonSubmit>
          </div>
        </form>
      </div>
    </div>
  );
});
