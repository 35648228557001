import {
  ADMIN_ACCOUNTS_AND_BALANCE_ROUTES,
  ADMIN_BCU_DETAILS_ROUTES,
  ADMIN_BCUS,
  ADMIN_LOGS,
  ADMIN_REFERENCE_VALUES,
  ADMIN_REFERENCE_VALUES_ROUTES,
  ADMIN_SUBMISSIONS_LIST,
  ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
  ADMIN_USERS,
  DASHBOARD_ROUTES,
  STATEMENTS,
  TRANSFER_REQUEST,
  TRANSFER_ROUTES,
} from '../../../../structure/routes/routes';

export const TABS = [
  { path: STATEMENTS, title: 'Statements' },
  // user tabs
  { path: DASHBOARD_ROUTES.TRANSACTIONS, title: 'Dashboard' },
  { path: DASHBOARD_ROUTES.REGISTER_CREDIT, title: 'BCU Registration' },
  {
    path: DASHBOARD_ROUTES.TRANSFER,
    title: 'BCU Transfer',
    childRoutes: [TRANSFER_ROUTES.REQUESTS, TRANSFER_REQUEST],
  },
  { path: DASHBOARD_ROUTES.RETIREMENT, title: 'BCU Retirement' },
  // admin tabs
  {
    path: ADMIN_BCUS,
    title: 'BCUs',
    childRoutes: [
      ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS,
      ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_HISTORY,
    ],
  },
  { path: ADMIN_LOGS, title: 'Logs' },
  {
    path: ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.MAIN,
    title: 'Accounts & Balance',
    childRoutes: [ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS],
  },
  { path: ADMIN_USERS, title: 'Users' },
  {
    path: ADMIN_SUBMISSIONS_LIST,
    title: 'Submissions List',
    childRoutes: [
      ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USERS_INFO,
      ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO,
      ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.NOTE,
      ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.USERS_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.NOTE,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.USERS_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.COMPANY_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.TRANSFER_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.BCU_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.NOTE,
    ],
  },
  {
    path: ADMIN_REFERENCE_VALUES,
    title: 'Reference Values',
    childRoutes: [
      ADMIN_REFERENCE_VALUES_ROUTES.CERTIFICATION_SCHEMES,
      ADMIN_REFERENCE_VALUES_ROUTES.TAB1,
      ADMIN_REFERENCE_VALUES_ROUTES.TAB2,
    ],
  },
];
