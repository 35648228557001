import { ADMIN_BCU_DETAILS_ROUTES } from '../../../../../structure/routes/routes';

export const TABS = [
  {
    title: 'Split BCU',
    path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS,
  },
  {
    title: 'Transactions History',
    path: ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_HISTORY,
  },
];
