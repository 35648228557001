export const getTransferDataFromFormData = (formData: FormData): any => {
  const BCUID = formData.get('credit')?.toString() ?? '';
  const receiverId = formData.get('company_buyer_name')?.toString() ?? '-1';
  const amount = formData.get('volume')?.toString() ?? '-1';
  const tspId = formData.get('transportation_service_provide')?.toString();
  const creditClaimCountry = formData.get('credit_claim_country')?.toString();
  const SAFUsePurpose = formData.get('saf_purpose')?.toString();

  return Object.entries({
    BCUID,
    receiverId,
    amount: +amount,
    tspId,
    creditClaimCountry,
    SAFUsePurpose,
  }).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(value ? { [key]: value } : {}),
    }),
    {},
  );
};
