import {
  ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES,
} from '../../../../../structure/routes/routes';

export const BCU_TRANSFER_TABS = [
  {
    title: 'Company Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.COMPANY_INFO,
  },
  {
    title: 'Users Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.USERS_INFO,
  },
  {
    title: 'BCU Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.BCU_INFO,
  },
  {
    title: 'BCU Transfer Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.TRANSFER_INFO,
  },
  {
    title: 'Note',
    path: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.NOTE,
  },
];

export const BCU_RETIREMENTS_TABS = [
  {
    title: 'Company Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.COMPANY_INFO,
  },
  {
    title: 'Users Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.USERS_INFO,
  },
  {
    title: 'BCU Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.BCU_INFO,
  },
  {
    title: 'BCU Retirement Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.RETIREMENT_INFO,
  },
  {
    title: 'Note',
    path: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.NOTE,
  },
];
