import React from 'react';
import { Select } from '../../Form/Select/Select';
import {
  ActionMeta,
  OnChangeValue,
} from 'react-select/dist/declarations/src/types';
import { Col, Row } from 'react-bootstrap';
import './Pagination.scss';

type Props = {
  limit: number;
  changeLimit: (newLimit: number) => void;
};

type Ops = {
  readonly value: number;
  readonly label: string;
};

const options: Ops[] = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 75, label: '75' },
  { value: 100, label: '100' },
];

export const ShowBy: React.FC<Props> = React.memo(({ limit, changeLimit }) => {
  const onChange = (
    newValue: OnChangeValue<Ops, false>,
    actionMeta: ActionMeta<Ops>,
  ) => {
    changeLimit(newValue?.value ?? options[0].value);
  };

  return (
    <div className="show_by">
      <Row>
        <Col className="title">Rows per page</Col>
        <Col>
          <Select
            onChange={onChange}
            options={options}
            defaultValue={limit as any}
          />
        </Col>
      </Row>
    </div>
  );
});
