import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';

import './TabElementStyle.scss';

type Props = {
  link: string;
  replace: boolean;
  childRoutes?: string[];
};

export const TabElement: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({ children, link, replace, childRoutes: rawChildRoutes }) => {
    const location = useLocation();
    const params = useParams();
    const childRoutes = rawChildRoutes?.map(route =>
      Object.entries(params).reduce(
        (acc, [key, value]) => acc.replace(`:${key}`, value!),
        route,
      ),
    );
    // TODO move it to separated method
    const path = Object.entries(params).reduce(
      (acc, [key, value]) => acc.replace(`:${key}`, value!),
      link,
    );
    const active =
      location.pathname === path || childRoutes?.includes(location.pathname);

    const onClick = (e: React.MouseEvent) => {
      if (active) {
        e.preventDefault();
      }
    };

    return (
      <Link
        to={path}
        className={classNames('tab_element', {
          active,
        })}
        onClick={onClick}
        replace={replace}>
        {children}
      </Link>
    );
  },
);
