import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserType } from '../../../structure/models/user/user';
import { RootState } from '../../store';

interface UserState {
  userData?: User;
  token?: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: UserState = {
  status: 'idle',
  token: localStorage.getItem('accessToken')!,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem('accessToken', state.token);
    },
    setUserData: (state, action: PayloadAction<User>) => {
      state.userData = action.payload;
    },
    setCompanyData: (state, action) => {
      state.userData!.company = action.payload;
    },
    setCompanyInfo: (state, action) => {
      if (action.payload && action.payload.length) {
        state.userData!.company.secondaryContactInfo = action.payload.find(
          (user: any) => user.type === UserType.Secondary,
        );
      }
    },
    logout: state => {
      state.userData = undefined;
      state.token = undefined;
      localStorage.removeItem('accessToken');
    },
  },
});

export const {
  changeToken,
  setUserData,
  logout,
  setCompanyData,
  setCompanyInfo,
} = userSlice.actions;
export const selectStatus = (state: RootState) => state.user.status;
export const selectToken = (state: RootState) => state.user.token;
export const selectUser = (state: RootState) => state.user.userData;

export default userSlice.reducer;
