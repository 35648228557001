import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';
import { ViewElement } from '../../../../components/ViewElement/ViewElement';

export const AdminRetirementInfo: React.FC = () => {
  const details = useAppSelector(selectSubmissionsDetails);
  if (!details) return null;

  return (
    <div className="admin-transfer-info view-elements-with-rows">
      <ViewElement label="BCUI ID" description={details?.credit?.BCUID} />
      <ViewElement label="Amount BCUs" description={details?.amount} />
      <ViewElement
        label="Company name retiree 1 Retired by"
        description={details?.companyRetiredBy?.name}
      />
      <ViewElement
        label="Company name retiree 2 On behalf of"
        description={details?.companyOnBehalfOf?.name}
      />
      <ViewElement
        label="Name Transportation Service Provider"
        description={details?.TSP?.name || details?.credit.TSP?.name}
      />
      <ViewElement
        label="Country where SAF Credit will be claimed"
        description={
          details?.creditClaimCountry || details?.credit?.creditClaimCountry
        }
      />
      <ViewElement
        label="Use of the SAF for domestic or international purposes"
        description={details?.SAFUsePurpose || details?.credit?.SAFUsePurpose}
      />
    </div>
  );
};
