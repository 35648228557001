import { PaginatedData } from '../../../structure/request/pagination';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { RetirementStatement } from '../../../structure/models/retirement-statements/retirement-statements';

interface RetirementStatementsState {
  status: 'idle' | 'loading' | 'failed';
  publicList: {
    count: number;
    results: RetirementStatement[];
  };
}

const initialState: RetirementStatementsState = {
  status: 'idle',
  publicList: {
    count: 0,
    results: [],
  },
};

export const retirementStatementsSlice = createSlice({
  name: 'retirement-statements',
  initialState,
  reducers: {
    setRetirementStatementsPublicList: (
      state,
      action: PayloadAction<PaginatedData<RetirementStatement>>,
    ) => {
      state.publicList = action.payload;
    },
    setRetirementStatementsStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'failed'>,
    ) => {
      state.status = action.payload;
    },
  },
});

export const {
  setRetirementStatementsStatus,
  setRetirementStatementsPublicList,
} = retirementStatementsSlice.actions;

export const selectRetirementStatementsPublicList = (state: RootState) =>
  state.retirementStatements.publicList;
export const selectRetirementStatementsStatus = (state: RootState) =>
  state.retirementStatements.status;

export default retirementStatementsSlice.reducer;
