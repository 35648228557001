import { actions } from '../fetchProvider/fetch';
import { AxiosResponse } from 'axios';
import {
  URL__GET_ADMIN_BCU_CREDIT,
  URL_ADMIN_BCU_EDIT,
  URL_ADMIN_BCU_LIST,
  URL_ADMIN_BCU_LIST_DOWNLOAD,
  URL_ADMIN_CREDIT_TRANSACTIONS,
  URL_ADMIN_SPLIT_BCU,
} from '../urls/urls';
import fileDownload from 'js-file-download';
import { AppThunk, AppThunkDispatch } from '../../store/store';
import { ServerResponse } from '../types';
import { PaginatedData } from '../../structure/request/pagination';
import {
  setAdminCredit,
  setBCUsList,
  setBCUsListStatus,
  setSplitBCU,
  updateBCU,
} from '../../store/reducers/admin/bcuListReducer';
import {
  setTransactions,
  setUserTransactionsStatus,
} from '../../store/reducers/user/transactionsReducer';

export const fetchAdminBCUs =
  ({ page, limit, ...query }: any): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setBCUsListStatus('loading'));

    return actions
      .get<AxiosResponse<ServerResponse<PaginatedData<any>>>>(
        URL_ADMIN_BCU_LIST,
        {
          queries: {
            skip: (page - 1) * limit,
            limit,
            ...query,
          },
        },
      )
      .then(response => {
        dispatch(setBCUsListStatus('idle'));
        dispatch(setBCUsList(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setBCUsListStatus('failed'));
        throw e;
      });
  };

export const fetchAdminEditBCU =
  ({ id, ...bcu }: any) =>
  (dispatch: AppThunkDispatch) => {
    return actions
      .post(URL_ADMIN_BCU_EDIT.replace(':id', id), {
        payloads: bcu,
      })
      .then((resp: any) => {
        dispatch(updateBCU(resp.data.data));

        return resp.data.data;
      });
  };

export const fetchAdminSplitBCU =
  (id: string) => (dispatch: AppThunkDispatch) => {
    return actions
      .get(URL_ADMIN_SPLIT_BCU.replace(':id', id))
      .then((resp: any) => {
        dispatch(setSplitBCU(resp.data.data));

        return resp.data.data;
      });
  };

export const fetchAdminBCUCredit =
  (id: string) => (dispatch: AppThunkDispatch) => {
    return actions
      .get(URL__GET_ADMIN_BCU_CREDIT.replace(':id', id))
      .then((resp: any) => {
        dispatch(setAdminCredit(resp.data.data));

        return resp.data.data;
      });
  };

export const fetchAdminCreditTransactions =
  ({ id, page, limit, ...query }: any) =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setUserTransactionsStatus('loading'));

    return actions
      .get(URL_ADMIN_CREDIT_TRANSACTIONS.replace(':id', id), {
        queries: {
          skip: (page - 1) * limit,
          limit,
          ...query,
        },
      })
      .then((resp: any) => {
        dispatch(setUserTransactionsStatus('idle'));
        dispatch(setTransactions(resp.data.data));

        return resp.data.data;
      })
      .catch(e => {
        dispatch(setUserTransactionsStatus('failed'));

        throw e;
      });
  };

export const downloadAdminBCUs = (): Promise<any> =>
  actions
    .get<AxiosResponse>(URL_ADMIN_BCU_LIST_DOWNLOAD)
    .then(response => {
      fileDownload(response.data, 'BCUs.csv');
    })
    .catch(e => {
      throw e;
    });
