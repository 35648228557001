import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { ViewElement } from '../components/ViewElement/ViewElement';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  fetchTSPSubmissionsDetails,
  fetchTSPTransferApprove,
} from '../../API/transfer';
import { selectSubmissionsDetails } from '../../store/reducers/admin/submissionsReducer';
import { Country } from '../components/Address/Country';
import { Select } from '../components/Form/Select/Select';
import { SAF_USE_PURPOSE_OPTIONS } from './helpers/options.constant';
import { useFormActions } from '../components/Form/FormHook';
import { TSPApproveValidate, TSPTransformData } from './helpers/validations';
import { toast } from 'react-toastify';

import './TransferRequestStyle.scss';

export const TransferRequest: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const details = useAppSelector(selectSubmissionsDetails);
  const goBack = () => navigate(-1);

  const { onSubmit, loading, showErrorsBlock } = useFormActions({
    validate: TSPApproveValidate,
    transformAndDispatchData: (formData: FormData) => {
      return dispatch(
        fetchTSPTransferApprove({ id, ...TSPTransformData(formData) }),
      ).then(() => {
        toast.success('Success: Transfer approved');

        goBack();
      });
    },
  });

  useEffect(() => {
    dispatch(fetchTSPSubmissionsDetails(id!));
  }, []);

  if (!details) {
    return null;
  }

  return (
    <Container className="transfer-request">
      <div className="transfer-request__back" onClick={goBack}>
        <span className="material-icons-outlined">arrow_back_ios</span>
        BCU Transfer Requests List
      </div>
      <div className="transfer-request__wrapper">
        <form onSubmit={onSubmit}>
          <Row>
            <h1>BCU Transfer Requests</h1>
          </Row>
          <div className="view-elements-with-rows">
            <ViewElement
              label="BCU ID"
              description={details?.newCredit?.BCUID || details?.credit?.BCUID}
            />
            <ViewElement label="Amount BCUs" description={details.amount} />
            <ViewElement
              label="Company name buyer/token"
              description={details.receiver?.name}
            />
            <ViewElement
              label="Company name transportation service provider/token"
              description={details.TSP?.name}
            />
            <ViewElement
              label="Country where BCU is claimed"
              description={
                <Country
                  required
                  placeholder="Country where BCU is claimed"
                  name="credit_claim_country"
                  error={showErrorsBlock['credit_claim_country']}
                  isDisabled={details.isTSPApproved}
                  defaultValue={
                    details?.creditClaimCountry ||
                    details.newCredit?.creditClaimCountry
                  }
                />
              }
            />
            <ViewElement
              label="Use of the SAF for domestic or international purposes"
              description={
                <Select
                  placeholder="Use of the SAF for domestic or international purposes"
                  required
                  options={SAF_USE_PURPOSE_OPTIONS}
                  name="SAFPurpose"
                  defaultValue={
                    details.SAFUsePurpose || details.newCredit?.SAFUsePurpose
                  }
                  isDisabled={details.isTSPApproved}
                  error={showErrorsBlock['SAFPurpose']}
                />
              }
            />
          </div>
          {!details.isTSPApproved && (
            <div className="action-buttons">
              <BaseButtonSubmit active loading={loading} lock={loading}>
                Submit
              </BaseButtonSubmit>
            </div>
          )}
        </form>
      </div>
    </Container>
  );
};
