import { AppThunk, AppThunkDispatch } from '../store/store';
import { actions } from './fetchProvider/fetch';
import { URL_ADMIN_GET_RETIREMENT_STATEMENTS } from './urls/urls';
import { AxiosResponse } from 'axios';
import { PaginatedData } from '../structure/request/pagination';
import { ServerResponse } from './types';
import {
  setRetirementStatementsPublicList,
  setRetirementStatementsStatus,
} from '../store/reducers/user/retirementStatementsReducer';
import {
  RetirementStatement,
  RetirementStatementsRequest,
} from '../structure/models/retirement-statements/retirement-statements';

export const fetchRetirementStatements =
  ({
    page,
    limit,
    ...query
  }: RetirementStatementsRequest): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setRetirementStatementsStatus('loading'));

    return actions
      .get<AxiosResponse<ServerResponse<PaginatedData<RetirementStatement>>>>(
        URL_ADMIN_GET_RETIREMENT_STATEMENTS,
        {
          queries: {
            skip: (page - 1) * limit,
            limit,
            ...query,
          },
        },
      )
      .then(response => {
        dispatch(setRetirementStatementsStatus('idle'));
        dispatch(setRetirementStatementsPublicList(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setRetirementStatementsStatus('failed'));
        throw e;
      });
  };
