export const ANY = '*';
export const STATEMENTS = '/';

const subRoute = (mainRoute: string, subRoute: string) =>
  `${mainRoute}/${subRoute}`;

export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const REGISTRATION = '/registration';
export const CHANGE_PASSWORD = '/change-password';
export const SET_PASSWORD = '/set-password';
export const RESET_PASSWORD = '/reset-password';
export const VERIFY_EMAIL = '/verify-email';
export const CONTACT_US = '/contact-us';

export const DASHBOARD = '/dashboard';

export const TRANSFER = 'transfer';
export const LOGS = 'logs';
export const RETIREMENT = 'retirement';
export const REGISTER_CREDIT = 'register-credit';

export const DASHBOARD_ROUTES = {
  TRANSFER: subRoute(DASHBOARD, TRANSFER),
  TRANSACTIONS: DASHBOARD,
  RETIREMENT: subRoute(DASHBOARD, RETIREMENT),
  REGISTER_CREDIT: subRoute(DASHBOARD, REGISTER_CREDIT),
};

export const TRANSFER_ROUTES = {
  TRANSFER: DASHBOARD_ROUTES.TRANSFER,
  REQUESTS: subRoute(DASHBOARD_ROUTES.TRANSFER, 'requests'),
};

export const TRANSFER_REQUEST = subRoute(TRANSFER_ROUTES.REQUESTS, '/:id');

// profile
export const USER_PROFILE = '/profile';
export const COMPANY_PROFILE = 'company';

export const PROFILE_ROUTES = {
  USER_PROFILE: USER_PROFILE,
  COMPANY_PROFILE: subRoute(USER_PROFILE, COMPANY_PROFILE),
};

// admin
const ADMIN = '/admin';
export const ADMIN_BCUS = subRoute(ADMIN, 'bcus');
export const ADMIN_BCU_DETAILS = subRoute(ADMIN_BCUS, ':creditId');
export const ADMIN_BCU_DETAILS_ROUTES = {
  ADMIN_BCU_DETAILS,
  ADMIN_BCU_HISTORY: subRoute(ADMIN_BCU_DETAILS, 'history'),
};
export const ADMIN_LOGS = subRoute(ADMIN, 'logs');
export const ADMIN_ACCOUNTS_AND_BALANCE = subRoute(
  ADMIN,
  'accounts-and-balance',
);

export const ADMIN_ACCOUNTS_AND_BALANCE_ROUTES = {
  MAIN: ADMIN_ACCOUNTS_AND_BALANCE,
  DETAILS: subRoute(ADMIN_ACCOUNTS_AND_BALANCE, ':id'),
};

export const ADMIN_USERS = subRoute(ADMIN, 'users');

export const ADMIN_SUBMISSIONS_LIST = subRoute(ADMIN, 'submission-lists');

export const ADMIN_SUBMISSIONS_LIST_COMPANY_INFO = subRoute(
  ADMIN_SUBMISSIONS_LIST,
  'company-info/:id',
);

export const ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES = {
  COMPANY_INFO: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO,
  USERS_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_COMPANY_INFO, 'users'),
  NOTE: subRoute(ADMIN_SUBMISSIONS_LIST_COMPANY_INFO, 'note'),
};

export const ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO = subRoute(
  ADMIN_SUBMISSIONS_LIST,
  'bcu-credit/:id',
);

export const ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES = {
  COMPANY_INFO: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO,
  USERS_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO, 'users'),
  BCU_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO, 'bcu-info'),
  NOTE: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO, 'note'),
};

export const ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO = subRoute(
  ADMIN_SUBMISSIONS_LIST,
  'bcu-transfer/:id',
);

export const ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES = {
  COMPANY_INFO: ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO,
  USERS_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO, 'users'),
  BCU_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO, 'bcu-info'),
  TRANSFER_INFO: subRoute(
    ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO,
    'transfer-info',
  ),
  NOTE: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO, 'note'),
};

export const ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO = subRoute(
  ADMIN_SUBMISSIONS_LIST,
  'bcu-retirement/:id',
);

export const ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES = {
  COMPANY_INFO: ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO,
  USERS_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO, 'users'),
  BCU_INFO: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO, 'bcu-info'),
  RETIREMENT_INFO: subRoute(
    ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO,
    'retirement-info',
  ),
  NOTE: subRoute(ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO, 'note'),
};

export const ADMIN_REFERENCE_VALUES = subRoute(ADMIN, 'reference-values');

export const ADMIN_REFERENCE_VALUES_ROUTES = {
  CERTIFICATION_SCHEMES: ADMIN_REFERENCE_VALUES,
  TAB1: subRoute(ADMIN_REFERENCE_VALUES, 'tab1'),
  TAB2: subRoute(ADMIN_REFERENCE_VALUES, 'tab2'),
};
