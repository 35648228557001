import React, { HTMLProps } from 'react';
import classNames from 'classnames';
import './CleanButtonStyle.scss';

interface Props extends HTMLProps<HTMLDivElement> {
  lock?: boolean;
}

export const CleanButton: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({ className, onClick, children, lock, ...props }) => {
    const customOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!lock && onClick) {
        onClick(e);
      }
    };

    return (
      <div
        className={classNames(className, 'clear_button', {
          clear_button_lock: lock,
        })}
        {...props}
        onClick={customOnClick}
      >
        {children}
      </div>
    );
  },
);
