import { validationFactory } from '../../../../core/helpers/validationFactory';
import {
  collectPhone,
  phoneValidator,
} from '../../../../components/Form/Phone';

const { createValidator, createValidate } = validationFactory();

export const validate = createValidate({
  firstname: createValidator((value: string) => !!value, 'Name is required'),
  lastname: createValidator((value: string) => !!value, 'Surname is required'),
  position: createValidator((value: string) => !!value, 'Surname is required'),
  email: createValidator(
    (value: string) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value),
    'Email must have correct format.',
  ),
  ...phoneValidator('cellPhone', (value: string) => !!value),
});

export const transformData = (formData: FormData) => {
  const id = formData.get('id');
  const firstname = formData.get('firstname');
  const lastname = formData.get('lastname');
  const email = formData.get('email');
  const cellPhone = collectPhone(formData, 'cellPhone', 'cellPhone');
  const position = formData.get('position');

  return {
    id,
    firstname,
    lastname,
    email,
    ...cellPhone,
    position,
  };
};
