import { actions } from './fetchProvider/fetch';
import { AppThunkDispatch } from '../store/store';
import { setCompanyInfo } from '../store/reducers/user/userReducer';
import { URL_COMPANY_INFO } from './urls/urls';

export const fetchCompanyInfo = () => (dispatch: AppThunkDispatch) => {
  return actions
    .get(URL_COMPANY_INFO)
    .then((resp: any) => dispatch(setCompanyInfo(resp.data.data.users)));
};
