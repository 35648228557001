import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FormError } from '../../core/models/interfaces/formError';
import { AxiosError, AxiosResponse } from 'axios';

export type FormActionHookProps = {
  validate: (
    entries: IterableIterator<[string, FormDataEntryValue]>,
  ) => (true | FormError)[];
  transformAndDispatchData: (formData: FormData) => Promise<any>;
};

export type ErrorBlocks = {
  [key: string]: string;
};

export function useFormActions({
  validate,
  transformAndDispatchData,
}: FormActionHookProps) {
  const [errors, setErrors] = useState<FormError[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasErrors] = useState(false);

  const showErrorsBlock: ErrorBlocks = errors.reduce(
    (acc, { key, description }: FormError) => ({
      ...acc,
      [key]: description,
    }),
    {},
  );

  const sendForm = (formData: FormData) => {
    setLoading(true);
    transformAndDispatchData(formData)
      .then(() => {
        setErrors([]);
        setHasErrors(false);
      })
      .catch((error: AxiosError) => {
        const responseData = error.response as AxiosResponse<{
          message: string | string[];
        }>;
        const message = Array.isArray(responseData.data.message)
          ? (responseData.data.message as string[]).join('\n')
          : responseData.data.message;
        toast.error(`Error: ${message}`);
        setHasErrors(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const formData = new FormData(event.currentTarget);
    const validateData = validate(formData.entries());
    if (validateData.every(d => d === true)) {
      sendForm(formData);
      setErrors([]);
    } else {
      setErrors(validateData.filter(error => error !== true) as FormError[]);
    }
  };

  return {
    loading,
    setLoading,
    onSubmit,
    showErrorsBlock,
    hasErrors: hasError || errors.length > 0,
  };
}
