import { PaginatedData } from '../../../structure/request/pagination';
import {
  SubmissionsCompanyInfo,
  SubmissionsUsers,
  SubmissionsTransactions,
  SubmissionsCompany,
} from '../../../structure/models/admin/submissions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SubmissionsState {
  users: PaginatedData<SubmissionsUsers>;
  transactions: PaginatedData<SubmissionsTransactions>;
  details: SubmissionsCompanyInfo | any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: SubmissionsState = {
  status: 'idle',
  users: {
    count: 0,
    results: [],
  },
  transactions: {
    count: 0,
    results: [],
  },
  details: {} as any,
};

export const adminSubmissionsSlice = createSlice({
  name: 'admin-submissions',
  initialState,
  reducers: {
    setSubmissionsUsers: (
      state,
      action: PayloadAction<PaginatedData<SubmissionsUsers>>,
    ) => {
      state.users = action.payload;
    },
    setSubmissionsTransactions: (
      state,
      action: PayloadAction<PaginatedData<SubmissionsTransactions>>,
    ) => {
      state.transactions = action.payload;
    },
    // TODO fix it
    setStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'failed'>,
    ) => {
      state.status = action.payload;
    },
    setSubmissionsDetails: (
      state,
      action: PayloadAction<SubmissionsCompanyInfo>,
    ) => {
      state.details = {
        ...action.payload,
        message: action.payload.message || '',
      };
    },
    setSubmissionsMessage: (state, action) => {
      state.details.message = action.payload;
    },
  },
});

export const {
  setStatus,
  setSubmissionsUsers,
  setSubmissionsTransactions,
  setSubmissionsDetails,
  setSubmissionsMessage,
} = adminSubmissionsSlice.actions;

export const selectSubmissionsUsers = (state: RootState) =>
  state.adminSubmissions.users;
export const selectSubmissionsTransactions = (state: RootState) =>
  state.adminSubmissions.transactions;
export const selectSubmissionsStatus = (state: RootState) =>
  state.adminSubmissions.status;
export const selectSubmissionsDetailsStatus = (state: RootState) =>
  state.adminSubmissions.details.status;
export const selectSubmissionsCompany = (
  state: RootState,
): SubmissionsCompany => state.adminSubmissions.details?.company;
export const selectSubmissionsMessage = (state: RootState) =>
  state.adminSubmissions.details.message || '';
export const selectSubmissionsDetails = (state: RootState) =>
  state.adminSubmissions.details.target;

export default adminSubmissionsSlice.reducer;
