export const getTransferDataFromFormData = (
  formData: FormData,
  stateFormData: any,
): any => {
  const id = formData.get('credit');
  const companyIdOnBehalfOf = formData.get('on_behalf_of');
  const companyIdRetiredBy = formData.get('retired_by');
  const creditClaimCountry =
    formData.get('credit_claim_country') || stateFormData.creditClaimCountry;
  const amount = formData.get('amount');
  const SAFUsePurpose =
    formData.get('SAFUsePurpose') || stateFormData.SAFUsePurpose;

  return Object.entries({
    id,
    companyIdOnBehalfOf,
    companyIdRetiredBy,
    creditClaimCountry,
    amount,
    SAFUsePurpose,
  }).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(value ? { [key]: value } : {}),
    }),
    {},
  );
};
