const toOptions = (arr: string[]) =>
  arr.map(value => ({ label: value, value }));

export const RAW_MATERIALS = toOptions([
  'Corn grain',
  'Corn oil (from dry mill ethanol plant)',
  'Forestry residues',
  'Miscanthus (herbaceous energy crops)',
  'Municipal solid waste (MSW)',
  'Palm fatty acid distillate',
  'Palm oil – closed pond',
  'Palm oil – open pond',
  'Poplar (short-rotation woody crops)',
  'Rapeseed oil',
  'Soybean oil',
  'Sugarcane',
  'Switchgrass (herbaceous energy crops)',
  'Tallow',
  'Used cooking oil',
]);

export const PRODUCT_TYPES = toOptions(['SAF']);

export const CUSTODY_MODELS = toOptions(['MassBalance']);

export const CERTIFICATION_SCHEMES = [
  { value: 'RSB_Global', label: 'RSB Global' },
  { value: 'RSB_EU_Red', label: 'RSB EU Red' },
  { value: 'RSB_CORSIA', label: 'RSB CORSIA' },
  { value: 'EU_Red', label: 'EU Red' },
  { value: 'EU_Red2', label: 'EU Red 2' },
  { value: 'ICAO_CORSIA', label: 'ICAO CORSIA' },
];

export const CONVERSION_PROCESSES = toOptions([
  'FT',
  'HEFA',
  'SIP',
  'FT-SKA',
  'ATJ-SPK',
  'CHJ',
  'HC-HEFA-SPK',
]);
