import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface AbatementBaselinesState {
  baseLines: any[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AbatementBaselinesState = {
  baseLines: [],
  status: 'idle',
};

const abatementBaselinesReducer = createSlice({
  name: 'abatement-base-lines',
  initialState,
  reducers: {
    setBaseLines: (state, action) => {
      state.baseLines = action.payload;
    },
  },
});

export const { setBaseLines } = abatementBaselinesReducer.actions;

export const selectBaseLines = (state: RootState) =>
  state.abatementBaselines.baseLines;

export default abatementBaselinesReducer.reducer;
