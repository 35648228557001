import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { BaseButton } from '../../../Buttons/BaseButton/BaseButton';
import { fetchLogOut } from '../../../../../API/login';
import Avatar from 'react-avatar';
import { Link, useNavigate } from 'react-router-dom';
import {
  CHANGE_PASSWORD,
  PROFILE_ROUTES,
} from '../../../../../structure/routes/routes';

import './UserMenuStyle.scss';
import { GenerateTokenModal } from './GenerateTokenModal';
import { Role } from '../../../../../structure/models/user/user';

export const UserMenu: React.FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openTokenModal, setTokenModal] = useState<boolean>(false);

  const name = `${user?.firstname} ${user?.lastname}`;

  const onClick = () => {
    dispatch(fetchLogOut());
  };

  const switchTokenModalVisible = () => setTokenModal(!openTokenModal);

  const goToPage = (url: string) => () => navigate(url);

  return (
    <div className="user_menu">
      <div className="name">
        <Link to={PROFILE_ROUTES.USER_PROFILE}>
          <Avatar
            name={name}
            round
            className="name__avatar"
            maxInitials={2}
            size="50px"
            fgColor="#FFFFFF"
          />
          {name}{' '}
          <span className="material-icons-outlined">arrow_drop_down</span>
        </Link>
      </div>
      <div className="menu">
        {![Role.Admin, Role.SuperAdmin].includes(user!.role) && (
          <div className="menu_content">
            <BaseButton onClick={switchTokenModalVisible} active={true}>
              <span className="material-icons-outlined">
                add_circle_outline
              </span>
              Generate token
            </BaseButton>
          </div>
        )}
        <div className="menu_content">
          <BaseButton onClick={goToPage(CHANGE_PASSWORD)} active={true}>
            <span className="material-icons-outlined">vpn_key</span>
            Change password
          </BaseButton>
        </div>
        <div className="menu_content">
          <BaseButton onClick={onClick} active={true}>
            <span className="material-icons-outlined">logout</span>
            Log Out
          </BaseButton>
        </div>
      </div>
      <GenerateTokenModal
        show={openTokenModal}
        onHide={switchTokenModalVisible}
      />
    </div>
  );
});
