import {
  RegistrationData,
  Suffixes,
} from '../../../structure/request/registration';
import { collectPhone } from '../../components/Form/Phone';

export const nameBySuffix = (name: string, suffix: Suffixes = '') => {
  return `${suffix}_${name}`;
};

const getStringBySuffix: (
  f: FormData,
  name: string,
  suffix?: Suffixes,
) => string = (formData, name, suffix = '') => {
  const data = formData.get(nameBySuffix(name, suffix));
  if (data instanceof File) {
    return '';
  }
  return data ?? '';
};

export const getUserDataFrom = (
  formData: FormData,
  suffix: Suffixes = '',
): any => {
  return {
    email: getStringBySuffix(formData, 'email', suffix),
    name: getStringBySuffix(formData, 'name', suffix),
    position: getStringBySuffix(formData, 'position', suffix),
    surname: getStringBySuffix(formData, 'surname', suffix),
    ...collectPhone(formData, nameBySuffix('phone', suffix), 'phone'),
  };
};

export const getRegistrationDataFromFormData = (
  formData: FormData,
): RegistrationData => {
  const companyName = formData.get('company_name')?.toString() ?? '';
  const address = formData.get('address')?.toString() ?? '';
  const addressNumber = formData.get('address_number')?.toString() ?? '';
  const city = formData.get('city')?.toString() ?? '';
  const zip = formData.get('ZIP')?.toString() ?? '';
  const country = formData.get('country')?.toString() ?? '';
  const registerRole = formData.get('register_role')?.toString() ?? '';
  const RSBPONumber = formData.get('rsb')?.toString();
  const primary = getUserDataFrom(formData, 'primary');
  const secondary = getUserDataFrom(formData, 'secondary');
  const certificateValidityStartDate = formData
    .get('start_date')
    ?.toString() as Date | undefined;
  const certificateValidityEndDate = formData.get('end_date')?.toString() as
    | Date
    | undefined;

  return {
    companyName,
    address,
    registerRole,
    addressNumber,
    zip,
    city,
    country,
    firstContactName: primary.name,
    firstContactSurname: primary.surname,
    firstContactEmail: primary.email,
    firstContactPosition: primary.position,
    firstContactCellPhone: primary.phone,
    secondContactName: secondary.name,
    secondContactSurname: secondary.surname,
    secondContactEmail: secondary.email,
    secondContactPosition: secondary.position,
    secondContactCellPhone: secondary.phone,
    RSBPONumber,
    certificateValidityStartDate,
    certificateValidityEndDate,
  };
};
