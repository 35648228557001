import React from 'react';
import classNames from 'classnames';
import { CleanSubmitButton } from '../CleanSubmitButton/CleanSubmitButton';

import './BaseButtonSubmitStyle.scss';

interface PropsDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  lock?: boolean;
  active?: boolean;
  loading?: boolean;
}

type Props = React.PropsWithChildren<PropsDiv>;

export const BaseButtonSubmit: React.FC<Props> = React.memo(
  ({ className, active, loading = false, children, ...props }) => {
    return (
      <CleanSubmitButton
        {...props}
        className={classNames(
          'base_submit_button',
          {
            active: active,
            loading,
          },
          className,
        )}>
        {children}
        {loading && (
          <span className="material-icons-outlined loading-icon">loop</span>
        )}
      </CleanSubmitButton>
    );
  },
);
