import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState = {
  status: 'idle',
  transactions: {
    results: [],
    count: 0,
  },
};

const transactionsSlice = createSlice({
  name: 'user-transactions',
  initialState,
  reducers: {
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    setUserTransactionsStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'failed'>,
    ) => {
      state.status = action.payload;
    },
  },
});

export const { setUserTransactionsStatus, setTransactions } =
  transactionsSlice.actions;

export const selectTransactions = (state: RootState) =>
  state.userTransactions.transactions;
export const selectUserTransactionsStatus = (
  state: RootState,
): 'idle' | 'loading' | 'failed' => state.userTransactions.status as any;

export default transactionsSlice.reducer;
