import { DateTime } from 'luxon';

export const DATE_FORMAT = 'dd.LL.yyyy';
export const DATE_WITH_TIME_FORMAT = `${DATE_FORMAT} 'at' HH:mm`;
export const DATE_WITH_FULL_MONTH = `dd LLLL yyyy 'at' HH:mm`;

export const formatDate = (
  date: string | Date,
  format: string = DATE_FORMAT,
) => {
  return DateTime.fromJSDate(new Date(date)).toFormat(format);
};
