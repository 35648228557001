import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { CompanyType } from '../../../structure/models/company/company';

const initialState = {
  status: 'idle',
  TSPCompanies: [] as any,
  companies: [] as any,
};

const companyExists = (companies: any[], company: any) =>
  companies.find(({ id }: any) => id === company.id);

const companiesSelectReducer = createSlice({
  initialState,
  name: 'companies-select',
  reducers: {
    setSelectCompanies: (state, action) => {
      state.companies = action.payload;

      state.companies.forEach((company: any) => {
        if (
          company.type === CompanyType.Airline &&
          !companyExists(state.TSPCompanies, company)
        ) {
          state.TSPCompanies.push(company);
        }
      });
    },
    setSelectTSPCompanies: (state, action) => {
      state.TSPCompanies = action.payload;
    },
  },
});

export const { setSelectCompanies, setSelectTSPCompanies } =
  companiesSelectReducer.actions;

//TODO fix name
export const selectSelectCompanies = (state: RootState) =>
  state.companiesSelect.companies;
export const selectSelectTSPCompanies = (state: RootState) =>
  state.companiesSelect.TSPCompanies;

export default companiesSelectReducer.reducer;
