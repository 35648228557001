import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { useFormActions } from '../components/Form/FormHook';
import { getFormChangePasswordData, validate } from './helpers/change-password';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LOGIN, STATEMENTS } from '../../structure/routes/routes';
import { Password } from '../components/Form/Password';
import { AppThunk } from '../../store/store';
import { SetPasswordData } from '../../structure/request/change-password';

import './ChangePasswordFormStyle.scss';

interface Props<T extends SetPasswordData> {
  fetchData: (data: T) => AppThunk<Promise<void>>;
  token?: string;
}

export const ChangePasswordForm = <T extends SetPasswordData>({
  fetchData,
  token,
}: Props<T>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const transformAndDispatchData = (formData: FormData) => {
    const data = getFormChangePasswordData(formData, token);

    return dispatch(fetchData(data as any)).then(() => {
      toast('Success: Password has been changed.', {
        type: 'success',
      });

      navigate(!token ? STATEMENTS : LOGIN);
    });
  };

  const { onSubmit, loading, showErrorsBlock } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  return (
    <div className="change-password-screen">
      <div className="change-password-screen__form">
        <h1>Reset your password</h1>
        <form onSubmit={onSubmit}>
          <Password
            label="New Password"
            placeholder="New Password"
            required
            name="password"
            error={showErrorsBlock['password']}
          />
          <Password
            label="Confirm New Password"
            placeholder="Confirm New Password"
            required
            name="confirmPassword"
            error={showErrorsBlock['confirmPassword']}
          />
          <BaseButtonSubmit
            className="change-password-screen__form-submit"
            active
            lock={loading}
            loading={loading}>
            Reset Password
          </BaseButtonSubmit>
        </form>
      </div>
    </div>
  );
};
