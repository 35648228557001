import { Company } from '../company/company';
import { PhoneValue } from '../../../app/components/Form/Phone';

export type Roles = 'admin';

export enum Role {
  SystemUser = 0,
  Auditor = 80,
  Admin = 90,
  SuperAdmin = 100,
}

export enum UserType {
  Primary,
  Secondary,
}

export interface User {
  id: number;
  company: Company;
  role: Role;
  SUID: string;
  firstname: string;
  lastname: string;
  position: string;
  cellPhone: PhoneValue;
  email: string;
  parent?: User | number;
  type: UserType;
  isEmailConfirmed: boolean;
  isAdminApproved: boolean;
}

export const nullSuffix = '';
export const primarySuffix = 'primary';
export const secondarySuffix = 'secondary';
export const suffixes = [nullSuffix, primarySuffix, secondarySuffix] as const;
