import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';
import { ViewElement } from '../../../../components/ViewElement/ViewElement';
import { formatDate } from '../../../../core/models/contstants/format-date';
import { CERTIFICATION_SCHEMES } from '../../../UserDashboard/BCURegistration/models/options.constant';
import { PosFile } from '../../../../components/PosFile/PosFile';

export const AdminBCUInfo: React.FC = () => {
  const details = useAppSelector(selectSubmissionsDetails);
  if (!details) return null;

  return (
    <div className="admin-submissions-bcu-info view-elements-with-rows">
      <ViewElement label="BCU ID" description={details.credit.BCUID} />
      <ViewElement
        label="Product Type"
        description={details.credit.productType}
      />
      <ViewElement label="Feedstock" description={details.credit.rawMaterial} />
      <ViewElement
        label="Date of registration"
        description={formatDate(details.credit.createdAt)}
      />
      <ViewElement
        label="Amount of product in MT"
        description={details.credit.volume}
      />
      <ViewElement
        label="Amount of product in MJ"
        description={details.credit.neatAmountInMJ}
      />
      <ViewElement
        label="Batch Number"
        description={details.credit.batchNumber}
      />
      <ViewElement
        label="Raw material type"
        description={details.credit.rawMaterial}
      />
      <ViewElement
        label="Certification scheme applicable"
        description={
          CERTIFICATION_SCHEMES.find(
            ({ value }) => value === details.credit.certificationScheme,
          )?.label
        }
      />
      <ViewElement label="Credit volume" description={details.credit.volume} />
      <ViewElement
        label="Delivery Country"
        description={details.credit.deliveryCountry}
      />
      <ViewElement
        label="Date of Acquisition"
        description={formatDate(details.credit.acquisitionDate)}
      />
      <ViewElement
        label="Date of Entry in the PO's Chain of Custody Tracking and Management Systems"
        description={formatDate(details.credit.entryInPOChainDate)}
      />
      <ViewElement
        label="Name of Supplier"
        description={details.credit.supplierName}
      />
      <ViewElement
        label="Address of Supplier"
        description={`${details.credit.supplierAddress} ${details.credit.supplierAddressNumber} ${details.credit.supplierCity}, ${details.credit.supplierCountry} ${details.credit.supplierZip}`}
      />
      <ViewElement
        label="Country of the last production/processing site"
        description={
          details.credit.lastProductionCountry ||
          details.credit.lastProductionName
        }
      />
      <ViewElement
        label="Chain of custody model employed  at the supplier’s last site"
        description={details.credit.chainCustodyModel}
      />
      <ViewElement
        label="Default or Actual LCA GHG value"
        description={details.credit.LCAGHGValue}
      />
      <ViewElement
        label="GHG Emission Reduction (%)"
        description={`${details.credit.GHGEmissionReduction}%`}
      />
      <ViewElement
        label="Claim as allowed under the RSB certification system"
        description={details.credit.additionalRSBClaim}
      />
      <ViewElement
        label="POS PDF"
        description={<PosFile id={details.credit.id} />}
      />
      {details.credit.certificationScheme === 'ICAO_CORSIA' && (
        <>
          <ViewElement
            label="CORCIA certificate"
            description="Corcia certification scheme applied"
          />
          <ViewElement
            label="Name of Neat SAF producer"
            description={details.credit.supplierName}
          />
          <ViewElement
            label="Production location of Neat SAF"
            description={details.credit.neatSAFProductionLocation}
          />
          <ViewElement
            label="Default ILUC Value"
            description={details.credit.defaultILUCValue}
          />
          <ViewElement
            label="Disaggregated Value for DLUC"
            description={details.credit.directLandUseChangeDisaggregatedValue}
          />
          <ViewElement
            label="Disaggregated Value for LEC"
            description={
              details.credit.landfillDirectionCreditDisaggregatedValue
            }
          />
          <ViewElement
            label="Disaggregated Value REC"
            description={
              details.credit.recyclingEmissionCreditDisaggregatedValue
            }
          />
        </>
      )}
    </div>
  );
};
