import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import {
  selectSubmissionsCompany,
  selectSubmissionsDetails,
} from '../../../../../store/reducers/admin/submissionsReducer';
import { Col, Row } from 'react-bootstrap';
import { UserType } from '../../../../../structure/models/user/user';
import { ViewElement } from '../../../../components/ViewElement/ViewElement';

import './AdminUsersInfoStyle.scss';

export const AdminUsersInfo: React.FC = () => {
  const company = useAppSelector(selectSubmissionsCompany);
  const details = useAppSelector(selectSubmissionsDetails);

  if (!company) return null;

  return (
    <div className="admin-users-info">
      {company.users.map((user: any) => (
        <div className="admin-users-info__profile" key={user.SUID}>
          <Row className="admin-users-info__profile__header">
            <Col>
              {user.type === UserType.Primary ? 'Primary' : 'Secondary'} Contact
              {details?.sender.SUID === user.SUID && ' (Sender)'}
            </Col>
          </Row>
          <div className="view-elements-with-rows">
            <ViewElement label="SU ID" description={user.SUID} />
            <ViewElement
              label="Name"
              description={`${user.firstname} ${user.lastname}`}
            />
            <ViewElement label="Position" description={user.position} />
            <ViewElement
              label="Cell Phone"
              description={
                typeof user?.cellPhone === 'string'
                  ? user?.cellPhone
                  : `${user?.cellPhone?.code}${user?.cellPhone?.number}`
              }
            />
            <ViewElement label="Email" description={user.email} />
          </div>
        </div>
      ))}
    </div>
  );
};
