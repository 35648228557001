import { Column } from '../../../../../components/Table/Table';

export const SPLIT_BCU_COLUMNS: Column<any>[] = [
  {
    title: 'BCU ID',
    keyItem: 'BCUID',
    render: item => item?.BCUID,
  },
  {
    title: 'Volume',
    keyItem: 'volume',
    render: item => item?.volume,
  },
  {
    title: 'Owner',
    keyItem: 'owner',
    render: item => item?.company?.name,
  },
  {
    title: 'TSP',
    keyItem: 'TSP',
    render: item => item?.TSP?.name,
  },
  // {
  //   title: 'Status',
  //   keyItem: 'status',
  //   render: item => item.TSP.name,
  // },
];
