import { validationFactory } from '../../core/helpers/validationFactory';
import { phoneValidator } from '../../components/Form/Phone';

const { createValidate, createValidator } = validationFactory();

export const validate = ({
  firstname,
  lastname,
  companyName,
  email,
  cellPhone,
  position,
}: any) =>
  createValidate({
    firstname: createValidator(
      (value: string) => !!value || !!firstname,
      'Name is required',
    ),
    lastname: createValidator(
      (value: string) => !!value || !!lastname,
      'Surname is required',
    ),
    companyName: createValidator(
      (value: string) => !!value || !!companyName,
      'Company is required',
    ),
    email: createValidator(
      (value: string) => !!value || !!email,
      'Email is required',
    ),
    ...phoneValidator(
      'phone',
      (value: string) => !!value || !!cellPhone?.code || !!cellPhone?.number,
    ),
    position: createValidator(
      (value: string) => !!value || !!position,
      'Position is required',
    ),
    message: createValidator((value: string) => !!value, 'Message is required'),
  });
