import React from 'react';

import './NoteStyle.scss';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  selectSubmissionsMessage,
  setSubmissionsMessage,
} from '../../../../../store/reducers/admin/submissionsReducer';

const limit = 2000;

export const Note: React.FC = () => {
  const dispatch = useAppDispatch();
  const message = useAppSelector(selectSubmissionsMessage);
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setSubmissionsMessage(e.target.value));
  };

  return (
    <div className="admin-submissions-note">
      <div className="admin-submissions-note__header">
        Add a note to a query response
      </div>
      <div className="admin-submissions-note__body">
        <textarea
          name="message"
          rows={10}
          maxLength={limit}
          onChange={onChange}
          value={message}
        ></textarea>
        <div className="admin-submissions-note__body__limit">
          {message.length}/{limit}
        </div>
      </div>
    </div>
  );
};
