import React, { useEffect } from 'react';
import { Table } from '../../../../components/Table/Table';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectSplitBCU } from '../../../../../store/reducers/admin/bcuListReducer';
import { fetchAdminSplitBCU } from '../../../../../API/admin/bcu-list';
import { useParams } from 'react-router-dom';
import { SPLIT_BCU_COLUMNS } from './models/split-bcu-columns.constant';

export const SplitBCU: React.FC = () => {
  const dispatch = useAppDispatch();
  const bcus = useAppSelector(selectSplitBCU);
  const { creditId } = useParams();

  useEffect(() => {
    dispatch(fetchAdminSplitBCU(creditId!));
  }, []);

  return <Table data={bcus} showHeader columns={SPLIT_BCU_COLUMNS} />;
};
