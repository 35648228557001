import {
  AllEntries,
  validationFactory,
} from '../../core/helpers/validationFactory';
import { CompanyType } from '../../../structure/models/company/company';
import { phoneValidator } from '../../components/Form/Phone';

const { createValidate, createValidator } = validationFactory();

const isAuditor: (allEntries: AllEntries) => boolean = (
  allEntries: AllEntries,
) => {
  return !!allEntries.find(
    ([key, value]) => key === 'register_role' && value === CompanyType.Auditor,
  );
};

const validators = {
  company_name: createValidator(
    (value: string) => value.length >= 3 && value.length <= 100,
    'Name length must be from 3 to 100 characters',
  ),
  register_role: createValidator(
    (value: string) => !!value,
    'Type is required',
  ),
  address: createValidator(
    (value: string) => value.length >= 3 && value.length <= 200,
    'Address length must be from 3 to 200 characters',
  ),
  address_number: createValidator(
    (value: string) => !!value,
    'Address Number is required',
  ),
  ZIP: createValidator(
    (value: string) => value.length > 4,
    'ZIP should be more than 4 characters',
  ),
  city: createValidator((value: string) => !!value, 'City is required'),
  country: createValidator((value: string) => !!value, 'Country is required'),
  rsb: createValidator((value: string, allEntries: AllEntries) => {
    const [, type] = allEntries.find(([key]) => key === 'register_role') || [];

    return type !== CompanyType.Supplier || (!!value && value.length === 10);
  }, 'PO Number length must be equal to 10 digits'),
  start_date: createValidator((value: string, allEntries: AllEntries) => {
    const [, type] = allEntries.find(([key]) => key === 'type') || [];

    return type !== CompanyType.Supplier || !!value;
  }, 'Start date is required'),
  end_date: createValidator((value: string, allEntries: AllEntries) => {
    const [, type] = allEntries.find(([key]) => key === 'type') || [];

    return type !== CompanyType.Supplier || !!value;
  }, 'End date is required'),
  primary_name: createValidator((value: string) => !!value, 'Name is required'),
  primary_surname: createValidator(
    (value: string) => !!value,
    'Surname is required',
  ),
  primary_email: createValidator(
    (value: string) => !!value,
    'Email is required',
  ),
  ...phoneValidator('primary_phone', (value: string) => !!value),
  primary_position: createValidator(
    (value: string) => !!value,
    'Position is required',
  ),
  secondary_name: createValidator(
    (value: string, allEntries: AllEntries) =>
      isAuditor(allEntries) || (!isAuditor(allEntries) && !!value),
    'Name is required',
  ),
  secondary_surname: createValidator(
    (value: string, allEntries: AllEntries) =>
      isAuditor(allEntries) || (!isAuditor(allEntries) && !!value),
    'Surname is required',
  ),
  secondary_email: createValidator((value: string, allEntries: AllEntries) => {
    const [, primaryEmail] =
      allEntries.find(
        ([key, email]) => key === 'primary_email' && email === value,
      ) || [];

    return (
      isAuditor(allEntries) ||
      (!isAuditor(allEntries) && !primaryEmail && !!value)
    );
  }, 'Email is required and it should not be the same as primary email'),
  ...phoneValidator(
    'secondary_phone',
    (value: string, allEntries: AllEntries) => isAuditor(allEntries) || !!value,
  ),
  secondary_position: createValidator(
    (value: string, allEntries: AllEntries) =>
      isAuditor(allEntries) || (!isAuditor(allEntries) && !!value),
    'Position is required',
  ),
  agreement: createValidator(
    (value: string) => !!value,
    'Agreement is required',
  ),
};

export const validate = createValidate(validators);
