import { PaginationRequestData } from './pagination';

export enum SubmissionsTypeBE {
  UserRegistration = 'User',
  UserProfileUpdate = 'UserUpdate',
  CreditTransaction = 'CreditTransaction',
}

export enum SubmissionsType {
  Registration = 'Account Registration',
  BCURegistration = 'BCU Registration',
  BCURetirement = 'BCU Retirement',
  BCUTransfer = 'BCU Transfer',
}

export enum SubmissionsStatus {
  Consideration = 'Consideration',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum SubmissionsStatusBE {
  Approved = 'Approved',
  Declined = 'Declined',
  Pending = 'Pending',
}

export interface SubmissionsRequest extends PaginationRequestData {
  type: SubmissionsType;
  status: SubmissionsStatus;
}
