import { actions } from './fetchProvider/fetch';
import { AppThunk, AppThunkDispatch } from '../store/store';
import {
  setCompanyData,
  setUserData,
} from '../store/reducers/user/userReducer';
import { User } from '../structure/models/user/user';
import { URL_COMPANY, URL_GET_USER } from './urls/urls';
import { AxiosResponse } from 'axios';
import { ServerResponse } from './types';

export const fetchUserData = (): AppThunk<Promise<void>> => dispatch => {
  return actions
    .get<AxiosResponse<ServerResponse<User>>>(URL_GET_USER)
    .then((userData: AxiosResponse<ServerResponse<User>>) => {
      dispatch(setUserData(userData.data.data));
    });
};

export const fetchCompanyData = () => (dispatch: AppThunkDispatch) => {
  return actions
    .get(URL_COMPANY)
    .then((resp: any) => dispatch(setCompanyData(resp.data.data)));
};
