import { EditableColumn } from '../../../../components/Table/Table';
import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../../core/models/contstants/format-date';
import {
  CERTIFICATION_SCHEMES,
  PRODUCT_TYPES,
  RAW_MATERIALS,
} from '../../../UserDashboard/BCURegistration/models/options.constant';
import { ErrorBlocks } from '../../../../components/Form/FormHook';
import { Select } from '../../../../components/Form/Select/Select';
import { Input } from '../../../../components/Form/Input';
import { DatePicker } from '../../../../components/Form/DatePicker';
import { Country } from '../../../../components/Address/Country';
import { Link } from 'react-router-dom';
import { ADMIN_BCU_DETAILS_ROUTES } from '../../../../../structure/routes/routes';

export const BCU_LIST_COLUMNS_LIST = (
  showErrorsBlock: ErrorBlocks,
): EditableColumn<any>[] => {
  return [
    {
      title: 'Date of registration',
      keyItem: 'registrationApproveDate',
      sort: true,
      render: item =>
        formatDate(item.registrationApproveDate, DATE_WITH_TIME_FORMAT),
    },
    {
      title: 'BCU ID',
      keyItem: 'BCUID',
      render: item => (
        <Link
          to={ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS.replace(
            ':creditId',
            item.id,
          ).replace(':companyId', item.company.id)}>
          {item.BCUID}
        </Link>
      ),
      renderEdit: item => (
        <>
          {item.BCUID}
          <input type="hidden" name="id" value={item.id} />
        </>
      ),
    },
    {
      title: 'Product Type',
      keyItem: 'productType',
      render: item => item.productType,
      renderEdit: item => (
        <Select
          options={PRODUCT_TYPES}
          defaultValue={item.productType}
          name="productType"
          required
          error={showErrorsBlock['productType']}
        />
      ),
    },
    {
      title: 'Amount of product in MT',
      keyItem: 'amountMT',
      render: item => item.volume,
      renderEdit: item => (
        <Input
          name="volume"
          defaultValue={item.volume}
          required
          type="number"
          min={0}
          error={showErrorsBlock['volume']}
        />
      ),
    },
    {
      title: 'Amount of product in MJ',
      keyItem: 'amountMJ',
      render: item => item.neatAmountInMJ,
      renderEdit: item => (
        <Input
          name="neatAmountInMJ"
          defaultValue={item.neatAmountInMJ}
          required
          type="number"
          min={0}
          error={showErrorsBlock['neatAmountInMJ']}
        />
      ),
    },
    {
      title: 'Batch Number',
      keyItem: 'batchNumber',
      render: item => item.batchNumber,
      renderEdit: item => (
        <Input
          name="batchNumber"
          defaultValue={item.batchNumber}
          required
          error={showErrorsBlock['batchNumber']}
        />
      ),
    },
    {
      title: 'Raw material type',
      keyItem: 'rawMaterial',
      render: item => item.rawMaterial,
      renderEdit: item => (
        <Select
          options={RAW_MATERIALS}
          name="rawMaterial"
          defaultValue={item.rawMaterial}
          required
          error={showErrorsBlock['rawMaterial']}
        />
      ),
    },
    {
      title: 'Delivery Country',
      keyItem: 'deliveryCountry',
      render: item => item.deliveryCountry,
      renderEdit: item => (
        <Input
          name="deliveryCountry"
          defaultValue={item.deliveryCountry}
          required
          error={showErrorsBlock['deliveryCountry']}
        />
      ),
    },
    {
      title: 'Certification scheme applicable',
      keyItem: 'certificationScheme',
      render: item =>
        CERTIFICATION_SCHEMES.find(
          ({ value }) => value === item.certificationScheme,
        )?.label,
      renderEdit: item => (
        <Select
          options={CERTIFICATION_SCHEMES}
          name="certificationScheme"
          defaultValue={item.certificationScheme}
          required
          error={showErrorsBlock['certificationScheme']}
        />
      ),
    },
    {
      title: 'Volume',
      keyItem: 'creditVolume',
      render: item => item.volume,
    },
    {
      title: 'Date of Acquisition',
      keyItem: 'acquisitionDate',
      render: item => formatDate(item.acquisitionDate),
      renderEdit: item => (
        <DatePicker
          name="acquisitionDate"
          defaultValue={item.acquisitionDate}
          required
          error={showErrorsBlock['acquisitionDate']}
        />
      ),
    },
    {
      title:
        "Date of Entry in the PO's Chain of Custody Tracking and Management Systems",
      keyItem: 'entryInPOChainDate',
      render: item => formatDate(item.entryInPOChainDate),
      renderEdit: item => (
        <DatePicker
          name="entryInPOChainDate"
          defaultValue={item.entryInPOChainDate}
          required
          error={showErrorsBlock['entryInPOChainDate']}
        />
      ),
    },
    {
      title: 'Name of Supplier',
      keyItem: 'supplierName',
      render: item => item.supplierName,
      renderEdit: item => (
        <Input
          name="supplierName"
          defaultValue={item.supplierName}
          required
          error={showErrorsBlock['supplierName']}
        />
      ),
    },
    {
      title: 'Address of Supplier',
      keyItem: 'supplierAddress',
      render: item => item.supplierAddress,
      renderEdit: item => (
        <Input
          name="supplierAddress"
          defaultValue={item.supplierAddress}
          required
          error={showErrorsBlock['supplierAddress']}
        />
      ),
    },
    {
      title: 'Address Number of Supplier',
      keyItem: 'supplierAddressNumber',
      render: item => item.supplierAddressNumber,
      renderEdit: item => (
        <Input
          name="supplierAddressNumber"
          defaultValue={item.supplierAddressNumber}
          required
          error={showErrorsBlock['supplierAddressNumber']}
        />
      ),
    },
    {
      title: 'City of Supplier',
      keyItem: 'supplierCity',
      render: item => item.supplierCity,
      renderEdit: item => (
        <Input
          name="supplierCity"
          defaultValue={item.supplierCity}
          required
          error={showErrorsBlock['supplierCity']}
        />
      ),
    },
    {
      title: 'Country of Supplier',
      keyItem: 'supplierCountry',
      render: item => item.supplierCountry,
      renderEdit: item => (
        <Country
          name="supplierCountry"
          defaultValue={item.supplierCountry}
          required
          error={showErrorsBlock['supplierCountry']}
        />
      ),
    },
    {
      title: 'ZIP of Supplier',
      keyItem: 'supplierZip',
      render: item => item.supplierZip,
      renderEdit: item => (
        <Input
          name="supplierZip"
          defaultValue={item.supplierZip}
          required
          error={showErrorsBlock['supplierZip']}
        />
      ),
    },
    {
      title: 'Country of the last production/processing site',
      keyItem: 'lastProductionCountry',
      render: item => item.lastProductionCountry,
      renderEdit: item => (
        <Input
          name="lastProductionCountry"
          defaultValue={item.lastProductionCountry}
          required
          error={showErrorsBlock['lastProductionCountry']}
        />
      ),
    },
    {
      title: 'Chain of custody model employed  at the supplier’s last site',
      keyItem: 'chainCustodyModel',
      render: item => item.chainCustodyModel,
      renderEdit: item => (
        <Input
          name="chainCustodyModel"
          defaultValue={item.chainCustodyModel}
          required
          error={showErrorsBlock['chainCustodyModel']}
        />
      ),
    },
    {
      title: 'Default or Actual LCA GHG value',
      keyItem: 'LCAGHGValue',
      render: item => item.LCAGHGValue,
      renderEdit: item => (
        <Input
          name="LCAGHGValue"
          defaultValue={item.LCAGHGValue}
          type="number"
          min={0}
          required
          error={showErrorsBlock['LCAGHGValue']}
        />
      ),
    },
    {
      title: 'GHG Emission Reduction (%)',
      keyItem: 'GHGEmissionReduction',
      render: item => item.GHGEmissionReduction,
    },
    {
      title: 'Claim as allowed under the RSB certification system',
      keyItem: 'additionalRSBClaim',
      render: item => item.additionalRSBClaim,
      renderEdit: item => (
        <Input
          name="additionalRSBClaim"
          defaultValue={item.additionalRSBClaim}
          error={showErrorsBlock['additionalRSBClaim']}
        />
      ),
    },
    {
      title: 'CORCIA certificate',
      keyItem: 'CORCIACertificate',
      render: item => 'Corcia certification scheme applied',
    },
    {
      title: 'Name of Neat SAF producer',
      keyItem: 'neatSAFProducer',
      render: item => item.supplierName,
    },
    {
      title: 'Default ILUC Value',
      keyItem: 'directLandUseChangeDisaggregatedValue',
      render: item => item.directLandUseChangeDisaggregatedValue,
      renderEdit: item => (
        <Input
          name="directLandUseChangeDisaggregatedValue"
          defaultValue={item.directLandUseChangeDisaggregatedValue}
          error={showErrorsBlock['directLandUseChangeDisaggregatedValue']}
        />
      ),
    },
    {
      title: 'Disaggregated Value for LEC',
      keyItem: 'landfillDirectionCreditDisaggregatedValue',
      render: item => item.landfillDirectionCreditDisaggregatedValue,
      renderEdit: item => (
        <Input
          name="landfillDirectionCreditDisaggregatedValue"
          defaultValue={item.landfillDirectionCreditDisaggregatedValue}
          error={showErrorsBlock['landfillDirectionCreditDisaggregatedValue']}
        />
      ),
    },
    {
      title: 'Disaggregated Value REC',
      keyItem: 'recyclingEmissionCreditDisaggregatedValue',
      render: item => item.recyclingEmissionCreditDisaggregatedValue,
      renderEdit: item => (
        <Input
          name="recyclingEmissionCreditDisaggregatedValue"
          defaultValue={item.recyclingEmissionCreditDisaggregatedValue}
          error={showErrorsBlock['recyclingEmissionCreditDisaggregatedValue']}
        />
      ),
    },
  ];
};
