import React from 'react';
import { Select } from '../Form/Select/Select';
import { Input } from '../Form/Input';
import { Col, Row } from 'react-bootstrap';
import { ErrorBlocks } from '../Form/FormHook';
import { COUNTRIES } from './models/countries.constant';
import { Country } from './Country';

type Props = {
  showErrorsBlock: ErrorBlocks;
  prefix?: string;
  disabled?: boolean;
};
const keys = ['address', 'address_number', 'ZIP', 'city', 'country'];

export const addressNamesWithPrefix = (
  prefix?: string,
): Record<string, string> =>
  keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: prefix ? `${prefix}_${key}` : key,
    }),
    {},
  );

export const Address: React.FC<Props> = React.memo(
  ({ showErrorsBlock, prefix, disabled }) => {
    const nameWithPrefix: Record<string, string> =
      addressNamesWithPrefix(prefix);

    return (
      <div className="company-address">
        <Row>
          <Col>
            <Input
              name={nameWithPrefix['address']}
              placeholder="Address"
              required
              disabled={disabled}
              error={showErrorsBlock[nameWithPrefix['address']]}
            />
          </Col>
          <Col>
            <Input
              name={nameWithPrefix['address_number']}
              placeholder="Address Number"
              required
              disabled={disabled}
              error={showErrorsBlock[nameWithPrefix['address_number']]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Input
              name={nameWithPrefix['ZIP']}
              placeholder="ZIP"
              required
              disabled={disabled}
              error={showErrorsBlock[nameWithPrefix['ZIP']]}
            />
          </Col>
          <Col md={3}>
            <Input
              name={nameWithPrefix['city']}
              placeholder="City"
              required
              disabled={disabled}
              error={showErrorsBlock[nameWithPrefix['city']]}
            />
          </Col>
          <Col md={6}>
            <Country
              name={nameWithPrefix['country']}
              required
              isDisabled={disabled}
              error={showErrorsBlock[nameWithPrefix['country']]}
            />
          </Col>
        </Row>
      </div>
    );
  },
);
