import {
  AllEntries,
  validationFactory,
} from '../../core/helpers/validationFactory';
import {
  SetPasswordData,
  ChangePasswordData,
} from '../../../structure/request/change-password';

const { createValidator, createValidate } = validationFactory();

export function getFormChangePasswordData(
  formData: FormData,
  token?: string,
): SetPasswordData | ChangePasswordData {
  return {
    password: formData.get('password')?.toString() || '',
    ...(token ? { token } : {}),
  };
}

const validators = {
  password: createValidator(
    (value: string) =>
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value),
    'Password must contain 8 characters, a special sign, number, lower case letter, and capital letter.',
  ),
  confirmPassword: createValidator((value: string, allEntries: AllEntries) => {
    const [password, confirmPassword] = allEntries;
    const [, passwordValue] = password;
    const [, confirmPasswordValue] = confirmPassword;

    return passwordValue === confirmPasswordValue;
  }, 'Password must be the same as above'),
};

export const validate = createValidate(validators);
