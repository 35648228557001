import React from 'react';
import { TabElement } from './components/TabElement/TabElement';
import { Container } from 'react-bootstrap';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';
import { pageAccesses } from '../../core/models/contstants/page-accesses';

import './NavigationTabsStyle.scss';
import { useParams } from 'react-router-dom';

export interface NavigationTabsProps {
  tabs: Array<{ path: string; title: string; childRoutes?: string[] }>;
  replace?: boolean;
}

export const NavigationTabs: React.FC<NavigationTabsProps> = React.memo(
  ({ tabs: allTabs, replace = false }) => {
    const params = useParams();
    const user = useAppSelector(selectUser);
    const tabs = allTabs.filter(tab => {
      const path = Object.entries(params).reduce(
        (acc, [key, value]) => acc.replace(value!, `:${key}`),
        tab.path,
      );

      return pageAccesses[path]?.canActivate(user);
    });

    return (
      <div className="navigation">
        <Container className="navigation_container">
          {tabs.map(tab => (
            <TabElement
              link={tab.path}
              key={tab.path}
              replace={replace}
              childRoutes={tab.childRoutes}>
              {tab.title}
            </TabElement>
          ))}
        </Container>
      </div>
    );
  },
);
