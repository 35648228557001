import { User } from '../user/user';

export const enum CompanyType {
  Aggregator = 'Aggregator',
  Airline = 'Airline',
  Corporate = 'Corporate',
  Forwarder = 'Forwarder',
  Supplier = 'Supplier',
  Auditor = 'Auditor',
}

export const COMPANY_TYPE_NAMES: Record<CompanyType, string> = {
  [CompanyType.Supplier]: 'Product Supplier',
  [CompanyType.Airline]: 'Transport service provider',
  [CompanyType.Forwarder]: 'Forwarder',
  [CompanyType.Aggregator]: 'Aggregator',
  [CompanyType.Corporate]: 'Corporate',
  [CompanyType.Auditor]: 'Auditor',
};

export interface Company {
  id: string;
  name: string;
  address: string;
  zip: string;
  city: string;
  country: string;
  addressNumber: string;
  type?: CompanyType;
  RSBPONumber?: string;
  certificateValidityStartDate?: string;
  certificateValidityEndDate?: string;
  balance?: number;
  approveDate: Date | string;
  secondaryContactInfo?: User;
}

export interface CompanyOption {
  readonly value: string;
  readonly label: string;
}
