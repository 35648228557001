import React from 'react';
import './SelectStyle.scss';
import classNames from 'classnames';
import RSelect, { GroupBase, StylesConfig } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

export interface Option<T = any> {
  value: T;
  label: string;
}

export const colourStyles: StylesConfig<Option> = {
  control: (styles, { menuIsOpen }) => ({
    ...styles,
    backgroundColor: `rgba(69, 90, 100, 0.1)`,
    borderRadius: '5px',
    border: 'none',
    borderColor: 'transparent !important',
    boxShadow: 'none',
    minHeight: '40px',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#455A64',
    paddingLeft: '6px',
  }),
  menu: styles => ({
    ...styles,
    zIndex: 99999,
  }),
};

export type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = StateManagerProps<Option, IsMulti, Group> & {
  error?: string;
  description?: string;
  required?: boolean;
};

export function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  error,
  description,
  required = false,
  placeholder: rawPlaceholder,
  value,
  defaultValue,
  options,
  ...props
}: SelectProps<Option, IsMulti, Group>) {
  const { className } = props;
  const placeholder = rawPlaceholder
    ? `${rawPlaceholder} ${required ? ' *' : ''}`
    : undefined;

  return (
    <div className="custom-select__wrapper">
      <RSelect
        // @ts-ignore
        styles={colourStyles}
        className={classNames('custom-select', className, {
          custom_select_lock: props.isDisabled,
        })}
        placeholder={placeholder}
        options={options}
        {...(defaultValue !== undefined
          ? {
            defaultValue: options?.find(
              (opt: any) => opt.value === defaultValue,
            ),
          }
          : {})}
        {...(value !== undefined
          ? { value: options?.find((opt: any) => opt.value === value) }
          : {})}
        {...props}
      />
      {error && <div className="custom-select__error">{error}</div>}
      {!error && description && <div className="custom-select__description">{description}</div>}
    </div>
  );
}
