import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../../core/models/contstants/format-date';
import { EditableColumn } from '../../../../components/Table/Table';
import { Input } from '../../../../components/Form/Input';
import { Phone } from '../../../../components/Form/Phone';
import { Col, Row } from 'react-bootstrap';
import { ErrorBlocks } from '../../../../components/Form/FormHook';

export const USERS_COLUMNS = (
  showErrorsBlocks: ErrorBlocks,
): EditableColumn<any>[] => [
  {
    title: 'SU ID',
    keyItem: 'SUID',
    sort: true,
    render: (item: any) => item.SUID,
  },
  {
    title: 'Registration Date',
    keyItem: 'registrationApproveDate',
    sort: true,
    render: (item: any) =>
      item.company?.approveDate
        ? formatDate(item.company.approveDate, DATE_WITH_TIME_FORMAT)
        : null,
  },
  {
    title: 'Contact Name',
    keyItem: 'firstname',
    sort: true,
    render: (item: any) => `${item.firstname} ${item.lastname}`,
    renderEdit: (item: any) => (
      <Row className="users__table__contact-name">
        <Col>
          <Input
            name="firstname"
            defaultValue={item.firstname}
            error={showErrorsBlocks['firstname']}
          />
        </Col>
        <Col>
          <Input
            name="lastname"
            defaultValue={item.lastname}
            error={showErrorsBlocks['lastname']}
          />
        </Col>
      </Row>
    ),
  },
  {
    title: 'Email',
    keyItem: 'email',
    sort: true,
    render: (item: any) => item.email,
    renderEdit: (item: any) => (
      <Input
        name="email"
        className="users__table__email"
        defaultValue={item.email}
        error={showErrorsBlocks['email']}
      />
    ),
  },
  {
    title: 'Phone',
    keyItem: 'cellPhone',
    sort: true,
    render: (item: any) =>
      typeof item.cellPhone === 'string'
        ? item.cellPhone
        : `${item.cellPhone.code}${item.cellPhone.number}`,
    renderEdit: (item: any) => (
      <Phone
        name="cellPhone"
        className="users__table__phone"
        value={item.cellPhone}
        error={
          showErrorsBlocks['cellPhone_number'] ||
          showErrorsBlocks['cellPhone_code']
        }
      />
    ),
  },
  {
    title: 'Position',
    keyItem: 'position',
    sort: true,
    render: (item: any) => item.position,
    renderEdit: (item: any) => (
      <Input
        name="position"
        className="users__table__position"
        defaultValue={item.position}
        error={showErrorsBlocks['position']}
      />
    ),
  },
  {
    title: 'Accound ID',
    keyItem: 'id',
    sort: true,
    render: (item: any) => item.id,
  },
  {
    title: 'Company Name',
    keyItem: 'company.name',
    sort: true,
    render: (item: any) => item.company.name,
  },
];
