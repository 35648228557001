import React from 'react';
import { PaginatedTable } from '../../../../components/Table/Table';
import { COLUMNS } from '../../Accounts/Transactions/models/columns.constant';
import {
  selectTransactions,
  selectUserTransactionsStatus,
} from '../../../../../store/reducers/user/transactionsReducer';
import { useAppSelector } from '../../../../../store/hooks';
import { selectAdminCredit } from '../../../../../store/reducers/admin/bcuListReducer';
import { fetchAdminCreditTransactions } from '../../../../../API/admin/bcu-list';

export const TransactionsHistory: React.FC = () => {
  const { company, id } = useAppSelector(selectAdminCredit);

  return (
    <PaginatedTable
      showHeader={false}
      columns={COLUMNS(company)}
      fetchData={fetchAdminCreditTransactions}
      selectStatus={selectUserTransactionsStatus}
      selectData={selectTransactions}
      filters={{ id }}
    />
  );
};
