import { AppDispatch, AppThunk, AppThunkDispatch } from '../store/store';
import { actions } from './fetchProvider/fetch';
import {
  URL_TRANSFER,
  URL_TRANSFER_COMPANIES,
  URL_TRANSFER_TSP_COMPANIES,
  URL_TRANSFER_TSP_SUBMISSIONS,
  URL_TRANSFER_TSP_SUBMISSIONS_APPROVE,
  URL_TRANSFER_TSP_SUBMISSIONS_DETAILS,
} from './urls/urls';
import { SubmissionsRequest } from '../structure/request/admin';
import {
  setStatus,
  setSubmissionsDetails,
  setSubmissionsTransactions,
} from '../store/reducers/admin/submissionsReducer';
import { AxiosResponse } from 'axios';
import { ServerResponse } from './types';
import { PaginatedData } from '../structure/request/pagination';
import { SubmissionsTransactions } from '../structure/models/admin/submissions';
import {
  setSelectCompanies,
  setSelectTSPCompanies,
} from '../store/reducers/user/companiesSelectReducer';

export const fetchTransfer =
  ({ BCUID, ...data }: any): AppThunk<Promise<void>> =>
  dispatch => {
    return actions.post(URL_TRANSFER.replace(':id', BCUID), {
      payloads: data,
    });
  };

export const fetchTransferCompanies = () => (dispatch: AppDispatch) => {
  return actions.get(URL_TRANSFER_COMPANIES).then((resp: any) => {
    dispatch(setSelectCompanies(resp.data.data));

    return resp.data.data;
  });
};

export const fetchAddTransferCompany =
  (token: string) => (dispatch: AppDispatch) => {
    return actions
      .post(URL_TRANSFER_COMPANIES, {
        payloads: { token },
      })
      .then((resp: any) => {
        dispatch(setSelectCompanies(resp.data.data));

        return resp.data.data;
      });
  };

export const fetchTransferTSPCompanies = () => (dispatch: AppDispatch) => {
  return actions.get(URL_TRANSFER_TSP_COMPANIES).then((resp: any) => {
    dispatch(setSelectTSPCompanies(resp.data.data));

    return resp.data.data;
  });
};

export const fetchAddTransferTSPCompany =
  (token: string) => (dispatch: AppDispatch) => {
    return actions
      .post(URL_TRANSFER_TSP_COMPANIES, {
        payloads: { token },
      })
      .then((resp: any) => {
        dispatch(setSelectTSPCompanies(resp.data.data));

        return resp.data.data;
      });
  };

export const fetchTransferSubmissions =
  ({
    type,
    page,
    limit,
    ...query
  }: SubmissionsRequest): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<
        AxiosResponse<ServerResponse<PaginatedData<SubmissionsTransactions>>>
      >(URL_TRANSFER_TSP_SUBMISSIONS, {
        queries: {
          skip: (page - 1) * limit,
          limit,
          ...query,
        },
      })
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsTransactions(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchTSPSubmissionsDetails =
  (id: string) => (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<any>(URL_TRANSFER_TSP_SUBMISSIONS_DETAILS.replace(':id', id))
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsDetails(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchTSPTransferApprove =
  ({ id, ...data }: any) =>
  () => {
    return actions.post(
      URL_TRANSFER_TSP_SUBMISSIONS_APPROVE.replace(':id', id),
      {
        payloads: data,
      },
    );
  };
