import React from 'react';
import './CheckBoxStyle.scss';
import classNames from 'classnames';

interface Props
  extends React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    React.AriaAttributes {
  lock?: boolean;
  error?: string;
}

export const CheckBox: React.FC<Props> = React.memo(
  ({ className, onClick, children, lock, checked, error, ...props }) => {
    return (
      <div className="custom_checkbox">
        <label className={'custom_checkbox__label'}>
          {children}
          <input
            className={classNames('custom_checkbox__checkbox', className)}
            checked={checked}
            type={'checkbox'}
            {...props}
          />
          <span className="checkbox_icon material-icons-outlined" />
        </label>
        {error && <div className="custom_checkbox__error">{error}</div>}
      </div>
    );
  },
);
