import { useSearchParamsState } from '../../../components/Table/Pagination/PaginationHook';
import { useAppDispatch } from '../../../../store/hooks';
import { Col, Container } from 'react-bootstrap';
import { Search } from '../../../components/Table/Search/Search';
import { DownloadFileButton } from '../../../components/Buttons/DownloadFileButton/DownloadFileButton';
import { EditablePaginatedTable } from '../../../components/Table/Table';
import { toast } from 'react-toastify';
import React from 'react';
import {
  downloadAdminBCUs,
  fetchAdminBCUs,
  fetchAdminEditBCU,
} from '../../../../API/admin/bcu-list';
import {
  selectBCUsList,
  selectBCUsListStatus,
} from '../../../../store/reducers/admin/bcuListReducer';
import { BCU_LIST_COLUMNS_LIST } from './models/bcu-list-columns.constant';
import { hasChanges, transformData, validate } from './models/validate';

export const BCUList: React.FC = () => {
  const [searchQuery, setSearchQuery] = useSearchParamsState<string>(
    'search',
    '',
  );
  const dispatch = useAppDispatch();

  return (
    <div className="users">
      <Container fluid>
        <div className="users__header">
          <h1>BCUs List</h1>
          <div className="controls">
            <Col className="search">
              <Search
                className="search__input"
                defaultValue={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            </Col>
            <DownloadFileButton fetchDownload={downloadAdminBCUs} />
          </div>
        </div>
        <EditablePaginatedTable
          className="users__table"
          fetchData={fetchAdminBCUs}
          getColumns={BCU_LIST_COLUMNS_LIST}
          selectData={selectBCUsList}
          selectStatus={selectBCUsListStatus}
          validate={validate}
          transformAndDispatchData={formData => {
            return dispatch(fetchAdminEditBCU(transformData(formData))).then(
              () => {
                toast.success('Success: BCU has been updated');
              },
            );
          }}
          filters={{
            search: searchQuery,
          }}
          hasChanges={hasChanges}
        />
      </Container>
    </div>
  );
};
