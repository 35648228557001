import React from 'react';
import { Modal, ModalProps } from '../../../../components/Modal/Modal';
import { BaseButtonSubmit } from '../../../../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';

import './RejectModalStyle.scss';

interface Props extends Omit<ModalProps, 'header' | 'onHide'> {
  onHide: (action: 'reject' | 'cancel') => void;
  loading: boolean;
}

const header = (
  <div className="reject-modal__header">
    <span className="material-icons-outlined">error_outline</span>
  </div>
);

export const RejectModal: React.FC<Props> = ({ onHide, loading, ...props }) => {
  const onHideModal = () => !loading && onHide('cancel');
  const onReject = () => onHide('reject');

  return (
    <Modal
      {...props}
      header={header}
      className="reject-modal"
      onHide={onHideModal}>
      Are you sure you want to reject the request?
      <div className="reject-modal__action-buttons">
        <BaseButtonSubmit onClick={onHideModal} lock={loading}>
          Cancel
        </BaseButtonSubmit>
        <BaseButtonSubmit
          active
          loading={loading}
          onClick={onReject}
          lock={loading}>
          Reject
        </BaseButtonSubmit>
      </div>
    </Modal>
  );
};
