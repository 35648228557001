import {
  AllEntries,
  validationFactory,
  Validators,
} from '../../core/helpers/validationFactory';

const { createValidator, createValidate } = validationFactory();

export const validators: Validators = {
  volume: createValidator(
    (value: number, _: AllEntries, max?: number) =>
      !!(max && value > 0 && value <= max),
    (key, value, _: AllEntries, max: number) =>
      `Volume is so match, max is ${max}`,
  ),
  company_buyer_name: createValidator(
    value => value && value.toString() !== '',
    'Buyer was not selected',
  ),
  credit: createValidator(value => !!value, 'BCU ID is required'),
};

export const TSPApproveValidate = createValidate({
  credit_claim_country: createValidator(
    value => !!value,
    'Country is required',
  ),
  SAFPurpose: createValidator(value => !!value, 'SAF Purpose is required'),
});

export const TSPTransformData = (formData: FormData) => {
  const creditClaimCountry = formData.get('credit_claim_country');
  const SAFUsePurpose = formData.get('SAFPurpose');

  return {
    creditClaimCountry,
    SAFUsePurpose,
  };
};
