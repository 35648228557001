import React from 'react';
import { AdminSubmissionsDetailsLayout } from './AdminSubmissionsDetailsLayout';
import {
  fetchSubmissionsTransactionInfo,
  fetchTransactionApprove,
  fetchTransactionReject,
} from '../../../../API/admin/submissions';
import { BCU_CREDITS_TABS } from './models/bcu-credits-tabs.constant';

export const AdminTransactionsLayout: React.FC = () => {
  return (
    <AdminSubmissionsDetailsLayout
      fetchData={fetchSubmissionsTransactionInfo}
      fetchReject={fetchTransactionReject}
      fetchApprove={fetchTransactionApprove}
      tabs={BCU_CREDITS_TABS}
    />
  );
};
