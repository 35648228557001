import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Input } from '../components/Form/Input';
import { Select } from '../components/Form/Select/Select';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { validators } from './helpers/validations';
import classNames from 'classnames';
import {
  fetchAddTransferCompany,
  fetchAddTransferTSPCompany,
  fetchTransfer,
  fetchTransferCompanies,
  fetchTransferTSPCompanies,
} from '../../API/transfer';
import { getTransferDataFromFormData } from './helpers/transfer';
import { useFormActions } from '../components/Form/FormHook';
import { validationFactory } from '../core/helpers/validationFactory';
import { CompanySelect } from './CompanySelect/CompanySelect';
import { selectCredits } from '../../store/reducers/user/creditsReducer';
import { toast } from 'react-toastify';
import { SAF_USE_PURPOSE_OPTIONS } from './helpers/options.constant';
import {
  selectSelectCompanies,
  selectSelectTSPCompanies,
} from '../../store/reducers/user/companiesSelectReducer';
import { useNavigate } from 'react-router-dom';
import { STATEMENTS } from '../../structure/routes/routes';
import { selectUser } from '../../store/reducers/user/userReducer';
import { CompanyType } from '../../structure/models/company/company';
import { ViewElement } from '../components/ViewElement/ViewElement';

import './TransferStyle.scss';

const { createValidate } = validationFactory();
export const Transfer: React.FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({
    volume: '',
    credit: null,
    buyer: null,
    tsp: null,
    country: null,
    SAFUsePurpose: null,
  });

  const isTSP = user?.company.type === CompanyType.Airline;

  const credits = useAppSelector(selectCredits);
  const creditsOptions = credits.map(({ id, BCUID }) => ({
    value: id,
    label: BCUID,
  }));
  const parentCredit = credits.find(
    credit => credit.id === formData.credit?.value,
  );

  console.log(parentCredit);

  const maxVolume = formData.credit?.value
    ? credits.find((v: any) => v.id === formData.credit.value)?.volume || 0
    : 0;

  const validate = createValidate(validators, {
    volume: [maxVolume],
  });

  const transformAndDispatchData = (formData: FormData) => {
    const transferData = getTransferDataFromFormData(formData);

    return dispatch(fetchTransfer(transferData)).then(() => {
      toast.success('Success: Your transfer request has been sent');

      navigate(STATEMENTS);
    });
  };

  const { loading, onSubmit, showErrorsBlock } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  const createOnChange = (field: string) => (item: any) => {
    const value = item?.target ? item.target.value : item;

    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const lockSubmit =
    !formData.credit?.value ||
    !formData.buyer?.value ||
    !formData.volume ||
    loading;

  return (
    <div>
      <div className="transfer_container">
        <form onSubmit={onSubmit}>
          <div>
            <h1>BCU Transfer</h1>
            <Row>
              <Col>
                <Select
                  name="credit"
                  onChange={createOnChange('credit')}
                  placeholder="BCU ID"
                  options={creditsOptions}
                />
              </Col>
              <Col>
                <Input
                  placeholder="Volume of credit transferred"
                  name="volume"
                  type="number"
                  min={0}
                  max={maxVolume}
                  onChange={createOnChange('volume')}
                  value={formData.volume}
                  required
                  error={showErrorsBlock['volume']}
                  step="0.001"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CompanySelect
                  fetchCompanies={fetchTransferCompanies}
                  fetchAddCompany={fetchAddTransferCompany}
                  selectCompanies={selectSelectCompanies}
                  onChange={createOnChange('buyer')}
                  placeholder="Company name buyer/token"
                  name="company_buyer_name"
                  required
                  error={showErrorsBlock['company_buyer_name']}
                />
              </Col>
              <Col className="transfer_container__tsp">
                {parentCredit?.TSP || isTSP ? (
                  <ViewElement
                    label="Company name transportation service provider/token"
                    description={parentCredit?.TSP?.name || user?.company.name}
                  />
                ) : (
                  formData.buyer?.value &&
                  formData.credit?.value && (
                    <CompanySelect
                      fetchCompanies={fetchTransferTSPCompanies}
                      fetchAddCompany={fetchAddTransferTSPCompany}
                      selectCompanies={selectSelectTSPCompanies}
                      placeholder="Company name transportation service provider/token"
                      name="transportation_service_provide"
                      onChange={createOnChange('tsp')}
                      error={showErrorsBlock['transportation_service_provide']}
                      description="Fill in if you want to involve Transportation service provider"
                    />
                  )
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {isTSP && (
                  <Input
                    placeholder="Country where BCU is claimed"
                    onChange={createOnChange('country')}
                    name="credit_claim_country"
                    error={showErrorsBlock['credit_claim_country']}
                  />
                )}
              </Col>
              <Col>
                {(isTSP || formData.country?.value) && (
                  <Select
                    name="saf_purpose"
                    placeholder="Use of the SAF for domestic or international purposes"
                    required
                    onChange={createOnChange('SAFUsePurpose')}
                    options={SAF_USE_PURPOSE_OPTIONS}
                  />
                )}
              </Col>
            </Row>
          </div>
          <div className="action-buttons">
            <BaseButtonSubmit
              lock={lockSubmit}
              className={classNames({
                base_color: !lockSubmit,
              })}>
              Submit
            </BaseButtonSubmit>
          </div>
        </form>
      </div>
    </div>
  );
});
