import React from 'react';
import { useSearchParamsState } from '../components/Table/Pagination/PaginationHook';
import { Container } from 'react-bootstrap';
import { PaginatedTable } from '../components/Table/Table';
import {
  selectRetirementStatementsPublicList,
  selectRetirementStatementsStatus,
} from '../../store/reducers/user/retirementStatementsReducer';
import { fetchRetirementStatements } from '../../API/retirement-statements';
import { RetirementStatement } from '../../structure/models/retirement-statements/retirement-statements';
import { RETIREMENT_STATEMENTS_COLUMNS } from './models/columns.constant';
import { Search } from '../components/Table/Search/Search';

import './StatementsStyle.scss';

export const Statements: React.FC = () => {
  const [searchQuery, setSearchQuery] = useSearchParamsState<string>(
    'search',
    '',
  );

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const rowKey = (item: RetirementStatement) => item.id;

  return (
    <div className="statements">
      <Container>
        <div className="statements__header">
          <h1>BCU Retirement Statements</h1>
          <Search onChange={onSearch} defaultValue={searchQuery} />
        </div>
        <PaginatedTable
          className="statements__table"
          fetchData={fetchRetirementStatements}
          columns={RETIREMENT_STATEMENTS_COLUMNS}
          selectData={selectRetirementStatementsPublicList}
          selectStatus={selectRetirementStatementsStatus}
          filters={{
            search: searchQuery,
          }}
          rowKey={rowKey}
        />
      </Container>
    </div>
  );
};
