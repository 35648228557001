import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Option, Select } from './Select/Select';
import { Input, InputProps } from './Input';
import { CODES, CODES_OPTIONS } from './models/codes.constant';
import { SingleValue } from 'react-select';
import classNames from 'classnames';

import './PhoneStyle.scss';
import { validationFactory } from '../../core/helpers/validationFactory';

export interface PhoneValue {
  code?: string;
  number?: string;
}

interface Props extends Omit<InputProps, 'type' | 'value' | 'onChange'> {
  value?: PhoneValue;
  onChange?: (e: PhoneValue) => void;
}

const prefixName = (prefix: string, name?: string) => {
  return name ? `${name}_${prefix}` : prefix;
};

const { createValidator } = validationFactory();

const phoneRegexp = /^(\d{4,8})$/;

export const phoneValidator = (
  name?: string,
  validExpression: any = () => true,
) => ({
  [prefixName('number', name)]: createValidator(
    (value, allEntries, ...rest) => {
      const isPhone = phoneRegexp.test(value);

      return validExpression(value, allEntries, ...rest) || isPhone;
    },
    'Phone must have correct format',
  ),
  [prefixName('code', name)]: createValidator((value, allEntries, ...rest) => {
    const isCode = CODES.includes(value);

    return validExpression(value, allEntries, ...rest) || isCode;
  }, 'Code must have correct format'),
});

export const collectPhone = (form: FormData, from: string, to: string) => ({
  [to]: {
    number: form.get(prefixName('number', from))?.toString(),
    code: form.get(prefixName('code', from))?.toString(),
  },
});

export const Phone: React.FC<Props> = React.memo(
  ({ name, className, error, value, onChange, ...props }) => {
    const [numberValue, setNumberValue] = useState<string | undefined>(
      value?.number?.toString() || '',
    );
    const [codeValue, setCodeValue] = useState<string>(value?.code || '');

    useEffect(() => {
      setNumberValue(value?.number?.toString() || '');
      setCodeValue(value?.code || '');
    }, [value]);

    const onChangeNumber = (event: ChangeEvent<HTMLInputElement>) => {
      const input = event.currentTarget.value.trim();
      const number = +input;
      if (!isNaN(number)) {
        setNumberValue(input);
        onChange?.({ ...value, number: input === '' ? undefined : input });
      }
    };

    const onChangeCode = (option: SingleValue<Option>) => {
      setCodeValue(option!.value);
      onChange?.({ ...value, code: option!.value });
    };

    return (
      <div className={classNames(className, 'custom-phone')}>
        <Row className={classNames(className, 'custom-phone')}>
          <Col md="3" sm="4" className="custom-phone__code-container">
            <Select
              placeholder="Code"
              options={CODES_OPTIONS}
              value={(codeValue ?? undefined) as any}
              name={prefixName('code', name)}
              onChange={onChangeCode}
            />
          </Col>
          <Col>
            <Input
              type="tel"
              {...props}
              name={prefixName('number', name)}
              value={numberValue}
              onChange={onChangeNumber}
            />
          </Col>
        </Row>
        {error && <div className="custom-phone__error">{error}</div>}
      </div>
    );
  },
);
