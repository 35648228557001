import React from 'react';
import { Modal as BModal, ModalProps as BModalProps } from 'react-bootstrap';
import classNames from 'classnames';

import './ModalStyle.scss';

export interface ModalProps extends BModalProps {
  header?: React.ReactNode;
  coloredHeader?: boolean;
  bodyClassName?: string;
}

export const Modal: React.FC<ModalProps> = ({
  header,
  children,
  className,
  coloredHeader = false,
  bodyClassName,
  ...props
}) => {
  return (
    <BModal {...props} className={classNames(className, 'rsb-modal')}>
      <BModal.Header
        closeButton
        closeLabel={'x'}
        className={classNames('rsb-modal__header', { colored: coloredHeader })}
      >
        {header}
      </BModal.Header>
      <BModal.Body className={classNames('rsb-modal__body', bodyClassName)}>
        {children}
      </BModal.Body>
    </BModal>
  );
};
