import React, { useEffect } from 'react';
import { PageWithTabsLayout } from '../../../layouts/PageWithTabsLayout';
import { TABS } from './models/tabs.constant';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ViewElement } from '../../../components/ViewElement/ViewElement';
import { fetchAdminBCUCredit } from '../../../../API/admin/bcu-list';
import { selectAdminCredit } from '../../../../store/reducers/admin/bcuListReducer';

import './BCUInfoStyle.scss';

export const BCUInfo: React.FC = () => {
  const { creditId } = useParams();
  const dispatch = useAppDispatch();
  const credit = useAppSelector(selectAdminCredit);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(() => {
    dispatch(fetchAdminBCUCredit(creditId!));
  }, []);

  return (
    <div className="admin-bcu-info">
      <div className="admin-bcu-info__back" onClick={goBack}>
        <span className="material-icons-outlined">arrow_back_ios</span>
        BCUs List
      </div>
      <div className="admin-bcu-info__header">
        <div className="admin-bcu-info__header__info">
          <ViewElement label="BCU ID" description={credit.BCUID}></ViewElement>
          <ViewElement label="Amount" description={credit.volume}></ViewElement>
        </div>
      </div>
      <PageWithTabsLayout tabs={TABS} />
    </div>
  );
};
