import React from 'react';
import { PaginatedTable } from '../components/Table/Table';
import {
  selectSubmissionsStatus,
  selectSubmissionsTransactions,
} from '../../store/reducers/admin/submissionsReducer';
import { TRANSACTIONS_COLUMNS } from '../dashboards/AdminDashboard/SubmissionList/models/columns.constant';
import { TRANSFER_REQUEST } from '../../structure/routes/routes';
import { useNavigate } from 'react-router-dom';
import { fetchTransferSubmissions } from '../../API/transfer';

export const TransferRequests: React.FC = () => {
  const navigate = useNavigate();
  // TODO move this class to global
  return (
    <div className="submissions-list">
      <PaginatedTable
        fetchData={fetchTransferSubmissions}
        selectData={selectSubmissionsTransactions}
        selectStatus={selectSubmissionsStatus}
        // TODO separate admin and users stores
        columns={TRANSACTIONS_COLUMNS}
        onRowClick={item => {
          navigate(TRANSFER_REQUEST.replace(':id', item.id));
        }}
      />
    </div>
  );
};
