import React from 'react';
import { Outlet } from 'react-router-dom';

export const UserDashboard: React.FC = React.memo(() => {
  return (
    <div>
      <Outlet />
    </div>
  );
});
