import React, { useEffect, useMemo, useState } from 'react';
import { UserBalance } from '../../../components/UserBalance/UserBalance';
import { Col, Row } from 'react-bootstrap';
import { Input } from '../../../components/Form/Input';
import { Option, Select } from '../../../components/Form/Select/Select';
import { DatePicker } from '../../../components/Form/DatePicker';
import { Address } from '../../../components/Address/Address';
import { FileUpload } from '../../../components/Form/FileUpload';
import { BaseButtonSubmit } from '../../../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import {
  CERTIFICATION_SCHEMES,
  CONVERSION_PROCESSES,
  CUSTODY_MODELS,
  PRODUCT_TYPES,
  RAW_MATERIALS,
} from './models/options.constant';
import { useFormActions } from '../../../components/Form/FormHook';
import { createTransform, validate } from './models/validation';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  fetchBaselines,
  fetchBCURegistration,
  fetchCredits,
} from '../../../../API/credits';
import { selectBaseLines } from '../../../../store/reducers/user/abatementBaselinesReducer';
import { selectUser } from '../../../../store/reducers/user/userReducer';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { STATEMENTS } from '../../../../structure/routes/routes';

import './BCURegistrationStyle.scss';
import { Country } from '../../../components/Address/Country';
import { fetchCompanyData } from '../../../../API/user';

export const BCURegistration: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const baseLines = useAppSelector(selectBaseLines);
  const navigate = useNavigate();
  const [certificationScheme, setCertificationScheme] = useState('');
  const [GHGValue, setGHGValue] = useState('');
  const [supplierName, setSupplierName] = useState('');

  const currentDate = new Date();
  const certificateIsExpired =
    new Date(user!.company.certificateValidityEndDate!) < currentDate;
  const showSAFForm = certificationScheme === 'ICAO_CORSIA';

  const GHGEmissionReduction = useMemo(() => {
    let result;
    const { baselineValue } =
      baseLines.find(({ applicableSchemes }) =>
        applicableSchemes.includes(certificationScheme),
      ) || {};

    if (
      certificationScheme &&
      GHGValue &&
      baselineValue &&
      !Number.isNaN(+GHGValue)
    ) {
      result = 100 - (100 * +GHGValue) / baselineValue;
    }

    return result?.toFixed(2) || '';
  }, [certificationScheme, GHGValue, baseLines]);

  const onChangeCertificationScheme = (option: SingleValue<Option>) =>
    setCertificationScheme(option!.value);
  const onChangeGHGValue = (event: React.ChangeEvent<HTMLInputElement>) =>
    setGHGValue(event.target.value);

  const transformAndDispatchData = (formData: FormData) => {
    const { name } =
      baseLines.find(({ applicableSchemes }) =>
        applicableSchemes.includes(certificationScheme),
      ) || {};

    const transform = createTransform(name, GHGEmissionReduction);

    return dispatch(fetchBCURegistration(transform(formData))).then(() => {
      toast.success(
        'Success: Your credit has been sent for administrator review. You will get email after review.',
      );

      navigate(STATEMENTS);
    });
  };

  useEffect(() => {
    if (!user) return;
    dispatch(fetchCredits());
    dispatch(fetchBaselines());
    dispatch(fetchCompanyData());
  }, []);

  const { showErrorsBlock, loading, onSubmit } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  const SAFForm = (
    <Row>
      <Row>
        <Col>
          <Input
            placeholder="Name of Neat SAF producer"
            required
            value={supplierName}
            disabled
          />
        </Col>
        <Col>
          <DatePicker
            placeholder="Production date of Neat SAF"
            required
            disabled={certificateIsExpired}
            name="production_date_of_neat_SAF"
            error={showErrorsBlock['production_date_of_neat_SAF']}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            placeholder="Production location of Neat SAF"
            name="production_location_of_neat_SAF"
            required
            disabled={certificateIsExpired}
            error={showErrorsBlock['production_location_of_neat_SAF']}
          />
        </Col>
        <Col>
          <Select
            placeholder="Conversion process"
            required
            isDisabled={certificateIsExpired}
            options={CONVERSION_PROCESSES}
            name="conversion_process"
            error={showErrorsBlock['conversion_process']}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            placeholder="Feedstock origin, with SCS Details"
            name="feedstock_origin_with_SCS_details"
            required
            disabled={certificateIsExpired}
            error={showErrorsBlock['feedstock_origin_with_SCS_details']}
          />
        </Col>
        <Col>
          <Input
            placeholder="Default ILUC Value"
            name="default_ILUC_value"
            type="number"
            required
            disabled={certificateIsExpired}
            error={showErrorsBlock['default_ILUC_value']}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            placeholder="Disaggregated Value for Direct Land Use Change"
            name="disaggregated_value_for_direct_land_use_change"
            disabled={certificateIsExpired}
            error={
              showErrorsBlock['disaggregated_value_for_direct_land_use_change']
            }
          />
        </Col>
        <Col>
          <Input
            placeholder="Disaggregated Value for Landfill Emission Credit"
            name="disaggregated_value_for_landfill_emission_credit"
            disabled={certificateIsExpired}
            error={
              showErrorsBlock[
                'disaggregated_value_for_landfill_emission_credit'
              ]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            placeholder="Disaggregated Value Recycling Emission Credit (REC)"
            name="disaggregated_value_recycling_emission_credit"
            disabled={certificateIsExpired}
            error={
              showErrorsBlock['disaggregated_value_recycling_emission_credit']
            }
          />
        </Col>
        <Col></Col>
      </Row>
    </Row>
  );

  return (
    <div className="bcu-registration">
      <UserBalance company={user!.company} />
      <div className="bcu-registration__form">
        <h2>BCU Registration</h2>
        {certificateIsExpired && (
          <div className="bcu-registration__form__expired-certificate">
            You certificate is expired.
          </div>
        )}
        <form onSubmit={onSubmit}>
          <Row>
            <Row>
              <Col>
                <Select
                  placeholder="Product type"
                  required
                  name="product_type"
                  isDisabled={certificateIsExpired}
                  options={PRODUCT_TYPES}
                  error={showErrorsBlock['product_type']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  placeholder="Neat amount in MT"
                  type="number"
                  required
                  step="0.001"
                  disabled={certificateIsExpired}
                  name="credit_volume"
                  error={showErrorsBlock['credit_volume']}
                />
              </Col>
              <Col>
                <Input
                  placeholder="Neat amount in MJ"
                  type="number"
                  required
                  step="0.001"
                  disabled={certificateIsExpired}
                  name="neat_amount_in_mj"
                  error={showErrorsBlock['neat_amount_in_mj']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  placeholder="Batch Number"
                  type="number"
                  required
                  disabled={certificateIsExpired}
                  name="batch_number"
                  error={showErrorsBlock['batch_number']}
                />
              </Col>
              <Col>
                <Select
                  placeholder="Raw material"
                  required
                  isDisabled={certificateIsExpired}
                  name="raw_material"
                  options={RAW_MATERIALS}
                  error={showErrorsBlock['raw_material']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  placeholder="Certification scheme applicable"
                  required
                  isDisabled={certificateIsExpired}
                  name="certification_scheme"
                  onChange={onChangeCertificationScheme}
                  options={CERTIFICATION_SCHEMES}
                  error={showErrorsBlock['certification_scheme']}
                />
              </Col>
              <Col>
                <Country
                  placeholder="Delivery country"
                  required
                  isDisabled={certificateIsExpired}
                  name="delivery_country"
                  error={showErrorsBlock['delivery_country']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DatePicker
                  placeholder="Date of Acquisition"
                  required
                  disabled={certificateIsExpired}
                  name="acquisition_date"
                  error={showErrorsBlock['acquisition_date']}
                />
              </Col>
              <Col>
                <DatePicker
                  placeholder="Date of Entry"
                  required
                  disabled={certificateIsExpired}
                  name="entry_date"
                  description="Entry in the PO's Chain of Custody Tracking and Management Systems"
                  error={showErrorsBlock['entry_date']}
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <Row>
              <Input
                placeholder="Name of supplier"
                required
                disabled={certificateIsExpired}
                onChange={(event: any) => setSupplierName(event.target.value)}
                name="supplier_name"
                error={showErrorsBlock['supplier_name']}
              />
              <Address
                showErrorsBlock={showErrorsBlock}
                disabled={certificateIsExpired}
                prefix="supplier"
              />
            </Row>
            <Row>
              <Input
                placeholder="Country of the last production/processing site"
                required
                disabled={certificateIsExpired}
                name="last_production_country"
                error={showErrorsBlock['last_production_country']}
              />
            </Row>
          </Row>
          <Row>
            <Row>
              <Col>
                <Select
                  placeholder="Chain of custody model employed at the supplier’s last site"
                  name="chain_of_custody_model"
                  isDisabled={certificateIsExpired}
                  options={CUSTODY_MODELS}
                  error={showErrorsBlock['chain_of_custody_model']}
                />
              </Col>
              <Col>
                <Input
                  placeholder="LCA GHG value (g CO2e/MJ)"
                  required
                  type="number"
                  disabled={certificateIsExpired}
                  name="LCA_GHG"
                  value={GHGValue}
                  onChange={onChangeGHGValue}
                  error={showErrorsBlock['LCA_GHG']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  placeholder="GHG Emission Reduction"
                  name="GHG_emission_reduction"
                  type="number"
                  disabled
                  someAfter={<span>%</span>}
                  value={GHGEmissionReduction}
                  min="0"
                  max="100"
                  error={showErrorsBlock['GHG_emission_reduction']}
                />
              </Col>
              <Col>
                <Input
                  placeholder="Additional claim as allowed under the RSB certification system"
                  name="additional_claim"
                  disabled={certificateIsExpired}
                  error={showErrorsBlock['additional_claim']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  placeholder="Declaration of incentives and tax credits"
                  name="SAF_declaration"
                  disabled={certificateIsExpired}
                  error={showErrorsBlock['SAF_declaration']}
                />
              </Col>
              <Col>
                <Input
                  placeholder="Original POS number"
                  name="POS_number"
                  disabled={certificateIsExpired}
                  error={showErrorsBlock['POS_number']}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  placeholder="Country of feedstock origin"
                  name="country_of_origin_of_feedstock"
                  required
                  disabled={certificateIsExpired}
                  error={showErrorsBlock['country_of_origin_of_feedstock']}
                />
              </Col>
              <Col>
                <Input
                  placeholder="RSB Participating Operator Code"
                  name="RSB_operator_code"
                  disabled
                  value={user?.company.RSBPONumber}
                  error={showErrorsBlock['RSB_operator_code']}
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>
                <FileUpload
                  label="POS PDF Upload"
                  accept="application/pdf"
                  disabled={certificateIsExpired}
                  name="POS_file"
                  error={showErrorsBlock['POS_file']}
                />
              </Col>
            </Row>
          </Row>
          {showSAFForm && SAFForm}
          <Row>
            <Col className="action-buttons">
              <BaseButtonSubmit
                active
                loading={loading}
                lock={loading || certificateIsExpired}>
                Submit
              </BaseButtonSubmit>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};
