import React, { useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { Collapse } from 'react-bootstrap';
import { Table } from '../Table/Table';
import classNames from 'classnames';
import { ViewElement } from '../ViewElement/ViewElement';
import { COLUMNS } from './models/columns.constant';
import { selectCredits } from '../../../store/reducers/user/creditsReducer';
import { CreditInfoModal } from './CreditInfoModal';

import './UserBalanceStyle.scss';
import { Company } from '../../../structure/models/company/company';

interface Props {
  company: Company;
  className?: string;
}

export const UserBalance: React.FC<Props> = React.memo(
  ({ company, className }) => {
    const credits = useAppSelector(selectCredits);
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [credit, setCredit] = useState({});
    const [creditModalVisible, setCreditModalVisible] = useState(false);

    const toggleModal = () => setCreditModalVisible(!creditModalVisible);
    const toggleCollapse = () => setCollapseOpen(!collapseOpen);

    const onRowClick = (item: any) => {
      setCredit(item);
      toggleModal();
    };

    return (
      <div className={classNames('user-balance', className)}>
        <div
          className={classNames('user-balance__header', {
            opened: collapseOpen,
          })}
          onClick={toggleCollapse}
          aria-controls="user-balance-credits"
          aria-expanded={collapseOpen}>
          <div className="user-balance__header__info">
            <ViewElement label="Company" description={company.name} />
            <ViewElement
              label="Total Balance"
              description={`${company.balance ?? 0} BCU`}
            />
          </div>
          <div className="user-balance__header__arrow">
            <span className="material-icons-outlined">
              keyboard_arrow_{collapseOpen ? 'up' : 'down'}
            </span>
          </div>
        </div>
        <Collapse className="user-balance__body" in={collapseOpen}>
          <div id="user-balance-credits">
            <Table
              columns={COLUMNS}
              data={credits}
              rowKey={item => item.id}
              onRowClick={onRowClick}
              showHeader
            />
          </div>
        </Collapse>
        <CreditInfoModal
          credit={credit}
          show={creditModalVisible}
          onHide={toggleModal}
        />
      </div>
    );
  },
);
