import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { selectStatus } from '../../store/reducers/user/userReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchLogin } from '../../API/login';
import './Login.scss';
import { Row } from 'react-bootstrap';
import { Input } from '../components/Form/Input';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { useFormActions } from '../components/Form/FormHook';
import { getFormLoginData, validate } from './helpers/login';
import {
  LoginOtpResponse,
  LoginTokenResponse,
} from '../../structure/request/login';
import { AxiosResponse } from 'axios';
import { fetchUserData } from '../../API/user';
import { FORGOT_PASSWORD, STATEMENTS } from '../../structure/routes/routes';
import { Password } from '../components/Form/Password';

export const Login: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [otpVisible, setOtpVisible] = useState<boolean>(false);
  const statusLogin = useAppSelector(selectStatus);
  const loading = statusLogin === 'loading';

  const transformAndDispatchData = (formData: FormData) => {
    return dispatch(fetchLogin(getFormLoginData(formData))).then(
      (resp: AxiosResponse<LoginTokenResponse | LoginOtpResponse>) => {
        if (typeof resp.data.data === 'string') {
          setOtpVisible(true);
        } else {
          return dispatch(fetchUserData()).then(() => navigate(STATEMENTS));
        }
      },
    );
  };

  const { onSubmit, showErrorsBlock } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  return (
    <div className="login_screen">
      <div className="login_screen__form">
        <form onSubmit={onSubmit}>
          <h1>Log In</h1>
          <Row>
            <Input
              placeholder="Email"
              name="email"
              type="email"
              required
              error={showErrorsBlock['email']}
            />
            <Password
              placeholder="Password"
              name="password"
              required
              error={showErrorsBlock['password']}
            />
            {otpVisible && (
              <Input
                placeholder="OTP"
                name="otp"
                type="otp"
                required
                error={showErrorsBlock['otp']}
                description="A code was sent to your email"
              />
            )}
          </Row>
          <div className="login_buttons">
            <div className="login_button">
              <BaseButtonSubmit active lock={loading} loading={loading}>
                Log In
              </BaseButtonSubmit>
            </div>
            <div className="forgot-password">
              <Link className="link" to={FORGOT_PASSWORD}>
                Forgot password?
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});
