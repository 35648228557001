import React, { useEffect, useState } from 'react';
import { PageWithTabsLayout } from '../../../layouts/PageWithTabsLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { BaseButtonSubmit } from '../../../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { RejectModal } from './RejectModal/RejectModal';
import { toast } from 'react-toastify';

import './AdminSubmissionsDetailsStyle.scss';
import {
  selectSubmissionsDetailsStatus,
  selectSubmissionsMessage,
} from '../../../../store/reducers/admin/submissionsReducer';
import { SubmissionsStatusBE } from '../../../../structure/request/admin';

interface Props {
  fetchData: (id: string) => any;
  fetchReject: (params: any) => any;
  fetchApprove: (params: any) => any;
  tabs: any;
}

export const AdminSubmissionsDetailsLayout: React.FC<Props> = ({
  fetchData,
  fetchReject,
  fetchApprove,
  tabs,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const message = useAppSelector(selectSubmissionsMessage);
  const status = useAppSelector(selectSubmissionsDetailsStatus);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const goBack = () => navigate(-1);

  const onReject = () => setShowRejectModal(true);
  const onApprove = () => {
    setLoading(true);
    dispatch(fetchApprove({ id, message }))
      .then(() => {
        toast.success('Success: Registration approved');
        goBack();
      })
      .catch((e: any) => {
        toast.error(`Error: ${e.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onHide = (status: 'reject' | 'cancel') => {
    if (status === 'reject') {
      setRejectLoading(true);
      dispatch(fetchReject({ id, message }))
        .then(() => {
          toast.success('Success: Registration rejected');
          goBack();
        })
        .catch((e: any) => {
          toast.error(`Error: ${e.response.data.message}`);
        })
        .finally(() => {
          setRejectLoading(false);
        });
      return;
    }
    setShowRejectModal(false);
  };

  useEffect(() => {
    dispatch(fetchData(id!));
  }, []);

  const back = (
    <div onClick={goBack} className="submissions-details__back">
      <span className="material-icons-outlined">arrow_back_ios</span>
      Registration Request
    </div>
  );

  const actionButtons = (
    <div className="submissions-details__action-buttons">
      <BaseButtonSubmit onClick={onReject} lock={rejectLoading || loading}>
        Reject
      </BaseButtonSubmit>
      <BaseButtonSubmit
        active
        loading={loading}
        onClick={onApprove}
        lock={loading || rejectLoading}>
        Approve
      </BaseButtonSubmit>
    </div>
  );

  return (
    <div className="submissions-details">
      <PageWithTabsLayout
        aboveWrapper={back}
        underWrapper={
          <>{status === SubmissionsStatusBE.Pending && actionButtons}</>
        }
        tabs={tabs.map((tab: any) => ({
          ...tab,
          path: tab.path.replace(':id', id as string),
        }))}
      />
      <RejectModal
        centered
        show={showRejectModal}
        onHide={onHide}
        loading={rejectLoading}
      />
    </div>
  );
};
