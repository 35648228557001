import React from 'react';
import { Search } from '../components/Table/Search/Search';
import { PaginatedTable } from '../components/Table/Table';
import { downloadAdminLogs, fetchAdminLogs } from '../../API/admin/logs';
import {
  selectAdminLogs,
  selectAdminLogsStatus,
} from '../../store/reducers/admin/logsReducer';
import { COLUMNS } from './models/columns.constant';
import { useSearchParamsState } from '../components/Table/Pagination/PaginationHook';
import { DownloadFileButton } from '../components/Buttons/DownloadFileButton/DownloadFileButton';

import './LogsStyle.scss';

export const Logs: React.FC = React.memo(() => {
  const [search, setSearch] = useSearchParamsState('search', '');
  const onSearch = (event: any) => setSearch(event.target.value);

  return (
    <div className="admin-logs">
      <div className="admin-logs__header">
        <h1>Logs list</h1>
        <div className="admin-logs__header__actions">
          <Search onChange={onSearch} defaultValue={search} />
          <DownloadFileButton fetchDownload={downloadAdminLogs} />
        </div>
      </div>
      <div className="admin-logs__body">
        <PaginatedTable
          columns={COLUMNS}
          fetchData={fetchAdminLogs}
          selectStatus={selectAdminLogsStatus}
          selectData={selectAdminLogs}
          filters={{ search }}
        />
      </div>
    </div>
  );
});
