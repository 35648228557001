import { actions } from './fetchProvider/fetch';
import { URL_CONTACT_US, URL_CONTACT_US_AUTH } from './urls/urls';

export const fetchContactUsAuth = (data: any) => () => {
  return actions.post(URL_CONTACT_US_AUTH, {
    payloads: data,
  });
};

export const fetchContactUs = (data: any) => () => {
  return actions.post(URL_CONTACT_US, {
    payloads: data,
  });
};
