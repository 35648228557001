import React from 'react';
import classNames from 'classnames';

import './InputStyle.scss';

export interface InputProps
  extends React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    React.AriaAttributes {
  lock?: boolean;
  label?: string;
  someBefore?: JSX.Element;
  someAfter?: JSX.Element;
  error?: string;
  description?: string;
}

export const Input: React.FC<InputProps> = React.memo(
  ({
    className,
    onChange,
    children,
    label,
    placeholder,
    required,
    lock,
    someBefore,
    someAfter,
    description,
    error,
    ...props
  }) => {
    const customOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!lock && onChange) {
        onChange(e);
      }
    };

    if (placeholder && required) {
      placeholder = `${placeholder} *`;
    }

    const input = (
      <div className="custom-input">
        <div className="custom-input__wrapper">
          {someBefore}
          <input
            disabled={lock}
            className={classNames(
              {
                clear_input_lock: lock,
              },
              className,
            )}
            placeholder={placeholder}
            {...props}
            onChange={customOnChange}
          />
          {someAfter}
        </div>
        {description && (
          <div className="custom-input__description">{description}</div>
        )}
        {error && <div className="custom-input__error">{error}</div>}
      </div>
    );

    if (label) {
      return (
        <label>
          <span>{label}</span>
          {input}
        </label>
      );
    }

    return input;
  },
);
