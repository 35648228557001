import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState = {
  status: 'idle',
  logs: {
    results: [],
    count: 0,
  },
};

const logsSlice = createSlice({
  name: 'admin-logs',
  initialState,
  reducers: {
    setAdminLogs: (state, action) => {
      state.logs = action.payload;
    },
    setAdminLogsStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setAdminLogs, setAdminLogsStatus } = logsSlice.actions;

export const selectAdminLogs = (state: RootState) => state.adminLogs.logs;
export const selectAdminLogsStatus = (state: RootState): any =>
  state.adminLogs.status;

export default logsSlice.reducer;
