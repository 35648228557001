import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  ADMIN_ACCOUNTS_AND_BALANCE_ROUTES,
  ADMIN_BCU_DETAILS_ROUTES,
  ADMIN_BCUS,
  ADMIN_LOGS,
  ADMIN_REFERENCE_VALUES,
  ADMIN_REFERENCE_VALUES_ROUTES,
  ADMIN_SUBMISSIONS_LIST,
  ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
  ADMIN_USERS,
  ANY,
  CHANGE_PASSWORD,
  COMPANY_PROFILE,
  CONTACT_US,
  DASHBOARD,
  DASHBOARD_ROUTES,
  FORGOT_PASSWORD,
  LOGIN,
  PROFILE_ROUTES,
  REGISTER_CREDIT,
  REGISTRATION,
  RESET_PASSWORD,
  RETIREMENT,
  SET_PASSWORD,
  STATEMENTS,
  TRANSFER,
  TRANSFER_REQUEST,
  TRANSFER_ROUTES,
  USER_PROFILE,
  VERIFY_EMAIL,
} from './structure/routes/routes';
import { Dashboards } from './app/dashboards/Dashboards';
import { Transactions } from './app/transactions/Transactions';
import { Logs } from './app/logs/Logs';
import { Transfer } from './app/transfer/Transfer';
import { Retirement } from './app/retirement/Retirement';
import { Statements } from './app/home/Statements';
import { Registration } from './app/registration/Registration';
import { ForgotPassword } from './app/login/ForgotPassword';
import { Login } from './app/login/Login';
import { guard } from './app/core/HOC/guard';
import { pageAccesses } from './app/core/models/contstants/page-accesses';
import { MainLayout } from './app/layouts/MainLayout';
import { Profiles } from './app/profiles/Profiles';
import { BCURegistration } from './app/dashboards/UserDashboard/BCURegistration/BCURegistration';
import { SubmissionList } from './app/dashboards/AdminDashboard/SubmissionList/SubmissionList';
import { EmailVerification } from './app/email-verification/EmailVerification';
import { AdminCompanyInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminCompanyInfo';
import { AdminUsersInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminUsersInfo';
import { UserInfo } from './app/profiles/UserInfo/UserInfo';
import { CompanyInfo } from './app/profiles/CompanyInfo/CompanyInfo';
import { SetPassword } from './app/login/SetPassword';
import { ResetPassword } from './app/login/ResetPassword';
import { ChangePassword } from './app/login/ChangePassword';
import { Note } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/Note';
import { AdminRegisterLayout } from './app/dashboards/AdminDashboard/SubmissionList/AdminRegisterLayout';
import { AdminTransactionsLayout } from './app/dashboards/AdminDashboard/SubmissionList/AdminTransactionsLayout';
import { AdminBCUInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminBCUInfo';
import { AdminTransfersLayout } from './app/dashboards/AdminDashboard/SubmissionList/AdminTransfersLayout';
import { AdminTransferInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminTransferInfo';
import { TransferLayout } from './app/transfer/TransferLayout';
import { TransferRequests } from './app/transfer/TransferRequests';
import { ContactUs } from './app/contact-us/ContactUs';
import { TransferRequest } from './app/transfer/TransferRequest';
import { AdminRetirementsLayout } from './app/dashboards/AdminDashboard/SubmissionList/AdminRetirementsLayout';
import { AdminRetirementInfo } from './app/dashboards/AdminDashboard/SubmissionList/Tabs/AdminRetirementInfo';
import { Users } from './app/dashboards/AdminDashboard/Users/Users';
import { Accounts } from './app/dashboards/AdminDashboard/Accounts/Accounts';
import { AdminTransactions } from './app/dashboards/AdminDashboard/Accounts/Transactions/AdminTransactions';
import { BCUList } from './app/dashboards/AdminDashboard/BCUList/BCUList';
import { BCUInfo } from './app/dashboards/AdminDashboard/BCUList/BCUInfo';
import { SplitBCU } from './app/dashboards/AdminDashboard/BCUList/Tabs/SplitBCU';
import { TransactionsHistory } from './app/dashboards/AdminDashboard/BCUList/Tabs/TransactionsHistory';
import { ReferenceValues } from './app/dashboards/AdminDashboard/ReferenceValues/ReferenceValues';
import { CertificationSchemes } from './app/dashboards/AdminDashboard/ReferenceValues/Tabs/CertificationSchemes';

const GuardedDashboards = guard(Dashboards);
const GuardedTransactions = guard(Transactions);
const GuardedBCURegistration = guard(BCURegistration);
const GuardedTransferLayout = guard(TransferLayout);
const GuardedTransferRequest = guard(TransferRequest);
const GuardedRetirement = guard(Retirement);
const GuardedSetPassword = guard(SetPassword);
const GuardedResetPassword = guard(ResetPassword);
const GuardedChangePassword = guard(ChangePassword);
const GuardedRegistration = guard(Registration);
const GuardedForgotPassword = guard(ForgotPassword);
const GuardedLogin = guard(Login);
const GuardedContactUs = guard(ContactUs);
const GuardedProfiles = guard(Profiles);
const GuardedCompanyInfo = guard(CompanyInfo);

const GuardedAdminBCUs = guard(BCUList);
const GuardedAdminBCUInfo = guard(BCUInfo);
const GuardedAdminLogs = guard(Logs);
const GuardedAdminAccountsAndBalance = guard(Accounts);
const GuardedAdminTransactions = guard(AdminTransactions);
const GuardedAdminUsers = guard(Users);
const GuardedAdminSubmissionsList = guard(SubmissionList);
const GuardedAdminReferenceValues = guard(ReferenceValues);
const GuardedAdminCompanyInfoLayout = guard(AdminRegisterLayout);
const GuardedAdminBCUInfoLayout = guard(AdminTransactionsLayout);
const GuardedAdminBCUTransferLayout = guard(AdminTransfersLayout);
const GuardedAdminBCURetirementLayout = guard(AdminRetirementsLayout);
const GuardedAdminCompanyInfo = guard(AdminCompanyInfo);
const GuardedAdminUsersInfo = guard(AdminUsersInfo);

const {
  [DASHBOARD]: dashboardAccess,
  [DASHBOARD_ROUTES.TRANSFER]: transferAccess,
  [DASHBOARD_ROUTES.RETIREMENT]: retirementAccess,
  [DASHBOARD_ROUTES.REGISTER_CREDIT]: registerCreditAccess,
  [TRANSFER_REQUEST]: transferRequestAccess,
  [SET_PASSWORD]: setPasswordAccess,
  [CHANGE_PASSWORD]: changePasswordAccess,
  [RESET_PASSWORD]: resetPasswordAccess,
  [REGISTRATION]: registrationAccess,
  [FORGOT_PASSWORD]: forgotPasswordAccess,
  [LOGIN]: loginAccess,
  [CONTACT_US]: contactUsAccess,
  [PROFILE_ROUTES.USER_PROFILE]: userInfoAccess,
  [PROFILE_ROUTES.COMPANY_PROFILE]: companyInfoAccess,
  [ADMIN_BCUS]: adminBCUsAccess,
  [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS]: adminBCUDetails,
  [ADMIN_LOGS]: adminLogsAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.MAIN]: adminAccountsAndBalanceAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS]: adminTransactionsAccess,
  [ADMIN_USERS]: adminUsersAccess,
  [ADMIN_SUBMISSIONS_LIST]: adminSubmissionListAccess,
  [ADMIN_REFERENCE_VALUES]: adminReferenceValuesAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO]:
    adminSubmissionsCompanyInfoAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USERS_INFO]:
    adminSubmissionsUsersInfoAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO]:
    adminSubmissionsBCUInfoAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.COMPANY_INFO]:
    adminSubmissionsBCUTransferAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.COMPANY_INFO]:
    adminSubmissionsBCURetirementAccess,
} = pageAccesses;

export const AppRoutes: FC = React.memo(() => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path={DASHBOARD}
          element={<GuardedDashboards {...dashboardAccess} />}>
          <Route index element={<GuardedTransactions {...dashboardAccess} />} />
          <Route
            path={REGISTER_CREDIT}
            element={<GuardedBCURegistration {...registerCreditAccess} />}
          />
          <Route
            path={TRANSFER}
            element={<GuardedTransferLayout {...transferAccess} />}>
            <Route index element={<Transfer />} />
            <Route
              path={TRANSFER_ROUTES.REQUESTS}
              element={<TransferRequests />}
            />
          </Route>
          <Route
            path={TRANSFER_REQUEST}
            element={<GuardedTransferRequest {...transferRequestAccess} />}
          />
          <Route
            path={RETIREMENT}
            element={<GuardedRetirement {...retirementAccess} />}
          />
        </Route>
        <Route
          path={USER_PROFILE}
          element={<GuardedProfiles {...userInfoAccess} />}>
          <Route index element={<UserInfo />} />
          <Route
            path={COMPANY_PROFILE}
            element={<GuardedCompanyInfo {...companyInfoAccess} />}
          />
        </Route>
        <Route
          path={SET_PASSWORD}
          element={<GuardedSetPassword {...setPasswordAccess} />}
        />
        <Route
          path={CHANGE_PASSWORD}
          element={<GuardedChangePassword {...changePasswordAccess} />}
        />
        <Route
          path={RESET_PASSWORD}
          element={<GuardedResetPassword {...resetPasswordAccess} />}
        />
        <Route
          path={CONTACT_US}
          element={<GuardedContactUs {...contactUsAccess} />}></Route>
        <Route
          path={REGISTRATION}
          element={<GuardedRegistration {...registrationAccess} />}
        />
        <Route
          path={FORGOT_PASSWORD}
          element={<GuardedForgotPassword {...forgotPasswordAccess} />}
        />
        <Route path={LOGIN} element={<GuardedLogin {...loginAccess} />} />
        <Route
          path={ADMIN_BCUS}
          element={<GuardedAdminBCUs {...adminBCUsAccess} />}
        />
        <Route
          path={ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS}
          element={<GuardedAdminBCUInfo {...adminBCUDetails} />}>
          <Route index element={<SplitBCU />} />
          <Route
            path={ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_HISTORY}
            element={<TransactionsHistory />}
          />
        </Route>
        <Route
          path={ADMIN_LOGS}
          element={<GuardedAdminLogs {...adminLogsAccess} />}
        />
        <Route
          path={ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.MAIN}
          element={
            <GuardedAdminAccountsAndBalance
              {...adminAccountsAndBalanceAccess}
            />
          }
        />
        <Route
          path={ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS}
          element={<GuardedAdminTransactions {...adminTransactionsAccess} />}
        />
        <Route
          path={ADMIN_USERS}
          element={<GuardedAdminUsers {...adminUsersAccess} />}
        />
        <Route
          path={ADMIN_SUBMISSIONS_LIST}
          element={
            <GuardedAdminSubmissionsList {...adminSubmissionListAccess} />
          }
        />
        <Route
          path={ADMIN_REFERENCE_VALUES}
          element={
            <GuardedAdminReferenceValues {...adminReferenceValuesAccess} />
          }>
          <Route index element={<CertificationSchemes />} />
          <Route path={ADMIN_REFERENCE_VALUES_ROUTES.TAB1} element={<></>} />
          <Route path={ADMIN_REFERENCE_VALUES_ROUTES.TAB2} element={<></>} />
        </Route>
        <Route
          path={ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO}
          element={
            <GuardedAdminCompanyInfoLayout
              {...adminSubmissionsCompanyInfoAccess}
            />
          }>
          <Route
            index
            element={
              <GuardedAdminCompanyInfo {...adminSubmissionsCompanyInfoAccess} />
            }
          />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USERS_INFO}
            element={
              <GuardedAdminUsersInfo {...adminSubmissionsUsersInfoAccess} />
            }
          />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.NOTE}
            element={<Note />}
          />
        </Route>
        <Route
          path={ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO}
          element={
            <GuardedAdminBCUInfoLayout {...adminSubmissionsBCUInfoAccess} />
          }>
          <Route
            index
            element={
              <GuardedAdminCompanyInfo {...adminSubmissionsCompanyInfoAccess} />
            }
          />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.USERS_INFO}
            element={
              <GuardedAdminUsersInfo {...adminSubmissionsUsersInfoAccess} />
            }
          />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.NOTE}
            element={<Note />}
          />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_INFO}
            element={<AdminBCUInfo />}
          />
        </Route>
        <Route
          path={ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.COMPANY_INFO}
          element={
            <GuardedAdminBCUTransferLayout
              {...adminSubmissionsBCUTransferAccess}
            />
          }>
          <Route index element={<AdminCompanyInfo />} />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.USERS_INFO}
            element={<AdminUsersInfo />}
          />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.BCU_INFO}
            element={<AdminBCUInfo />}
          />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.TRANSFER_INFO}
            element={<AdminTransferInfo />}
          />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.NOTE}
            element={<Note />}
          />
        </Route>
        <Route
          path={ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.COMPANY_INFO}
          element={
            <GuardedAdminBCURetirementLayout
              {...adminSubmissionsBCURetirementAccess}
            />
          }>
          <Route index element={<AdminCompanyInfo />} />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.USERS_INFO}
            element={<AdminUsersInfo />}
          />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.BCU_INFO}
            element={<AdminBCUInfo />}
          />
          <Route
            path={
              ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.RETIREMENT_INFO
            }
            element={<AdminRetirementInfo />}
          />
          <Route
            path={ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.NOTE}
            element={<Note />}
          />
        </Route>
        <Route path={VERIFY_EMAIL} element={<EmailVerification />} />
        <Route path={STATEMENTS} element={<Statements />} />
        <Route path={ANY} element={<Navigate to={STATEMENTS} />} />
      </Route>
    </Routes>
  );
});
