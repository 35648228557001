import classNames from 'classnames';
import React from 'react';
import {
  DATE_WITH_FULL_MONTH,
  formatDate,
} from '../../../../../core/models/contstants/format-date';
import { UserType } from '../../../../../../structure/models/user/user';
import { TransactionType } from '../../../../../../API/admin/submissions';

export const COLUMNS = (id: string) => [
  {
    title: 'Register Date',
    sort: true,
    keyItem: 'approveDate',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__details">{item?.type}</div>
        <div className="admin-transactions__info__header">
          {formatDate(item?.approveDate, DATE_WITH_FULL_MONTH)}
        </div>
      </div>
    ),
  },
  {
    title: 'From/By',
    keyItem: 'fromBy',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__header">From/By</div>
        <div className="admin-transactions__info__details">
          {item?.company?.name}
        </div>
      </div>
    ),
  },
  {
    title: 'To',
    keyItem: 'to',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__header">To</div>
        <div className="admin-transactions__info__details">
          {item?.receiver?.name}
        </div>
      </div>
    ),
  },
  {
    title: 'User',
    keyItem: 'user',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__header">
          User
          <span className="transactions__user__type user-type">
            {item.sender?.type === UserType.Primary ? 'Primary' : 'Secondary'}
          </span>
        </div>
        <div className="admin-transactions__info__details">
          {item?.sender?.firstname} {item?.sender?.lastname}
        </div>
      </div>
    ),
  },
  {
    title: 'BCU ID',
    keyItem: 'BCUID',
    render: (item: any) => (
      <div className="admin-transactions__info">
        <div className="admin-transactions__info__header">BCU ID</div>
        <div className="admin-transactions__info__details">
          {item?.newCredit?.BCUID || item?.credit?.BCUID}
        </div>
      </div>
    ),
  },
  {
    title: 'amount',
    keyItem: 'amount',
    render: (item: any) => {
      const plus =
        item.type === TransactionType.Register || id === item.receiver?.id;

      return (
        <div className="admin-transactions__info">
          <div
            className={classNames('admin-transactions__info__volume', {
              plus,
              minus: !plus,
            })}>
            {plus ? '+' : '-'}
            {item?.amount} BCUs
          </div>
        </div>
      );
    },
  },
];
