import React, { useEffect } from 'react';
import { Table } from '../../../../components/Table/Table';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectBaseLines } from '../../../../../store/reducers/user/abatementBaselinesReducer';
import { fetchBaselines } from '../../../../../API/credits';
import { COLUMNS } from './models/columns.constant';

export const CertificationSchemes: React.FC = () => {
  const dispatch = useAppDispatch();
  const baseLines = useAppSelector(selectBaseLines);

  useEffect(() => {
    dispatch(fetchBaselines());
  }, []);

  return <Table showHeader columns={COLUMNS} data={baseLines} />;
};
