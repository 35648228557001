import {
  ADMIN_ACCOUNTS_AND_BALANCE_ROUTES,
  ADMIN_BCU_DETAILS_ROUTES,
  ADMIN_BCUS,
  ADMIN_LOGS,
  ADMIN_REFERENCE_VALUES,
  ADMIN_REFERENCE_VALUES_ROUTES,
  ADMIN_SUBMISSIONS_LIST,
  ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
  ADMIN_USERS,
  CHANGE_PASSWORD,
  CONTACT_US,
  DASHBOARD,
  DASHBOARD_ROUTES,
  FORGOT_PASSWORD,
  LOGIN,
  PROFILE_ROUTES,
  REGISTRATION,
  RESET_PASSWORD,
  SET_PASSWORD,
  STATEMENTS,
  TRANSFER_REQUEST,
  TRANSFER_ROUTES,
} from '../../../../structure/routes/routes';
import { Role, User } from '../../../../structure/models/user/user';
import { GuardProps } from '../../HOC/guard';
import { CompanyType } from '../../../../structure/models/company/company';

const createRolesAccess = (
  roles: Array<Role | undefined>,
  redirect: string = STATEMENTS,
): GuardProps => ({
  canActivate: (user?: User) => roles.includes(user?.role),
  redirect,
});

const userRolesAccess = createRolesAccess([Role.SystemUser, Role.Auditor]);

const supplierTypeAccess: GuardProps = {
  canActivate: (user?: User) => user?.company?.type === CompanyType.Supplier,
  redirect: STATEMENTS,
};

const adminRolesAccess = createRolesAccess([Role.Admin, Role.SuperAdmin]);
const authorizedRolesAccess = createRolesAccess([
  Role.Admin,
  Role.SuperAdmin,
  Role.SystemUser,
  Role.Auditor,
]);

const allRolesAccess = createRolesAccess([
  undefined,
  Role.Admin,
  Role.SuperAdmin,
  Role.SystemUser,
  Role.Auditor,
]);

const allRolesExceptAdmin = createRolesAccess([
  Role.SystemUser,
  Role.Auditor,
  undefined,
]);

const unauthorizedAccess = createRolesAccess([undefined]);

export const pageAccesses: Record<string, GuardProps> = {
  [DASHBOARD]: userRolesAccess,
  [DASHBOARD_ROUTES.TRANSFER]: userRolesAccess,
  [TRANSFER_ROUTES.REQUESTS]: userRolesAccess,
  [TRANSFER_REQUEST]: userRolesAccess,
  [DASHBOARD_ROUTES.REGISTER_CREDIT]: supplierTypeAccess,
  [DASHBOARD_ROUTES.RETIREMENT]: userRolesAccess,
  [PROFILE_ROUTES.USER_PROFILE]: userRolesAccess,
  [PROFILE_ROUTES.COMPANY_PROFILE]: userRolesAccess,
  [ADMIN_BCUS]: adminRolesAccess,
  [ADMIN_LOGS]: adminRolesAccess,
  [ADMIN_USERS]: adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.MAIN]: adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS]: adminRolesAccess,
  [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS]: adminRolesAccess,
  [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_HISTORY]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USERS_INFO]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.NOTE]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO]:
    adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_INFO]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.USERS_INFO]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.NOTE]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.COMPANY_INFO]:
    adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.TRANSFER_INFO]:
    adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.BCU_INFO]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.USERS_INFO]:
    adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.NOTE]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.COMPANY_INFO]:
    adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.RETIREMENT_INFO]:
    adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.BCU_INFO]:
    adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.USERS_INFO]:
    adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.NOTE]: adminRolesAccess,
  [ADMIN_REFERENCE_VALUES]: adminRolesAccess,
  [ADMIN_REFERENCE_VALUES_ROUTES.TAB1]: adminRolesAccess,
  [ADMIN_REFERENCE_VALUES_ROUTES.TAB2]: adminRolesAccess,
  [CHANGE_PASSWORD]: authorizedRolesAccess,
  [RESET_PASSWORD]: unauthorizedAccess,
  [SET_PASSWORD]: unauthorizedAccess,
  [REGISTRATION]: unauthorizedAccess,
  [FORGOT_PASSWORD]: unauthorizedAccess,
  [LOGIN]: unauthorizedAccess,
  [STATEMENTS]: allRolesAccess,
  [CONTACT_US]: allRolesExceptAdmin,
};
