export const hasChanges = (formData: FormData, item: any) => {
  return (
    item.firstname !== formData.get('firstname') ||
    item.lastname !== formData.get('lastname') ||
    item.email !== formData.get('email') ||
    item.cellPhone.code !== formData.get('cellPhone_code') ||
    item.cellPhone.number !== formData.get('cellPhone_number') ||
    item.position !== formData.get('position')
  );
};
