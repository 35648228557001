import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { CompanyType } from '../../structure/models/company/company';
import { PageWithTabsLayout } from '../layouts/PageWithTabsLayout';
import { Transfer } from './Transfer';
import { TABS } from './helpers/tabs.constant';
import { UserBalance } from '../components/UserBalance/UserBalance';
import { fetchCredits } from '../../API/credits';
import { fetchCompanyData } from '../../API/user';
import classNames from 'classnames';

import './TransferLayoutStyle.scss';

export const TransferLayout: React.FC = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const isTSP = user?.company.type === CompanyType.Airline;

  useEffect(() => {
    dispatch(fetchCredits());
    dispatch(fetchCompanyData());
  }, []);

  return (
    <div className="transfer-layout">
      <UserBalance
        company={user!.company}
        className={classNames({ 'transfer-layout__tsp': isTSP })}
      />
      {isTSP ? <PageWithTabsLayout tabs={TABS} /> : <Transfer />}
    </div>
  );
};
