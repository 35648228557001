import React, { useRef, useState } from 'react';

import './FileUploadStyle.scss';
import classNames from 'classnames';

export interface FileUploadProps {
  name?: string;
  label: string;
  error?: string;
  required?: boolean;
  accept?: string;
  disabled?: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  accept,
  required,
  label,
  name,
  error,
  disabled,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const [file] = event.target.files ?? [];
    setFileName(file?.name ?? '');
  };

  const onLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    if (fileName || disabled) {
      event.preventDefault();
    }
  };
  const onDeleteFile = () => {
    ref.current!.value = '';
    setFileName('');
  };
  const labelText = fileName ? fileName : `${label} ${required ? '*' : ''}`;

  return (
    <div className="file-upload">
      <label className="file-upload__label" onClick={onLabelClick}>
        <div className="file-upload__label__file-info">
          <span className="material-icons-outlined file-upload__label__file-info__info-icon">
            {fileName ? 'picture_as_pdf' : 'add'}
          </span>
          <span
            className={classNames('file-upload__label__file-info__text', {
              uploaded: !!fileName,
            })}>
            {labelText}
          </span>
        </div>
        {fileName && (
          <span
            className="material-icons-outlined file-upload__label__delete-icon"
            onClick={onDeleteFile}>
            delete
          </span>
        )}
        <input
          type="file"
          className="file-upload__label__input"
          accept={accept}
          name={name}
          onChange={onChange}
          onClick={e => e.stopPropagation()}
          ref={ref}
        />
      </label>
      {error && <div className="file-upload__error">{error}</div>}
    </div>
  );
};
