import { formatDate } from '../../core/models/contstants/format-date';
import { UserType } from '../../../structure/models/user/user';
import React from 'react';
import { Column } from '../../components/Table/Table';

export enum ActionLogTypeFE {
  CompanyRegistration = 'Company Registration',
  SystemUserRegistration = 'System User Registration',
  PasswordSetup = 'Password Setup',
  PasswordRestore = 'Password Restore',
  PasswordChange = 'Password Change',
  Login = 'Login',
  Logout = 'Logout',
  RegisterTransaction = 'Register Transaction',
  TransferTransaction = 'Transfer Transaction',
  RetireTransaction = 'Retire Transaction',
  UserChange = 'User Change',
  CompanyChange = 'Company Change',
  TokenRequest = 'Token Request',
  TokenSubmission = 'Token Submission',
  LinkedAccounts = 'Linked Accounts',
  RetirementStatementCreation = 'Retirement Statement Creation',
  ContactUsFrom = 'Contact Us Form',
  BCUChange = 'BCU Change',
}

export const COLUMNS: Column<any>[] = [
  {
    title: 'Date & Time',
    keyItem: 'createdAt',
    sort: true,
    render: item => formatDate(item.createdAt),
  },
  {
    title: 'Action',
    keyItem: 'action',
    render: (item: any) =>
      ActionLogTypeFE[item.action as keyof typeof ActionLogTypeFE],
  },
  {
    title: 'SU ID',
    keyItem: 'SUID',
    render: (item: any) => item.user.SUID,
  },
  {
    title: 'Account ID',
    keyItem: 'accountId',
    render: (item: any) => item.user?.company?.id,
  },
  {
    title: 'Contact Name',
    keyItem: 'contactName',
    render: (item: any) => (
      <>
        {item.user.firstname} {item.user.lastname}s
        <span className="user-type">
          {item.user.type === UserType.Primary ? 'Primary' : 'Secondary'}
        </span>
      </>
    ),
  },
  {
    title: 'Details',
    keyItem: 'details',
    render: (item: any) => item.actionDetails,
  },
];
