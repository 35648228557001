import { validationFactory } from '../../../../core/helpers/validationFactory';

const { createValidator, createValidate } = validationFactory();

export const validate = createValidate({
  address: createValidator((value: string) => !!value, 'Address is required'),
});

export const transformData = (formData: FormData) => {
  const id = formData.get('id');
  const address = formData.get('address');

  return {
    id,
    address,
  };
};
