import { TRANSFER_ROUTES } from '../../../structure/routes/routes';

export const TABS = [
  {
    title: 'BCU Transfer',
    path: TRANSFER_ROUTES.TRANSFER,
  },
  {
    title: 'BCU Transfer Requests List',
    path: TRANSFER_ROUTES.REQUESTS,
  },
];
