import { Column } from '../../Table/Table';
import { formatDate } from '../../../core/models/contstants/format-date';
import { CERTIFICATION_SCHEMES } from '../../../dashboards/UserDashboard/BCURegistration/models/options.constant';

export const COLUMNS: Column<any>[] = [
  {
    title: 'BCU ID',
    keyItem: 'BCUID',
    render: item => item.BCUID,
  },
  {
    title: 'Product',
    keyItem: 'product',
    render: item => item.productType,
  },
  {
    title: 'Feedstock',
    keyItem: 'feedstock',
    render: item => item.rawMaterial,
  },
  {
    title: 'Scheme',
    keyItem: 'scheme',
    render: item =>
      CERTIFICATION_SCHEMES.find(
        ({ value }) => value === item.certificationScheme,
      )?.label,
  },
  {
    title: 'Date of registration',
    keyItem: 'registrationApproveDate',
    render: item => formatDate(item.registrationApproveDate),
  },
  {
    title: 'Volume',
    keyItem: 'volume',
    render: item => `${item.volume} BCU`,
  },
];
