import React, { useEffect, useState } from 'react';
import {
  Option,
  Select,
  SelectProps,
} from '../../components/Form/Select/Select';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { SingleValue } from 'react-select';
import { Modal, ModalProps } from '../../components/Modal/Modal';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { Input } from '../../components/Form/Input';
import { BaseButtonSubmit } from '../../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { useFormActions } from '../../components/Form/FormHook';
import { validate } from './models/validate.constant';
import { Company } from '../../../structure/models/company/company';

interface Props extends SelectProps<Option> {
  fetchCompanies: any;
  fetchAddCompany: any;
  selectCompanies: any;
}

const ADD_COMPANY_OPTION = { value: 'add_company' };
interface AddCompanyModalProps
  extends ModalProps,
    Pick<Props, 'fetchAddCompany'> {
  onAdd: (company: Company[]) => void;
  header: string;
}
const AddCompanyModal: React.FC<AddCompanyModalProps> = ({
  className,
  show,
  fetchAddCompany,
  onAdd,
  header,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const { loading, onSubmit, showErrorsBlock } = useFormActions({
    validate,
    transformAndDispatchData: formData => {
      const token = formData.get('token');

      return dispatch(fetchAddCompany(token)).then((company: Company[]) =>
        onAdd(company),
      );
    },
  });

  return (
    <Modal
      show={show}
      header={<h2>{header}</h2>}
      className={classNames('add-company-by-token', className)}
      centered
      {...props}
    >
      <form onSubmit={onSubmit}>
        <Row>
          <Col md="9">
            <Input
              placeholder={header}
              required
              name="token"
              error={showErrorsBlock['token']}
            />
          </Col>
          <Col md="3">
            <BaseButtonSubmit active loading={loading} lock={loading}>
              Add
            </BaseButtonSubmit>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

export const CompanySelect: React.FC<Props> = ({
  fetchCompanies,
  fetchAddCompany,
  selectCompanies,
  placeholder,
  onChange,
  value,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const companies: any = useAppSelector(selectCompanies);
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>({
    value,
  });
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  useEffect(() => {
    setOptions([
      ...companies.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
      { ...ADD_COMPANY_OPTION, label: `Add ${placeholder}` },
    ]);
  }, [companies]);

  useEffect(() => {
    setSelectedOption({ ...selectedOption, value });
  }, [value]);

  const onCompanyChange = (option: SingleValue<Option>, actionMeta: any) => {
    if (option?.value === 'add_company') {
      setModalVisible(true);

      return;
    }

    setSelectedOption(option);
    onChange?.(option, actionMeta);
  };

  const onHide = () => {
    setModalVisible(false);
  };

  const onAdd = (data: Company[]) => {
    setOptions([
      ...data.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
      { ...ADD_COMPANY_OPTION, label: `Add ${placeholder}` },
    ]);
    onHide();
  };

  return (
    <>
      <Select
        options={options}
        value={selectedOption?.value}
        placeholder={placeholder}
        onChange={onCompanyChange}
        {...props}
      />
      <AddCompanyModal
        onAdd={onAdd}
        show={modalVisible}
        onHide={onHide}
        header={`Add ${placeholder}`}
        fetchAddCompany={fetchAddCompany}
      />
    </>
  );
};
