import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { SortItem, SortOrder } from './SortItem/SortItem';
import { usePaginator } from './Pagination/PaginationHook';
import { PaginationHookProps } from './Pagination/models/pagination-hook-props.interface';
import { BaseButtonSubmit } from '../Buttons/BaseButtonSubmit/BaseButtonSubmit';

import './TableStyle.scss';
import './EditableTableStyle.scss';
import { Modal } from '../Modal/Modal';
import {
  ErrorBlocks,
  FormActionHookProps,
  useFormActions,
} from '../Form/FormHook';

export interface Column<T> {
  title?: string;
  sort?: boolean;
  sorter?: (a: T, b: T) => number;
  render: (item: T, index: number) => React.ReactElement | string;
  keyItem: string;
}

export interface EditableColumn<T> extends Column<T> {
  renderEdit?: (item: T, index: number) => React.ReactElement;
}

interface Props<T>
  extends React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  > {
  columns: Column<T>[];
  showHeader?: boolean;
  data: T[];
  rowClass?: string | ((item: T) => string);
  rowKey?: (item: T) => string;
  paginator?: React.ReactElement;
  onSort?: (key: string) => void;
  sort?: SortOrder;
  loading?: boolean;
  onRowClick?: (item: T) => void;
  tableClassName?: string;
}

interface PaginatedTableProps<TData = any>
  extends Omit<Props<TData>, 'paginator' | 'onSort' | 'sort' | 'data'>,
    Omit<PaginationHookProps<TData>, 'defaultSortBy'> {}

interface EditablePaginatedTableProps<TData = any>
  extends Omit<PaginatedTableProps<TData>, 'columns' | 'onSubmit'>,
    FormActionHookProps {
  getColumns: (showErrorBlocks: ErrorBlocks) => EditableColumn<TData>[];
  hasChanges: (formData: FormData, item: TData) => boolean;
}

export const Table = <T extends { id: string }>({
  className,
  showHeader,
  columns,
  data,
  rowKey = item => item.id,
  paginator,
  onSort,
  sort,
  loading = false,
  onRowClick,
  tableClassName,
  ...props
}: React.PropsWithChildren<Props<T>>) => {
  return (
    <div className="table">
      <div className={classNames('table-wrapper', className)}>
        <table className={classNames('base_table', tableClassName)} {...props}>
          {showHeader && (
            <thead>
              <tr>
                {columns.map((column: Column<T>) => (
                  <th key={`column_${column.keyItem}`}>
                    {column.sort ? (
                      <SortItem
                        sort={sort!}
                        keyItem={column.keyItem}
                        title={column.title!}
                        onChangeSort={onSort!}
                      />
                    ) : (
                      column.title
                    )}
                  </th>
                ))}
              </tr>
            </thead>
          )}

          <tbody>
            {!loading &&
              data?.map((item: T, index: number) => (
                <tr
                  className={classNames({
                    even: index % 2 === 0,
                    clickable: !!onRowClick,
                  })}
                  key={rowKey(item)}
                  {...(onRowClick ? { onClick: () => onRowClick(item) } : {})}>
                  {columns.map((column: Column<T>) => (
                    <td key={`column_${rowKey(item)}_${column.keyItem}`}>
                      <span className="text">{column.render(item, index)}</span>
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        {(loading || !data?.length) && (
          <div className="table-service-information">
            {loading && (
              <div className="table-service-information__loading">
                <span className="material-icons-outlined loading-icon">
                  loop
                </span>
                Loading...
              </div>
            )}
            {!loading && !data?.length && (
              <div className="table-service-information__no-result-found">
                <div className="table-service-information__no-result-found__icon">
                  <span className="material-icons-outlined">
                    sentiment_very_dissatisfied
                  </span>
                </div>
                <div className="table-service-information__no-result-found__text">
                  No result found
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {paginator && <div className="table-pagination">{paginator}</div>}
    </div>
  );
};

export const PaginatedTable = <TData extends { id: string }>({
  columns,
  fetchData,
  filters,
  selectData,
  selectStatus,
  ...props
}: React.PropsWithChildren<PaginatedTableProps<TData>>) => {
  const firstSortColumn = columns.find((column: Column<TData>) => column.sort);
  const { data, sort, loading, onChangeSort, paginationNavigation, showBy } =
    usePaginator({
      fetchData,
      filters,
      columns,
      defaultSortBy: firstSortColumn?.keyItem ?? '',
      selectData,
      selectStatus,
    });

  return (
    <Table
      onSort={onChangeSort}
      paginator={
        <>
          {showBy}
          {paginationNavigation}
        </>
      }
      data={data.results}
      sort={sort}
      columns={columns}
      loading={loading}
      showHeader
      {...props}
    />
  );
};

// TODO change this shit
export const EditablePaginatedTable = <TData extends { id: string }>({
  getColumns,
  className,
  hasChanges,
  validate,
  transformAndDispatchData,
  ...props
}: PropsWithChildren<EditablePaginatedTableProps<TData>>) => {
  const [editableItem, setEditableItem] = useState<TData | null>(null);
  const [nextItem, setNextItem] = useState<TData | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const { onSubmit, showErrorsBlock, loading, hasErrors } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  const triggerSubmit = () => {
    ref.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true }),
    );
  };

  useEffect(() => {
    if (!loading && !hasErrors) {
      setEditableItem(nextItem);
      setNextItem(null);
    }
  }, [loading]);

  const ref: any = useRef();
  const column: Column<TData> = {
    keyItem: 'edit',
    render: (item: TData) => {
      const active = item === editableItem;
      const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!active) {
          event.stopPropagation();
          event.preventDefault();

          if (
            editableItem === null ||
            !hasChanges(new FormData(ref.current), editableItem)
          ) {
            setEditableItem(item);
          } else {
            setModalVisible(true);
            setNextItem(item);
          }
        }
      };

      return (
        <>
          {active && <input type="hidden" name="id" value={item.id} />}
          <BaseButtonSubmit
            className="editable-table__submit"
            onClick={onClick}
            {...(active
              ? {
                  lock: loading,
                  loading,
                }
              : {})}>
            <span className={classNames('material-icons-outlined', { active })}>
              {active ? 'check' : 'create'}
            </span>
          </BaseButtonSubmit>
        </>
      );
    },
  };

  const editableColumns = [
    ...getColumns(showErrorsBlock).map(
      ({ renderEdit, render, ...column }: EditableColumn<TData>) => ({
        ...column,
        render: (item: TData, index: number) =>
          item === editableItem && renderEdit
            ? renderEdit(item, index)
            : render(item, index),
      }),
    ),
    column,
  ];

  return (
    <form onSubmit={onSubmit} ref={ref}>
      <PaginatedTable
        className={classNames('editable-table', className)}
        columns={editableColumns}
        {...props}
      />
      <Modal
        header={<h2>Do you want to save your changes?</h2>}
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        centered>
        <div className="action-buttons centered">
          <BaseButtonSubmit
            onClick={() => {
              setModalVisible(false);
              setEditableItem(nextItem);
              setNextItem(null);
            }}>
            Don't save
          </BaseButtonSubmit>
          <BaseButtonSubmit
            active
            itemType="submit"
            onClick={() => {
              setModalVisible(false);
              triggerSubmit();
            }}>
            Save
          </BaseButtonSubmit>
        </div>
      </Modal>
    </form>
  );
};
