import React, { useState } from 'react';
import { Modal, ModalProps } from '../Modal/Modal';
import { ViewElement } from '../ViewElement/ViewElement';

import './CreditInfoModalStyle.scss';
import { formatDate } from '../../core/models/contstants/format-date';
import { CERTIFICATION_SCHEMES } from '../../dashboards/UserDashboard/BCURegistration/models/options.constant';
import { Collapse } from 'react-bootstrap';
import { PosFile } from '../PosFile/PosFile';

interface Props extends Omit<ModalProps, 'header'> {
  credit: any;
}

export const CreditInfoModal: React.FC<Props> = ({ credit, ...props }) => {
  const [collapseOpened, setCollapseOpened] = useState(false);
  const header = (
    <div className="credit-info-modal__header">
      <ViewElement label="BCU ID" description={credit.BCUID} />
      <ViewElement label="Amount" description={credit.volume} />
    </div>
  );

  const toggleCollapse = () => setCollapseOpened(!collapseOpened);

  return (
    <Modal
      className="credit-info-modal"
      header={header}
      coloredHeader
      bodyClassName="credit-info-modal__body"
      {...props}>
      <div className="view-elements-with-rows credit-info-modal__body__info">
        <ViewElement label="Product type" description={credit.productType} />
        <ViewElement label="Feedstock" description={credit.rawMaterial} />
        <ViewElement
          label="Date of registration"
          description={formatDate(credit.registrationApproveDate)}
        />
        <ViewElement
          label="Amount of product in MT"
          description={credit.volume}
        />
        <ViewElement
          label="Amount of product in MJ"
          description={credit.neatAmountInMJ}
        />
        <ViewElement label="Batch number" description={credit.batchNumber} />
        <ViewElement
          label="Raw material type"
          description={credit.rawMaterial}
        />
        <ViewElement
          label="Certification scheme applicable"
          description={
            CERTIFICATION_SCHEMES.find(
              ({ value }) => value === credit.certificationScheme,
            )?.label
          }
        />
        <ViewElement label="POS PDF" description={<PosFile id={credit.id} />} />
        <ViewElement label="Credit volume" description={credit.volume} />
        <ViewElement
          label="Delivery Country"
          description={credit.deliveryCountry}
        />
        <ViewElement
          label="Date of Acquisition"
          description={formatDate(credit.acquisitionDate)}
        />
        <ViewElement
          label="Date of Entry in the PO's Chain of Custody Tracking and Management Systems"
          description={formatDate(credit.entryInPOChainDate)}
        />
        <ViewElement
          label="Name of Supplier"
          description={credit.supplierName}
        />
        <ViewElement
          label="Address of Supplier"
          description={`${credit.supplierAddress} ${credit.supplierAddressNumber} ${credit.supplierCity}, ${credit.supplierCountry} ${credit.supplierZip}`}
        />
        <ViewElement
          label="Country of the last production/processing site"
          description={
            credit.lastProductionCountry || credit.lastProductionName
          }
        />
        <Collapse in={collapseOpened}>
          <div>
            <ViewElement
              label="Chain of custody model employed at the supplier’s last site"
              description={credit.chainCustodyModel}
            />
            <ViewElement
              label="Default or Actual LCA GHG value"
              description={credit.LCAGHGValue}
            />
            <ViewElement
              label="GHG Emission Reduction (%)"
              description={credit.GHGEmissionReduction}
            />
            <ViewElement
              label="Claim as allowed under the RSB certification system"
              description={credit.additionalRSBClaim}
            />
            <ViewElement
              label="Declaration of incentives and tax credits"
              description={credit.incentivesSAFDeclarationAndTaxCredits}
            />
            <ViewElement
              label="CORCIA certificate"
              description="Corcia certification scheme applied"
            />
            <ViewElement
              label="Name of Neat SAF producer"
              description={credit.neatSAFProducerName}
            />
            <ViewElement
              label="Production location of Neat SAF"
              description={credit.neatSAFProductionLocation}
            />
            <ViewElement
              label="Conversion process"
              description={credit.conversionProcess}
            />
            <ViewElement
              label="Default ILUC Value"
              description={credit.defaultILUCValue}
            />
            <ViewElement
              label="Disaggregated Value for Direct Land Use Change (DLUC)"
              description={credit.directLandUseChangeDisaggregatedValue}
            />
            <ViewElement
              label="Disaggregated Value for Landfill Emission Credit (LEC)"
              description={credit.landfillDirectionCreditDisaggregatedValue}
            />
            <ViewElement
              label="Disaggregated Value Recycling Emission Credit (REC)"
              description={credit.recyclingEmissionCreditDisaggregatedValue}
            />
          </div>
        </Collapse>
      </div>
      <div
        className="credit-info-modal__body__collapse"
        onClick={toggleCollapse}>
        {collapseOpened ? 'Collapse' : 'Expand'} Info
      </div>
    </Modal>
  );
};
