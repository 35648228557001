import React from 'react';
import { PaginatedTable } from '../../../components/Table/Table';
import {
  fetchSubmissionsTransactions,
  fetchSubmissionsUsers,
} from '../../../../API/admin/submissions';
import { SubmissionsType } from '../../../../structure/request/admin';
import {
  selectSubmissionsStatus,
  selectSubmissionsTransactions,
  selectSubmissionsUsers,
} from '../../../../store/reducers/admin/submissionsReducer';
import { SubmissionsUsers } from '../../../../structure/models/admin/submissions';
import { Col } from 'react-bootstrap';
import { Option, Select } from '../../../components/Form/Select/Select';
import {
  REGISTRATION_COLUMNS,
  TRANSACTIONS_COLUMNS,
} from './models/columns.constant';
import { useSearchParamsState } from '../../../components/Table/Pagination/PaginationHook';
import { SingleValue } from 'react-select';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
} from '../../../../structure/routes/routes';
import { STATUSES, TYPES } from './models/options.constant';
import { Search } from '../../../components/Table/Search/Search';

import './SubmissionsListStyle.scss';

const getTableProps = (
  type: SubmissionsType,
  navigate: NavigateFunction,
): any => {
  const props: any = {
    [SubmissionsType.BCURegistration]: {
      fetchData: fetchSubmissionsTransactions,
      selectData: selectSubmissionsTransactions,
      columns: TRANSACTIONS_COLUMNS,
      onRowClick: (item: SubmissionsUsers) => {
        navigate(
          ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO.replace(
            ':id',
            item.id,
          ),
        );
      },
    },
    [SubmissionsType.BCUTransfer]: {
      fetchData: fetchSubmissionsTransactions,
      selectData: selectSubmissionsTransactions,
      columns: TRANSACTIONS_COLUMNS,
      onRowClick: (item: SubmissionsUsers) => {
        navigate(
          ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.COMPANY_INFO.replace(
            ':id',
            item.id,
          ),
        );
      },
    },
    [SubmissionsType.BCURetirement]: {
      fetchData: fetchSubmissionsTransactions,
      selectData: selectSubmissionsTransactions,
      columns: TRANSACTIONS_COLUMNS,
      onRowClick: (item: SubmissionsUsers) => {
        navigate(
          ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.COMPANY_INFO.replace(
            ':id',
            item.id,
          ),
        );
      },
    },
    [SubmissionsType.Registration]: {
      fetchData: fetchSubmissionsUsers,
      selectData: selectSubmissionsUsers,
      columns: REGISTRATION_COLUMNS,
      onRowClick: (item: SubmissionsUsers) => {
        navigate(
          ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO.replace(
            ':id',
            item.id,
          ),
        );
      },
    },
  };

  return props[type];
};

export const SubmissionList: React.FC = () => {
  const navigate = useNavigate();
  const [type, setType] = useSearchParamsState<SubmissionsType>(
    'type',
    SubmissionsType.Registration,
  );
  const [status, setStatus] = useSearchParamsState('status', null);
  const [search, setSearch] = useSearchParamsState<string>('search', '');

  const onChangeStatus = (option: SingleValue<Option>) => {
    setStatus(option?.value);
  };

  const onChangeType = (
    option: SingleValue<Pick<Option<SubmissionsType>, 'value'>>,
  ) => {
    setType(option!.value);
  };

  return (
    <div className="submissions-list">
      <div className="submissions-list__header">
        <h1>Submissions list</h1>
        <div className="submissions-list__header__controls">
          <Col>
            <Search
              defaultValue={search}
              onChange={event => setSearch(event.target.value)}
            />
          </Col>
          <Col>
            <Select
              placeholder="Type"
              options={TYPES}
              value={type as any}
              onChange={onChangeType}
            />
          </Col>
          <Col>
            <Select
              placeholder="Status"
              options={STATUSES}
              onChange={onChangeStatus}
              value={status}
            />
          </Col>
        </div>
      </div>
      <PaginatedTable
        {...getTableProps(type, navigate)}
        selectStatus={selectSubmissionsStatus}
        filters={{
          type,
          status,
          search,
        }}
      />
    </div>
  );
};
