import {
  SubmissionsRequest,
  SubmissionsType,
} from '../../structure/request/admin';
import { AppThunk, AppThunkDispatch } from '../../store/store';
import {
  setStatus,
  setSubmissionsDetails,
  setSubmissionsTransactions,
  setSubmissionsUsers,
} from '../../store/reducers/admin/submissionsReducer';
import { actions } from '../fetchProvider/fetch';
import { AxiosResponse } from 'axios';
import { ServerResponse } from '../types';
import { PaginatedData } from '../../structure/request/pagination';
import {
  SubmissionsTransactions,
  SubmissionsUsers,
} from '../../structure/models/admin/submissions';
import {
  URL_ADMIN_APPROVE_USER_REGISTRATION,
  URL_ADMIN_GET_COMPANY_INFO,
  URL_ADMIN_GET_SUBMISSIONS_TRANSACTION,
  URL_ADMIN_GET_SUBMISSIONS_TRANSACTIONS,
  URL_ADMIN_GET_SUBMISSIONS_USERS,
  URL_ADMIN_REJECT_USER_REGISTRATION,
  URL_ADMIN_SUBMISSIONS_TRANSACTIONS_APPROVE,
  URL_ADMIN_SUBMISSIONS_TRANSACTIONS_DECLINE,
} from '../urls/urls';

export enum TransactionType {
  Register = 'Register',
  Transfer = 'Transfer',
  Retirement = 'Retirement',
}

const SUBMISSIONS_TYPES: Record<string, string> = {
  [SubmissionsType.BCURegistration]: TransactionType.Register,
  [SubmissionsType.BCURetirement]: TransactionType.Retirement,
  [SubmissionsType.BCUTransfer]: TransactionType.Transfer,
};

export const fetchSubmissionsUsers =
  ({
    type,
    page,
    limit,
    ...query
  }: SubmissionsRequest): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<AxiosResponse<ServerResponse<PaginatedData<SubmissionsUsers>>>>(
        URL_ADMIN_GET_SUBMISSIONS_USERS,
        {
          queries: {
            skip: (page - 1) * limit,
            limit,
            ...query,
          },
        },
      )
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsUsers(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchSubmissionsTransactions =
  ({
    type,
    page,
    limit,
    ...query
  }: SubmissionsRequest): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<
        AxiosResponse<ServerResponse<PaginatedData<SubmissionsTransactions>>>
      >(URL_ADMIN_GET_SUBMISSIONS_TRANSACTIONS, {
        queries: {
          skip: (page - 1) * limit,
          limit,
          type: SUBMISSIONS_TYPES[type],
          ...query,
        },
      })
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsTransactions(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchSubmissionsCompanyInfo =
  (id: string) => (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<any>(URL_ADMIN_GET_COMPANY_INFO.replace(':id', id))
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsDetails(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchRegistrationReject =
  ({ id, message }: any) =>
  () => {
    return actions.post(URL_ADMIN_REJECT_USER_REGISTRATION.replace(':id', id), {
      payloads: { message },
    });
  };

export const fetchRegistrationApprove =
  ({ id, message }: any) =>
  () => {
    return actions.post(
      URL_ADMIN_APPROVE_USER_REGISTRATION.replace(':id', id),
      {
        payloads: { message },
      },
    );
  };

export const fetchSubmissionsTransactionInfo =
  (id: string) => (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<any>(URL_ADMIN_GET_SUBMISSIONS_TRANSACTION.replace(':id', id))
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsDetails(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchTransactionReject =
  ({ id, message }: any) =>
  () => {
    return actions.post(
      URL_ADMIN_SUBMISSIONS_TRANSACTIONS_DECLINE.replace(':id', id),
      {
        payloads: { message },
      },
    );
  };

export const fetchTransactionApprove =
  ({ id, message }: any) =>
  () => {
    return actions.post(
      URL_ADMIN_SUBMISSIONS_TRANSACTIONS_APPROVE.replace(':id', id),
      {
        payloads: { message },
      },
    );
  };
