import { ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES } from '../../../../../structure/routes/routes';

export const BCU_CREDITS_TABS = [
  {
    title: 'Company Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO,
  },
  {
    title: 'Users Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.USERS_INFO,
  },
  {
    title: 'BCU Info',
    path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_INFO,
  },
  {
    title: 'Note',
    path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.NOTE,
  },
];
