import { PaginatedData } from '../../../structure/request/pagination';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface UsersState {
  status: 'idle' | 'loading' | 'failed';
  list: {
    count: number;
    results: any[];
  };
}

const initialState: UsersState = {
  status: 'idle',
  list: {
    count: 0,
    results: [],
  },
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersList: (state, action: PayloadAction<PaginatedData<any>>) => {
      state.list = action.payload;
    },
    updateUser: (state, action) => {
      const user = action.payload;
      const results = state.list.results.map(value =>
        value.id === user.id ? user : value,
      );

      state.list = {
        ...state.list,
        results,
      };
    },
    setUsersStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'failed'>,
    ) => {
      state.status = action.payload;
    },
  },
});

export const { setUsersStatus, updateUser, setUsersList } = usersSlice.actions;

export const selectUsersList = (state: RootState) => state.users.list;
export const selectUsersStatus = (state: RootState) => state.users.status;

export default usersSlice.reducer;
