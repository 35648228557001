import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ViewElement } from '../../components/ViewElement/ViewElement';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';

import './UserInfoStyle.scss';

export const UserInfo: React.FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <div className="user-info">
      <Row className="user-info__header-block">
        <h1>
          {user?.firstname} {user?.lastname}
        </h1>
        <ViewElement label={`ID: ${user?.SUID}`} dots={false} />
      </Row>
      <Row>
        <Col md="5" className="user-info__info-block">
          <ViewElement label="Position" description={user?.position} />
          <ViewElement label="Email" description={user?.email} />
          <ViewElement
            label="Cell Phone"
            description={
              typeof user?.cellPhone === 'string'
                ? user?.cellPhone
                : `${user?.cellPhone?.code}${user?.cellPhone?.number}`
            }
          />
        </Col>
      </Row>
    </div>
  );
};
