import { config } from '../../../../../config';
import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../../core/models/contstants/format-date';
import { Link } from 'react-router-dom';
import { ADMIN_ACCOUNTS_AND_BALANCE_ROUTES } from '../../../../../structure/routes/routes';
import { ErrorBlocks } from '../../../../components/Form/FormHook';
import { EditableColumn } from '../../../../components/Table/Table';
import { Input } from '../../../../components/Form/Input';

export const ACCOUNTS_COLUMNS = (
  showErrorsBlocks: ErrorBlocks,
): EditableColumn<any>[] => [
    {
      title: 'Date of Confirmation',
      keyItem: 'approveDate',
      sort: true,
      render: (item: any) => formatDate(item.approveDate, DATE_WITH_TIME_FORMAT),
    },
    {
      title: 'Account ID',
      keyItem: 'id',
      sort: true,
      render: (item: any) => item.id,
    },
    {
      title: 'RSB PO Number',
      keyItem: 'RSBPONumber',
      sort: true,
      render: (item: any) => item.RSBPONumber,
    },
    {
      title: 'Company Name',
      keyItem: 'name',
      sort: true,
      render: (item: any) => item.name,
    },
    {
      title: 'Company Address',
      keyItem: 'address',
      sort: true,
      render: (item: any) => item.address,
      renderEdit: (item: any) => (
        <div>
          <Input
            name="address"
            defaultValue={item.address}
            error={showErrorsBlocks['address']}
          />
        </div>
      ),
    },
    {
      title: 'Type of Organization',
      keyItem: 'type',
      sort: true,
      render: (item: any) => item.type,
    },
    {
      title: 'Primary Contact',
      keyItem: 'primaryContact',
      render: (item: any) =>
        `${item.users[0]?.firstname || ''} ${item.users[0]?.lastname || ''} ${item.users[0]?.email || ''
        }`,
    },
    {
      title: 'Secondary Contact',
      keyItem: 'secondaryContact',
      render: (item: any) =>
        `${item.users[1]?.firstname || ''} ${item.users[1]?.lastname || ''} ${item.users[1]?.email || ''
        }`,
    },
    {
      keyItem: 'details',
      render: (item: any) => (
        <Link
          to={ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS.replace(':id', item.id)}>
          <span className="material-icons-outlined">more_vert</span>
        </Link>
      ),
    },
  ];

export const DOWNLOAD_USERS_DATA_URL = `${config.API_URL}/users/list/download`;
