import { ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES } from '../../../../../structure/routes/routes';

export const COMPANY_TABS = [
  {
    title: 'Company Info',
    path: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO,
  },
  {
    title: 'Users Info',
    path: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USERS_INFO,
  },
  {
    title: 'Note',
    path: ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.NOTE,
  },
];
