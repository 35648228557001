import React, { useEffect } from 'react';
import { UserBalance } from '../components/UserBalance/UserBalance';
import { PaginatedTable } from '../components/Table/Table';
import { fetchUserTransactions } from '../../API/user-transactions';
import {
  selectTransactions,
  selectUserTransactionsStatus,
} from '../../store/reducers/user/transactionsReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { Search } from '../components/Table/Search/Search';
import { useSearchParamsState } from '../components/Table/Pagination/PaginationHook';
import { COLUMNS } from './models/columns.constant';

import './TransactionsStyle.scss';
import { fetchCredits } from '../../API/credits';
import { fetchCompanyData } from '../../API/user';

export const Transactions: React.FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useSearchParamsState('search', '');
  const onSearch = (e: any) => setSearch(e.target.value);

  useEffect(() => {
    if (!user) return;
    dispatch(fetchCredits());
    dispatch(fetchCompanyData());
  }, []);

  return (
    <div className="transactions">
      <UserBalance company={user!.company} />
      <div className="transactions__header">
        <h1>Transaction History</h1>
        <Search defaultValue={search} onChange={onSearch} />
      </div>
      <div className="transactions__container">
        <PaginatedTable
          showHeader={false}
          columns={COLUMNS(user)}
          fetchData={fetchUserTransactions}
          selectStatus={selectUserTransactionsStatus}
          selectData={selectTransactions}
          filters={{ search }}
        />
      </div>
    </div>
  );
});
