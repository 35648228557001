import React from 'react';
import { AdminSubmissionsDetailsLayout } from './AdminSubmissionsDetailsLayout';
import {
  fetchSubmissionsTransactionInfo,
  fetchTransactionApprove,
  fetchTransactionReject,
} from '../../../../API/admin/submissions';
import { BCU_TRANSFER_TABS } from './models/bcu-transfer-tabs.constant';

export const AdminTransfersLayout: React.FC = () => {
  return (
    <AdminSubmissionsDetailsLayout
      fetchData={fetchSubmissionsTransactionInfo}
      fetchReject={fetchTransactionReject}
      fetchApprove={fetchTransactionApprove}
      tabs={BCU_TRANSFER_TABS}
    />
  );
};
