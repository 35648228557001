import { Column } from '../../../../components/Table/Table';
import {
  SubmissionsTransactions,
  SubmissionsUsers,
} from '../../../../../structure/models/admin/submissions';
import {
  SubmissionsStatus,
  SubmissionsStatusBE,
} from '../../../../../structure/request/admin';
import classNames from 'classnames';
import { UserType } from '../../../../../structure/models/user/user';
import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../../core/models/contstants/format-date';

const STATUSES = {
  [SubmissionsStatusBE.Approved]: SubmissionsStatus.Approved,
  [SubmissionsStatusBE.Declined]: SubmissionsStatus.Rejected,
  [SubmissionsStatusBE.Pending]: SubmissionsStatus.Consideration,
};

export const REGISTRATION_COLUMNS: Column<SubmissionsUsers>[] = [
  {
    title: 'Date and Time',
    keyItem: 'createdAt',
    sort: true,
    render: item => formatDate(item.createdAt, DATE_WITH_TIME_FORMAT),
  },
  {
    title: 'SUID',
    keyItem: 'SUID',
    render: item => item.target?.SUID,
  },
  {
    title: 'User Name',
    keyItem: 'userName',
    render: item => `${item.target?.firstname} ${item.target?.lastname}`,
  },
  {
    title: 'Company Name',
    keyItem: 'companyName',
    render: item => item.target?.company.name,
  },
  {
    title: 'Status',
    keyItem: 'status',
    render: item => (
      <span
        className={classNames('submissions-list__status', {
          approved: item.status === SubmissionsStatusBE.Approved,
          declined: item.status === SubmissionsStatusBE.Declined,
          consideration: item.status === SubmissionsStatusBE.Pending,
        })}
      >
        {item.status === SubmissionsStatusBE.Pending
          ? 'For Consideration'
          : STATUSES[item.status]}
      </span>
    ),
  },
];

export const TRANSACTIONS_COLUMNS: Column<SubmissionsTransactions>[] = [
  {
    title: 'Date and Time',
    keyItem: 'createdAt',
    sort: true,
    render: item => formatDate(item.createdAt, DATE_WITH_TIME_FORMAT),
  },
  {
    title: 'From/By',
    keyItem: 'companySender',
    render: item => item.target?.company.name,
  },
  {
    title: 'To',
    keyItem: 'receiver',
    render: item => item.target?.receiver?.name!,
  },
  {
    title: 'BCU ID',
    keyItem: 'BCUID',
    render: item => item.target?.credit.BCUID,
  },
  {
    title: 'User',
    keyItem: 'senderUser',
    render: item => (
      <span className="submissions-list__user">
        {item.target?.sender.firstname} {item.target?.sender.lastname}
        <span className="submissions-list__user__type user-type">
          {item.target?.sender.type === UserType.Primary
            ? 'Primary'
            : 'Secondary'}
        </span>
      </span>
    ),
  },
  {
    title: 'Volume',
    keyItem: 'amount',
    render: item => `${item.target?.amount} BCU`,
  },
  {
    title: 'Status',
    keyItem: 'status',
    render: item => (
      <span
        className={classNames('submissions-list__status', {
          approved: item.status === SubmissionsStatusBE.Approved,
          declined: item.status === SubmissionsStatusBE.Declined,
          consideration: item.status === SubmissionsStatusBE.Pending,
        })}
      >
        {item.status === SubmissionsStatusBE.Pending
          ? 'For Consideration'
          : STATUSES[item.status]}
      </span>
    ),
  },
];
