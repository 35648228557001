import { AppThunk } from '../store/store';
import { actions } from './fetchProvider/fetch';
import {
  URL_ABATEMENT_BASELINES,
  URL_BCU_REGISTRATION,
  URL_COMPANY_CREDITS,
  URL_CREDITS_RETIRE,
  URL_POS_FILE,
} from './urls/urls';
import { setBaseLines } from '../store/reducers/user/abatementBaselinesReducer';
import { setCredits } from '../store/reducers/user/creditsReducer';

export const fetchBCURegistration =
  (registrationData: any): AppThunk<Promise<void>> =>
  () => {
    const data = new FormData();
    Object.entries(registrationData).forEach(([key, value]) => {
      data.append(key, value as any);
    });

    return actions.post<void>(URL_BCU_REGISTRATION, {
      payloads: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

export const fetchBaselines = (): AppThunk<Promise<void>> => dispatch => {
  return actions.get(URL_ABATEMENT_BASELINES).then((response: any) => {
    dispatch(setBaseLines(response.data.data));
  });
};

export const fetchCredits = () => (dispatch: any) => {
  return actions
    .get(URL_COMPANY_CREDITS)
    .then((resp: any) => dispatch(setCredits(resp.data.data)));
};

export const fetchCreditRetire =
  ({ id, ...payloads }: any) =>
  () => {
    return actions.post(URL_CREDITS_RETIRE.replace(':id', id), { payloads });
  };

export const fetchDownloadPDF = (id: string) => () => {
  return actions
    .get(URL_POS_FILE.replace(':id', id), { responseType: 'blob' } as any)
    .then((resp: any) => {
      return resp.data;
    });
};
