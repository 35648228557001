import { SubmissionsRequest } from '../../structure/request/admin';
import { AppThunk, AppThunkDispatch } from '../../store/store';
import { actions } from '../fetchProvider/fetch';
import { AxiosResponse } from 'axios';
import { ServerResponse } from '../types';
import { PaginatedData } from '../../structure/request/pagination';
import { SubmissionsUsers } from '../../structure/models/admin/submissions';
import {
  setUsersList,
  setUsersStatus,
  updateUser,
} from '../../store/reducers/admin/usersReducer';
import {
  URL_ADMIN_DOWNLOAD_USERS,
  URL_ADMIN_EDIT_USER,
  URL_ADMIN_GET_USERS,
} from '../urls/urls';
import fileDownload from 'js-file-download';

export const fetchUsers =
  ({
    type,
    page,
    limit,
    ...query
  }: SubmissionsRequest): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setUsersStatus('loading'));

    return actions
      .get<AxiosResponse<ServerResponse<PaginatedData<SubmissionsUsers>>>>(
        URL_ADMIN_GET_USERS,
        {
          queries: {
            skip: (page - 1) * limit,
            limit,
            ...query,
          },
        },
      )
      .then(response => {
        dispatch(setUsersStatus('idle'));
        dispatch(setUsersList(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setUsersStatus('failed'));
        throw e;
      });
  };

export const fetchEditUser =
  ({ id, ...user }: any) =>
  (dispatch: AppThunkDispatch) => {
    return actions
      .post(URL_ADMIN_EDIT_USER.replace(':id', id), {
        payloads: user,
      })
      .then((resp: any) => {
        dispatch(updateUser(resp.data.data));

        return resp.data.data;
      });
  };

export const downloadUsers = (): Promise<any> =>
  actions
    .get<AxiosResponse>(URL_ADMIN_DOWNLOAD_USERS)
    .then(response => {
      fileDownload(response.data, 'users.csv');
    })
    .catch(e => {
      throw e;
    });
