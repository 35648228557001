import React, { useEffect } from 'react';
import { COLUMNS } from './models/columns.constant';
import {
  fetchAdminCredits,
  fetchAdminTransactions,
  fetchCompany,
} from '../../../../../API/admin/accounts';
import { useNavigate, useParams } from 'react-router-dom';
import { PaginatedTable } from '../../../../components/Table/Table';
import { Search } from '../../../../components/Table/Search/Search';
import { UserBalance } from '../../../../components/UserBalance/UserBalance';
import {
  selectTransactions,
  selectUserTransactionsStatus,
} from '../../../../../store/reducers/user/transactionsReducer';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { useSearchParamsState } from '../../../../components/Table/Pagination/PaginationHook';
import { selectCompany } from '../../../../../store/reducers/admin/accountsReducer';
import { Container } from 'react-bootstrap';

import './AdminTransactionsStyle.scss';

export const AdminTransactions: React.FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  const company = useAppSelector(selectCompany);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [search, setSearch] = useSearchParamsState('search', '');
  const onSearch = (e: any) => setSearch(e.target.value);
  const goBack = () => navigate(-1);

  useEffect(() => {
    if (!user) return;
    dispatch(fetchAdminCredits(id!));
    dispatch(fetchCompany(id!));
  }, []);

  return (
    <Container className="admin-transactions">
      <div onClick={goBack} className="admin-transactions__back">
        <span className="material-icons-outlined">arrow_back_ios</span>
        Accounts & Balance
      </div>
      <UserBalance company={company} />
      <div className="admin-transactions__header">
        <h1>Transaction History</h1>
        <Search defaultValue={search} onChange={onSearch} />
      </div>
      <div className="admin-transactions__container">
        <PaginatedTable
          showHeader={false}
          columns={COLUMNS(id!)}
          fetchData={fetchAdminTransactions}
          selectStatus={selectUserTransactionsStatus}
          selectData={selectTransactions}
          filters={{ search, id }}
        />
      </div>
    </Container>
  );
});
