import React from 'react';
import { Col, Container } from 'react-bootstrap';
import './UsersStyle.scss';
import { USERS_COLUMNS } from './models/columns.constant';
import { EditablePaginatedTable } from '../../../components/Table/Table';
import { useSearchParamsState } from '../../../components/Table/Pagination/PaginationHook';
import {
  selectUsersList,
  selectUsersStatus,
} from '../../../../store/reducers/admin/usersReducer';
import { Search } from '../../../components/Table/Search/Search';
import {
  downloadUsers,
  fetchEditUser,
  fetchUsers,
} from '../../../../API/admin/users';
import { DownloadFileButton } from '../../../components/Buttons/DownloadFileButton/DownloadFileButton';
import { hasChanges } from './models/has-changes.constant';
import { transformData, validate } from './models/validate';
import { useAppDispatch } from '../../../../store/hooks';
import { toast } from 'react-toastify';

export const Users: React.FC = () => {
  const [searchQuery, setSearchQuery] = useSearchParamsState<string>(
    'search',
    '',
  );
  const dispatch = useAppDispatch();

  return (
    <div className="users">
      <Container fluid>
        <div className="users__header">
          <h1>Users</h1>
          <div className="controls">
            <Col className="search">
              <Search
                className="search__input"
                defaultValue={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            </Col>
            <DownloadFileButton fetchDownload={downloadUsers} />
          </div>
        </div>
        <EditablePaginatedTable
          className="users__table"
          fetchData={fetchUsers}
          getColumns={USERS_COLUMNS}
          selectData={selectUsersList}
          selectStatus={selectUsersStatus}
          validate={validate}
          transformAndDispatchData={formData => {
            return dispatch(fetchEditUser(transformData(formData))).then(() => {
              toast.success('Success: User has been updated');
            });
          }}
          filters={{
            search: searchQuery,
          }}
          hasChanges={hasChanges}
        />
      </Container>
    </div>
  );
};
